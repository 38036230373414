export default {
  data() {
    return { screenHeight: 0, screenWidth: 0 };
  },
  methods: {
    getScreenSsize() {
      this.screenWidth = window.innerWidth;
      this.screenHeight = window.innerHeight;
    },
  },
  computed: {
    isFullScreen() {
      const { screenHeight, screenWidth } = this;
      return screenHeight < 500 || screenWidth < 500;
    },
  },
  mounted() {
    this.getScreenSsize();
    window.addEventListener("resize", this.getScreenSsize);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.getScreenSsize);
  },
};
