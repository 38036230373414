import { db } from "@/mixins/utilisync-db";

export default {
  data() {
    return {
      selectedGisInfo: {
        graphic: {
          layer: {},
        },
        info: {},
        type: "",
      },
      highlightSelect: [],
    };
  },
  methods: {
    async getGisInfoData(graphic) {
      this.selectedGisInfo = { graphic };
      const globalIdObj =
        Array.isArray(graphic?.layer?.fields) &&
        graphic?.layer?.fields.find((f) =>
          ["oid", "global-id"].includes(f.type)
        );
      const featureId = graphic?.attributes?.[globalIdObj.name];
      const { mapServiceId, utilisyncRefField } = graphic?.layer ?? {};
      if (!mapServiceId || !featureId) {
        this.selectedGisInfo = {
          graphic,
          info: [],
          type: "gis-info",
          refField: graphic?.attributes?.[utilisyncRefField],
        };
        return;
      }
      try {
        if (!navigator.onLine) {
          return;
        }

        const gisDataValues = await db.gisDataValues.toCollection().toArray();
        const gisDataValueIds =
          this.gisDataValues?.map((g) => g.gis_data_field_id) ?? [];
        const gisDataFields = await db.gisDataFields
          .filter((g) => {
            return (
              g.map_service_id === mapServiceId &&
              !gisDataValueIds.includes(featureId)
            );
          })
          .toArray();

        const results = gisDataFields?.map((gdf) => {
          const { name } = gdf;
          const value = gisDataValues?.find((gdv) => {
            return (
              gdv?.gis_data_field_id === gdf?.gis_data_field_id &&
              gdv?.feature_id === featureId
            );
          })?.value;

          return {
            name,
            value,
          };
        });

        this.selectedGisInfo = {
          graphic,
          info: results,
          type: "gis-info",
          refField: graphic?.attributes?.[utilisyncRefField],
        };
      } catch (error) {
        console.log(error);
      }
    },
    async highlightItem(gisInfo) {
      const { view } = this;
      if (gisInfo?.layer) {
        const layerView = await view.whenLayerView(gisInfo.layer);
        const selected = layerView.highlight(gisInfo);
        this.highlightSelect.push(selected);
      } else if (gisInfo?.sourceLayer?.layer) {
        const layerView = await view.whenLayerView(gisInfo.sourceLayer.layer);
        const selected = layerView.highlight(gisInfo);
        this.highlightSelect.push(selected);
      }
    },
  },
};
