var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: {
        value: _vm.showActionItemDialog,
        "max-width": "600px",
        persistent: ""
      }
    },
    [
      _c("validation-observer", {
        ref: "actionItemForm",
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function(ref) {
              var isFormValid = ref.valid
              return [
                _c(
                  "v-card",
                  { attrs: { id: "actionItemDialog" } },
                  [
                    _c(
                      "v-toolbar",
                      { attrs: { dark: "", color: "#3F51B5" } },
                      [
                        _c("v-toolbar-title", [_vm._v("Action Item")]),
                        _c("v-spacer"),
                        _c(
                          "v-btn",
                          {
                            attrs: { icon: "", dark: "" },
                            on: { click: _vm.closeForm }
                          },
                          [_c("v-icon", [_vm._v("mdi-close")])],
                          1
                        )
                      ],
                      1
                    ),
                    _c(
                      "v-card-text",
                      {
                        staticClass: "pt-3",
                        style: {
                          height: _vm.$vuetify.breakpoint.xsOnly
                            ? _vm.contentHeight + "px"
                            : "65vh"
                        },
                        attrs: { id: "dynamic-form-card-text" }
                      },
                      [
                        _c(
                          "v-row",
                          [
                            _c(
                              "v-col",
                              { attrs: { cols: "12" } },
                              [
                                _c("validation-provider", {
                                  attrs: {
                                    name: "Description",
                                    rules: "required"
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function(ref) {
                                          var errors = ref.errors
                                          var valid = ref.valid
                                          return [
                                            _c("v-textarea", {
                                              ref: "textAreaRef",
                                              attrs: {
                                                label: "Description *",
                                                "hide-details": "auto",
                                                "error-messages": errors,
                                                success: valid,
                                                color: "#3F51B5",
                                                name: "value",
                                                disabled: _vm.readOnly,
                                                hint:
                                                  _vm.item.question
                                                    .descriptionHint,
                                                "persistent-hint": "",
                                                "auto-grow": "",
                                                rows: "1"
                                              },
                                              model: {
                                                value:
                                                  _vm.actionItem
                                                    .action_item_description,
                                                callback: function($$v) {
                                                  _vm.$set(
                                                    _vm.actionItem,
                                                    "action_item_description",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "actionItem.action_item_description"
                                              }
                                            })
                                          ]
                                        }
                                      }
                                    ],
                                    null,
                                    true
                                  )
                                })
                              ],
                              1
                            ),
                            _vm.selectedMapService.site_enabled
                              ? _c(
                                  "v-col",
                                  {
                                    staticClass:
                                      "d-flex gap justify-space-between",
                                    attrs: { cols: "12" }
                                  },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "half" },
                                      [
                                        _c("validation-provider", {
                                          attrs: {
                                            name: "Assign To",
                                            rules: "required"
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "default",
                                                fn: function(ref) {
                                                  var errors = ref.errors
                                                  var valid = ref.valid
                                                  return [
                                                    _c("v-select", {
                                                      attrs: {
                                                        items:
                                                          _vm.ASSIGN_TO_CHOICES,
                                                        "hide-details": "auto",
                                                        name: "assignTo",
                                                        "item-value": "value",
                                                        "item-text": "label",
                                                        "error-messages": errors,
                                                        success: valid,
                                                        label: "Assign To *",
                                                        disabled: _vm.readOnly
                                                      },
                                                      model: {
                                                        value: _vm.assignTo,
                                                        callback: function(
                                                          $$v
                                                        ) {
                                                          _vm.assignTo = $$v
                                                        },
                                                        expression: "assignTo"
                                                      }
                                                    })
                                                  ]
                                                }
                                              }
                                            ],
                                            null,
                                            true
                                          )
                                        })
                                      ],
                                      1
                                    )
                                  ]
                                )
                              : _vm._e(),
                            _vm.assignTo === _vm.ASSIGN_TO.SITE_CONTACT
                              ? _c(
                                  "v-col",
                                  {
                                    staticClass:
                                      "d-flex gap justify-space-between",
                                    attrs: { cols: "12" }
                                  },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "half" },
                                      [
                                        _c("validation-provider", {
                                          attrs: {
                                            name: "Site Contact",
                                            rules: "required"
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "default",
                                                fn: function(ref) {
                                                  var errors = ref.errors
                                                  var valid = ref.valid
                                                  return [
                                                    _c("v-combobox", {
                                                      attrs: {
                                                        items:
                                                          _vm.sharedSiteContacts,
                                                        "hide-details": "auto",
                                                        name: "siteContact",
                                                        "item-value": "user_id",
                                                        "error-messages": errors,
                                                        success: valid,
                                                        label: "Site Contact *",
                                                        disabled: _vm.readOnly
                                                      },
                                                      scopedSlots: _vm._u(
                                                        [
                                                          {
                                                            key: "item",
                                                            fn: function(ref) {
                                                              var item =
                                                                ref.item
                                                              return [
                                                                _c("section", [
                                                                  _c("div", [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        item.f_name
                                                                      ) +
                                                                        " " +
                                                                        _vm._s(
                                                                          item.l_name
                                                                        )
                                                                    )
                                                                  ]),
                                                                  _c(
                                                                    "div",
                                                                    {
                                                                      staticClass:
                                                                        "caption"
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        _vm._s(
                                                                          item.email
                                                                        )
                                                                      )
                                                                    ]
                                                                  )
                                                                ])
                                                              ]
                                                            }
                                                          },
                                                          {
                                                            key: "selection",
                                                            fn: function(ref) {
                                                              var item =
                                                                ref.item
                                                              return [
                                                                _c("section", [
                                                                  _c("div", [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        item.f_name
                                                                      ) +
                                                                        " " +
                                                                        _vm._s(
                                                                          item.l_name
                                                                        )
                                                                    )
                                                                  ]),
                                                                  _c(
                                                                    "div",
                                                                    {
                                                                      staticClass:
                                                                        "caption"
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        _vm._s(
                                                                          item.email
                                                                        )
                                                                      )
                                                                    ]
                                                                  )
                                                                ])
                                                              ]
                                                            }
                                                          }
                                                        ],
                                                        null,
                                                        true
                                                      ),
                                                      model: {
                                                        value:
                                                          _vm.sharedSiteContactUser,
                                                        callback: function(
                                                          $$v
                                                        ) {
                                                          _vm.sharedSiteContactUser = $$v
                                                        },
                                                        expression:
                                                          "sharedSiteContactUser"
                                                      }
                                                    })
                                                  ]
                                                }
                                              }
                                            ],
                                            null,
                                            true
                                          )
                                        })
                                      ],
                                      1
                                    )
                                  ]
                                )
                              : _vm.assignTo === _vm.ASSIGN_TO.ORG_USER
                              ? _c(
                                  "v-col",
                                  {
                                    staticClass:
                                      "d-flex gap justify-space-between",
                                    attrs: { cols: "12" }
                                  },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "half" },
                                      [
                                        _c("validation-provider", {
                                          attrs: {
                                            name: "Organization User",
                                            rules: "required"
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "default",
                                                fn: function(ref) {
                                                  var errors = ref.errors
                                                  var valid = ref.valid
                                                  return [
                                                    _c("v-combobox", {
                                                      attrs: {
                                                        items: _vm.userChoices,
                                                        "hide-details": "auto",
                                                        name:
                                                          "organizationUser",
                                                        "item-value": "value",
                                                        "item-text": "name",
                                                        "error-messages": errors,
                                                        success: valid,
                                                        label:
                                                          "Organization User *",
                                                        disabled: _vm.readOnly,
                                                        "return-object": false
                                                      },
                                                      scopedSlots: _vm._u(
                                                        [
                                                          {
                                                            key: "item",
                                                            fn: function(ref) {
                                                              var item =
                                                                ref.item
                                                              return [
                                                                _c("section", [
                                                                  _c("div", [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        item.name
                                                                      )
                                                                    )
                                                                  ])
                                                                ])
                                                              ]
                                                            }
                                                          },
                                                          {
                                                            key: "selection",
                                                            fn: function(ref) {
                                                              var assigneeUserId =
                                                                ref.item
                                                              return [
                                                                _c("section", [
                                                                  _c("div", [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        _vm.getAssigneeName(
                                                                          assigneeUserId
                                                                        )
                                                                      )
                                                                    )
                                                                  ])
                                                                ])
                                                              ]
                                                            }
                                                          }
                                                        ],
                                                        null,
                                                        true
                                                      ),
                                                      model: {
                                                        value:
                                                          _vm.assigneeUserId,
                                                        callback: function(
                                                          $$v
                                                        ) {
                                                          _vm.assigneeUserId = $$v
                                                        },
                                                        expression:
                                                          "assigneeUserId"
                                                      }
                                                    })
                                                  ]
                                                }
                                              }
                                            ],
                                            null,
                                            true
                                          )
                                        })
                                      ],
                                      1
                                    )
                                  ]
                                )
                              : _vm._e(),
                            _c("v-col", { attrs: { cols: "12" } }, [
                              _c(
                                "div",
                                { staticClass: "half" },
                                [
                                  _c("validation-provider", {
                                    attrs: {
                                      slim: "",
                                      name: _vm.item.question.label,
                                      rules: "required"
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function(ref) {
                                            var errors = ref.errors
                                            var valid = ref.valid
                                            return [
                                              _c(
                                                "v-menu",
                                                {
                                                  scopedSlots: _vm._u(
                                                    [
                                                      {
                                                        key: "activator",
                                                        fn: function(ref) {
                                                          var on = ref.on
                                                          return [
                                                            _c(
                                                              "v-text-field",
                                                              _vm._g(
                                                                {
                                                                  attrs: {
                                                                    label:
                                                                      "Due By *",
                                                                    color:
                                                                      "#3F51B5",
                                                                    "error-messages": errors,
                                                                    success: valid,
                                                                    disabled:
                                                                      _vm.readOnly
                                                                  },
                                                                  model: {
                                                                    value:
                                                                      _vm
                                                                        .actionItem
                                                                        .due_by,
                                                                    callback: function(
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        _vm.actionItem,
                                                                        "due_by",
                                                                        $$v
                                                                      )
                                                                    },
                                                                    expression:
                                                                      "actionItem.due_by"
                                                                  }
                                                                },
                                                                on
                                                              )
                                                            )
                                                          ]
                                                        }
                                                      }
                                                    ],
                                                    null,
                                                    true
                                                  )
                                                },
                                                [
                                                  _c("v-date-picker", {
                                                    model: {
                                                      value:
                                                        _vm.actionItem.due_by,
                                                      callback: function($$v) {
                                                        _vm.$set(
                                                          _vm.actionItem,
                                                          "due_by",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "actionItem.due_by"
                                                    }
                                                  })
                                                ],
                                                1
                                              )
                                            ]
                                          }
                                        }
                                      ],
                                      null,
                                      true
                                    )
                                  })
                                ],
                                1
                              )
                            ]),
                            _c(
                              "v-col",
                              { attrs: { cols: "12" } },
                              [
                                _c("p", { staticClass: "caption" }, [
                                  _vm._v("Photos")
                                ]),
                                _c("file-pond", {
                                  ref: "files",
                                  attrs: {
                                    name: "files",
                                    "label-idle": "Tap or drop photos here...",
                                    "allow-multiple": "",
                                    "accepted-file-types":
                                      "image/jpeg, image/png",
                                    files: _vm.uploadFiles,
                                    allowFileSizeValidation: "",
                                    maxFileSize: "2MB",
                                    disabled: _vm.readOnly
                                  },
                                  on: { addfile: _vm.onChange }
                                }),
                                _vm._l(_vm.actionItem.assigner_photos, function(
                                  image,
                                  index
                                ) {
                                  return _c(
                                    "v-card",
                                    { key: image.id },
                                    [
                                      _c(
                                        "v-card-text",
                                        [
                                          _c(
                                            "v-list-item",
                                            [
                                              _c(
                                                "v-list-item-content",
                                                [
                                                  _c(
                                                    "v-card",
                                                    {
                                                      staticClass:
                                                        "d-flex flex-row justify-start elevation-0"
                                                    },
                                                    [
                                                      _c(
                                                        "v-card",
                                                        {
                                                          staticClass:
                                                            "\n                          elevation-0\n                          d-flex\n                          flex-column\n                          justify-center\n                          mr-2\n                        "
                                                        },
                                                        [
                                                          _c("img", {
                                                            staticClass:
                                                              "thumbnail mr-2 cursor-pointer",
                                                            attrs: {
                                                              src:
                                                                image.metadata
                                                                  .s3_file_path
                                                            },
                                                            on: {
                                                              click: function(
                                                                $event
                                                              ) {
                                                                return _vm.onClick(
                                                                  image.id
                                                                )
                                                              }
                                                            }
                                                          })
                                                        ]
                                                      ),
                                                      _c(
                                                        "v-card",
                                                        {
                                                          staticClass:
                                                            "elevation-0",
                                                          attrs: {
                                                            width: "100%"
                                                          }
                                                        },
                                                        [
                                                          _c("v-textarea", {
                                                            key: image.id,
                                                            ref:
                                                              "imageTextAreaRef",
                                                            refInFor: true,
                                                            attrs: {
                                                              label:
                                                                "Description",
                                                              color: "#3F51B5",
                                                              name: image.id,
                                                              "auto-grow": "",
                                                              rows: "1"
                                                            },
                                                            model: {
                                                              value:
                                                                image.description,
                                                              callback: function(
                                                                $$v
                                                              ) {
                                                                _vm.$set(
                                                                  image,
                                                                  "description",
                                                                  $$v
                                                                )
                                                              },
                                                              expression:
                                                                "image.description"
                                                            }
                                                          })
                                                        ],
                                                        1
                                                      )
                                                    ],
                                                    1
                                                  )
                                                ],
                                                1
                                              ),
                                              _c(
                                                "v-list-item-action",
                                                [
                                                  _c(
                                                    "v-list-item-action-text",
                                                    [
                                                      _c(
                                                        "v-menu",
                                                        {
                                                          scopedSlots: _vm._u(
                                                            [
                                                              {
                                                                key:
                                                                  "activator",
                                                                fn: function(
                                                                  ref
                                                                ) {
                                                                  var on =
                                                                    ref.on
                                                                  var attrs =
                                                                    ref.attrs
                                                                  return [
                                                                    _c(
                                                                      "v-btn",
                                                                      _vm._g(
                                                                        _vm._b(
                                                                          {
                                                                            attrs: {
                                                                              icon:
                                                                                ""
                                                                            }
                                                                          },
                                                                          "v-btn",
                                                                          attrs,
                                                                          false
                                                                        ),
                                                                        on
                                                                      ),
                                                                      [
                                                                        _c(
                                                                          "v-icon",
                                                                          [
                                                                            _vm._v(
                                                                              _vm._s(
                                                                                _vm.mdiDotsVertical
                                                                              )
                                                                            )
                                                                          ]
                                                                        )
                                                                      ],
                                                                      1
                                                                    )
                                                                  ]
                                                                }
                                                              }
                                                            ],
                                                            null,
                                                            true
                                                          )
                                                        },
                                                        [
                                                          _c(
                                                            "v-list",
                                                            [
                                                              _c(
                                                                "v-list-item",
                                                                {
                                                                  on: {
                                                                    click: function(
                                                                      $event
                                                                    ) {
                                                                      return _vm.onClick(
                                                                        image.id
                                                                      )
                                                                    }
                                                                  }
                                                                },
                                                                [
                                                                  _c(
                                                                    "v-list-item-title",
                                                                    [
                                                                      _c(
                                                                        "v-icon",
                                                                        [
                                                                          _vm._v(
                                                                            _vm._s(
                                                                              _vm.mdiPencil
                                                                            )
                                                                          )
                                                                        ]
                                                                      ),
                                                                      _vm._v(
                                                                        " Markup "
                                                                      )
                                                                    ],
                                                                    1
                                                                  )
                                                                ],
                                                                1
                                                              ),
                                                              _c(
                                                                "v-list-item",
                                                                {
                                                                  on: {
                                                                    click: function(
                                                                      $event
                                                                    ) {
                                                                      return _vm.removePhoto(
                                                                        index,
                                                                        image.id
                                                                      )
                                                                    }
                                                                  }
                                                                },
                                                                [
                                                                  _c(
                                                                    "v-list-item-title",
                                                                    [
                                                                      _c(
                                                                        "v-icon",
                                                                        [
                                                                          _vm._v(
                                                                            _vm._s(
                                                                              _vm.mdiDelete
                                                                            )
                                                                          )
                                                                        ]
                                                                      ),
                                                                      _vm._v(
                                                                        " Delete "
                                                                      )
                                                                    ],
                                                                    1
                                                                  )
                                                                ],
                                                                1
                                                              )
                                                            ],
                                                            1
                                                          )
                                                        ],
                                                        1
                                                      )
                                                    ],
                                                    1
                                                  )
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                })
                              ],
                              2
                            )
                          ],
                          1
                        ),
                        _vm.showMarkupImageDialog[_vm.selectedFile.file_id]
                          ? _c("MarkupImageDialog", {
                              attrs: {
                                showMarkupImageDialog:
                                  _vm.showMarkupImageDialog[
                                    _vm.selectedFile.file_id
                                  ],
                                selectedFile: _vm.selectedFile
                              },
                              on: {
                                "markup-image-dialog-close": function($event) {
                                  _vm.showMarkupImageDialog = {}
                                  _vm.onFileMarkupPreviewSaved()
                                },
                                "file-markup-preview-saved":
                                  _vm.onFileMarkupPreviewSaved
                              }
                            })
                          : _vm._e()
                      ],
                      1
                    ),
                    _c(
                      "v-card-actions",
                      { staticClass: "d-flex justify-end px-5" },
                      [
                        _c(
                          "v-btn",
                          {
                            attrs: {
                              color: "#3F51B5",
                              dark: isFormValid && !_vm.uploadingFiles,
                              disabled: !isFormValid || _vm.uploadingFiles
                            },
                            on: { click: _vm.save }
                          },
                          [_vm._v(" Save Action Item ")]
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ]
            }
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }