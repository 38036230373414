<template>
  <v-dialog
    :value="showEditRepeatingGroupDialog"
    max-width="600px"
    :fullscreen="$vuetify.breakpoint.xsOnly"
  >
    <v-card>
      <v-toolbar dark color="#3F51B5">
        <v-toolbar-title>{{ item.question.label }}</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon dark @click="closeForm">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>

      <v-card-text
        class="pa-5 ma-0"
        id="edit-repeating-group-dynamic-form-card-text"
      >
        <validation-observer ref="groupForm">
          <template v-for="item of newSelectedValueItems">
            <template v-if="isVisible(item)">
              <template v-if="item.question">
                <template v-if="['FILE'].includes(getItemType(item))">
                  <GroupFileInput
                    v-if="!item.question.isImage"
                    :key="item.id"
                    :item="item"
                    :readOnly="readOnly"
                    v-model="item.value"
                    @validated="onFieldValidated"
                    @input="$forceUpdate()"
                  />
                  <GroupPhotoInput
                    v-else
                    :key="item.id"
                    :item="item"
                    :allowMultiple="item.allowMultiple"
                    :readOnly="readOnly"
                    :formResultId="formResultId"
                    :formDefinition="formDefinition"
                    :canEdit="canEdit"
                    :isEditingFinalForm="isEditingFinalForm"
                    :alreadySubmittedFinalOnline="alreadySubmittedFinalOnline"
                    :selectedGisInfo="selectedGisInfo"
                    :globalId="globalId"
                    :objectId="objectId"
                    v-model="item.value"
                    @validated="onFieldValidated"
                  />
                </template>
                <GroupMultiSelectInput
                  :key="item.id"
                  v-else-if="['MULTI_SELECT'].includes(getItemType(item))"
                  :item="item"
                  :readOnly="readOnly"
                  v-model="item.value"
                  @input="$forceUpdate()"
                />
                <GroupSignaturePad
                  v-else-if="['SIGNATURE'].includes(getItemType(item))"
                  :key="item.id"
                  :item="item"
                  :readOnly="readOnly"
                  v-model="item.value"
                  @validated="onFieldValidated"
                  @input="$forceUpdate()"
                />
                <GroupSingleSelectInput
                  :key="item.id"
                  v-else-if="['SINGLE_SELECT'].includes(getItemType(item))"
                  :item="item"
                  :readOnly="readOnly"
                  v-model="item.value"
                  @validated="onFieldValidated"
                  @input="$forceUpdate()"
                />
                <GroupDateInput
                  :key="item.id"
                  v-else-if="['DATE'].includes(getItemType(item))"
                  :item="item"
                  :readOnly="readOnly"
                  v-model="item.value"
                  @validated="onFieldValidated"
                  @input="$forceUpdate()"
                />
                <GroupTimeInput
                  :key="item.id"
                  v-else-if="['TIME'].includes(getItemType(item))"
                  :item="item"
                  :readOnly="readOnly"
                  v-model="item.value"
                  @validated="onFieldValidated"
                  @input="$forceUpdate()"
                />
                <GroupTextInput
                  v-else-if="
                    ['TEXT', 'EMAIL', 'NUMBER'].includes(getItemType(item))
                  "
                  :key="item.id"
                  :item="item"
                  :readOnly="readOnly"
                  :formDefinition="formDefinition"
                  :isRequired="isRequired(item)"
                  v-model="item.value"
                  @validated="onFieldValidated"
                  @input="$forceUpdate()"
                />
              </template>
              <RepeatingGroupInfo
                :key="item.id"
                :item="item"
                v-else-if="item.information"
              />
            </template>
          </template>
        </validation-observer>
      </v-card-text>

      <v-card-actions class="d-flex justify-end px-5">
        <v-btn
          text
          color="#3F51B5"
          :disabled="!canAddOrDeleteRows || readOnly"
          @click="$emit('delete-item', selectedValueIndex)"
        >
          Delete
        </v-btn>
        <v-btn
          color="#3F51B5"
          @click="save"
          :disabled="readOnly"
          class="white--text"
        >
          Save
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import GroupFileInput from "@/components/tickets/shared/repeating-group/repeating-group-dialog/GroupFileInput";
import GroupMultiSelectInput from "@/components/tickets/shared/repeating-group/repeating-group-dialog/GroupMultiSelectInput";
import GroupPhotoInput from "@/components/tickets/shared/repeating-group/repeating-group-dialog/GroupPhotoInput";
import GroupSignaturePad from "@/components/tickets/shared/repeating-group/repeating-group-dialog/GroupSignaturePad";
import GroupSingleSelectInput from "@/components/tickets/shared/repeating-group/repeating-group-dialog/GroupSingleSelectInput";
import GroupTextInput from "@/components/tickets/shared/repeating-group/repeating-group-dialog/GroupTextInput";
import GroupDateInput from "@/components/tickets/shared/repeating-group/repeating-group-dialog/GroupDateInput";
import GroupTimeInput from "@/components/tickets/shared/repeating-group/repeating-group-dialog/GroupTimeInput";
import { cloneDeep } from "lodash";
import RepeatingGroupInfo from "@/components/tickets/shared/repeating-group/RepeatingGroupInfo";

export default {
  name: "EditRepeatingGroupDialog",
  props: {
    showEditRepeatingGroupDialog: Boolean,
    selectedValueItems: Array,
    selectedValueIndex: Number,
    item: Object,
    readOnly: Boolean,
    formResultId: String,
    objectId: Number,
    globalId: String,
    alreadySubmittedFinalOnline: Boolean,
    isEditingFinalForm: Boolean,
    canEdit: Boolean,
    selectedGisInfo: Object,
    formDefinition: Object,
    canAddOrDeleteRows: Boolean,
  },
  components: {
    GroupFileInput,
    GroupMultiSelectInput,
    GroupPhotoInput,
    GroupSignaturePad,
    GroupSingleSelectInput,
    GroupTextInput,
    GroupDateInput,
    GroupTimeInput,
    RepeatingGroupInfo,
  },
  beforeMount() {
    this.newSelectedValueItems = [...this.item.question.groupedItems];
    for (const item of this.newSelectedValueItems) {
      const valueItem = this.selectedValueItems?.find(
        (it) => it.id === item.id
      );
      item.value = valueItem?.value;

      if (item.question?.type === "FILE") {
        if (item.value && !Array.isArray(item.value)) {
          item.value = [item.value];
        }
      }

      if (item.information?.type === "IMAGE" && item.information?.dynamic) {
        const infoImageItem = this.selectedValueItems?.find(
          (it) => it.id === item.id
        );
        if (infoImageItem) {
          item.information = infoImageItem.information;
        }
      }
    }
  },
  computed: {
    isFormValid() {
      const values = Object.values(this.fieldValidationResult);
      return values.every((r) => Boolean(r.valid));
    },
  },
  methods: {
    getItemType(item) {
      return item?.question?.type;
    },
    closeForm() {
      this.$emit("close-form");
    },
    async save() {
      const success = await this.$refs.groupForm.validate();
      if (!success) {
        return;
      }
      const savedValues = cloneDeep(this.newSelectedValueItems);
      this.$emit("form-saved", savedValues);
    },
    onFieldValidated(result) {
      const { id, ...restResult } = result;
      this.$set(this.fieldValidationResult, id, restResult);
    },
    isVisible(item) {
      if (item.question) {
        return this.checkVisibility("question", item);
      } else {
        return this.checkVisibility("information", item);
      }
    },
    checkVisibility(type = "question", item) {
      if (
        !item[type]?.visible?.condition ||
        item[type]?.visible?.condition === "ALWAYS"
      ) {
        return true;
      }

      if (item[type]?.visible?.condition === "NEVER") {
        return false;
      }
      const { sections = [] } = { ...this.formDefinition?.form };
      for (const section of sections) {
        for (const dependantItem of section.items) {
          if (+dependantItem.id === +item[type]?.visible?.dependantId) {
            if (dependantItem?.question?.type === "GROUP") {
              for (const groupDependantItem of dependantItem?.question
                ?.groupedItems ?? []) {
                if (
                  +groupDependantItem.id ===
                  +item?.question?.visible?.dependantGroupItemId
                ) {
                  const groupDependantItemValue = groupDependantItem.value;
                  if (Array.isArray(groupDependantItemValue)) {
                    if (groupDependantItemValue.length > 1) {
                      return false;
                    } else {
                      const [dependantItemValue] = groupDependantItemValue;
                      return this.checkDependantValue(
                        dependantItemValue,
                        type,
                        "visible",
                        item
                      );
                    }
                  } else {
                    return this.checkDependantValue(
                      groupDependantItemValue,
                      type,
                      "visible",
                      item
                    );
                  }
                }
              }
            } else {
              const dependantItemValue = dependantItem.value;
              if (Array.isArray(dependantItemValue)) {
                if (dependantItemValue.length > 1) {
                  return false;
                } else {
                  const [dependantValue] = dependantItemValue;
                  return this.checkDependantValue(
                    dependantValue,
                    type,
                    "visible",
                    item
                  );
                }
              } else {
                return this.checkDependantValue(
                  dependantItemValue,
                  type,
                  "visible",
                  item
                );
              }
            }
          }
        }
      }
      return item[type].visible.condition === "ALWAYS";
    },
    checkDependantValue(
      dependantItemValue,
      type = "question",
      field = "required",
      item
    ) {
      if (item[type][field].condition === "NOT_EQUAL") {
        return (
          +dependantItemValue !== +item[type][field].value &&
          dependantItemValue !== item[type][field].value
        );
      } else if (item[type][field].condition === "EQUAL") {
        return (
          +dependantItemValue === +item[type][field].value ||
          dependantItemValue === item[type][field].value
        );
      } else if (item[type][field].condition === "GREATER_THAN") {
        return +dependantItemValue > +item[type][field].value;
      } else if (item[type][field].condition === "GREATER_THAN_OR_EQUAL") {
        return +dependantItemValue >= +item[type][field].value;
      } else if (item[type][field].condition === "LESS_THAN") {
        return +dependantItemValue < +item[type][field].value;
      } else if (item[type][field].condition === "LESS_THAN_OR_EQUAL") {
        return +dependantItemValue <= +item[type][field].value;
      }
    },
    isRequired(item) {
      if (item?.question?.required?.condition === "NEVER") {
        return false;
      }

      if (item?.question?.required?.condition === "ALWAYS") {
        return true;
      }
      const { sections } = { ...this.formDefinition.form };
      for (const section of sections) {
        for (const dependantItem of section.items) {
          if (+dependantItem.id === +item?.question?.required?.dependantId) {
            if (dependantItem?.question?.type === "GROUP") {
              for (const groupDependantItem of dependantItem?.question
                ?.groupedItems ?? []) {
                if (
                  +groupDependantItem.id ===
                  +item?.question?.required?.dependantGroupItemId
                ) {
                  const groupDependantItemValue = groupDependantItem.value;
                  if (Array.isArray(groupDependantItemValue)) {
                    if (groupDependantItemValue.length > 1) {
                      return false;
                    } else {
                      const [dependantItemValue] = groupDependantItemValue;
                      return this.checkDependantValue(
                        dependantItemValue,
                        undefined,
                        undefined,
                        item
                      );
                    }
                  } else {
                    return this.checkDependantValue(
                      groupDependantItemValue,
                      undefined,
                      undefined,
                      item
                    );
                  }
                }
              }
            } else {
              const dependantItemValue = dependantItem.value;
              if (Array.isArray(dependantItemValue)) {
                if (dependantItemValue.length > 1) {
                  return false;
                } else {
                  const [dependantValue] = dependantItemValue;
                  return this.checkDependantValue(
                    dependantValue,
                    undefined,
                    undefined,
                    item
                  );
                }
              } else {
                return this.checkDependantValue(
                  dependantItemValue,
                  undefined,
                  undefined,
                  item
                );
              }
            }
          }
        }
      }
      return item?.question?.required?.condition === "ALWAYS";
    },
  },
  data() {
    return {
      fieldValidationResult: {},
      newSelectedValueItems: [],
    };
  },
};
</script>

<style>
#edit-repeating-group-dynamic-form-card-text {
  max-height: 60vh;
  overflow-y: auto;
}
</style>
