<template>
  <v-dialog
    :value="showRepeatingGroupDialog"
    max-width="600px"
    :fullscreen="$vuetify.breakpoint.xsOnly"
  >
    <v-card>
      <v-toolbar dark color="#3F51B5">
        <v-toolbar-title>
          {{ item.question.label }}
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon dark @click="closeForm">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>

      <v-card-text
        class="pa-5 ma-0"
        id="repeating-group-dynamic-form-card-text"
        v-if="showForm"
      >
        <validation-observer ref="groupForm">
          <template v-for="item of groupedItems">
            <template v-if="isVisible(item)">
              <template v-if="item.question">
                <template v-if="['FILE'].includes(getItemType(item))">
                  <GroupFileInput
                    v-if="!item.question.isImage"
                    :key="item.id"
                    :item="item"
                    :readOnly="readOnly"
                    v-model="item.value"
                    @input="$forceUpdate()"
                  />
                  <GroupPhotoInput
                    v-else
                    :key="item.id"
                    :item="item"
                    :readOnly="readOnly"
                    :allowMultiple="item.allowMultiple"
                    :formResultId="formResultId"
                    :formDefinition="formDefinition"
                    :canEdit="canEdit"
                    :isEditingFinalForm="isEditingFinalForm"
                    :alreadySubmittedFinalOnline="alreadySubmittedFinalOnline"
                    :globalId="globalId"
                    :objectId="objectId"
                    v-model="item.value"
                    @input="$forceUpdate()"
                  />
                </template>
                <GroupMultiSelectInput
                  :key="item.id"
                  v-else-if="['MULTI_SELECT'].includes(getItemType(item))"
                  :item="item"
                  :readOnly="readOnly"
                  v-model="item.value"
                  @input="$forceUpdate()"
                />
                <GroupSignaturePad
                  v-else-if="['SIGNATURE'].includes(getItemType(item))"
                  :key="item.id"
                  :item="item"
                  :readOnly="readOnly"
                  v-model="item.value"
                  @input="$forceUpdate()"
                />
                <GroupSingleSelectInput
                  :key="item.id"
                  v-else-if="['SINGLE_SELECT'].includes(getItemType(item))"
                  :item="item"
                  :readOnly="readOnly"
                  v-model="item.value"
                  @input="$forceUpdate()"
                />
                <GroupDateInput
                  :key="item.id"
                  v-else-if="['DATE'].includes(getItemType(item))"
                  :item="item"
                  :readOnly="readOnly"
                  v-model="item.value"
                  @input="$forceUpdate()"
                />
                <GroupTimeInput
                  :key="item.id"
                  v-else-if="['TIME'].includes(getItemType(item))"
                  :item="item"
                  :readOnly="readOnly"
                  v-model="item.value"
                  @input="$forceUpdate()"
                />
                <GroupTextInput
                  v-else-if="
                    ['TEXT', 'EMAIL', 'NUMBER'].includes(getItemType(item))
                  "
                  :key="item.id"
                  :item="item"
                  :readOnly="readOnly"
                  :formDefinition="formDefinition"
                  :isRequired="isRequired(item)"
                  v-model="item.value"
                  @input="$forceUpdate()"
                />
              </template>
              <RepeatingGroupInfo
                :key="item.id"
                :item="item"
                v-else-if="item.information"
              />
            </template>
          </template>
        </validation-observer>
      </v-card-text>

      <v-card-actions class="d-flex justify-end px-5">
        <v-btn color="#3F51B5" @click="save" class="white--text"> Save </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import GroupFileInput from "@/components/tickets/shared/repeating-group/repeating-group-dialog/GroupFileInput";
import GroupMultiSelectInput from "@/components/tickets/shared/repeating-group/repeating-group-dialog/GroupMultiSelectInput";
import GroupPhotoInput from "@/components/tickets/shared/repeating-group/repeating-group-dialog/GroupPhotoInput";
import GroupSignaturePad from "@/components/tickets/shared/repeating-group/repeating-group-dialog/GroupSignaturePad";
import GroupSingleSelectInput from "@/components/tickets/shared/repeating-group/repeating-group-dialog/GroupSingleSelectInput";
import GroupTextInput from "@/components/tickets/shared/repeating-group/repeating-group-dialog/GroupTextInput";
import GroupDateInput from "@/components/tickets/shared/repeating-group/repeating-group-dialog/GroupDateInput";
import GroupTimeInput from "@/components/tickets/shared/repeating-group/repeating-group-dialog/GroupTimeInput";
import { cloneDeep } from "lodash";
import { axiosWithNoAuth } from "@/plugins/axios";
import { db } from "@/mixins/utilisync-db";
import { getRepeatingGroupDefaultValues } from "@/mixins/getDefaultValues";
import RepeatingGroupInfo from "@/components/tickets/shared/repeating-group/RepeatingGroupInfo";

export default {
  name: "RepeatingGroupDialog",
  props: {
    showRepeatingGroupDialog: Boolean,
    item: Object,
    readOnly: Boolean,
    formResultId: String,
    selectedMapServiceId: String,
    objectId: Number,
    globalId: String,
    alreadySubmittedFinalOnline: Boolean,
    isEditingFinalForm: Boolean,
    canEdit: Boolean,
    formDefinition: Object,
  },
  components: {
    GroupFileInput,
    GroupMultiSelectInput,
    GroupPhotoInput,
    GroupSignaturePad,
    GroupSingleSelectInput,
    GroupTextInput,
    GroupDateInput,
    GroupTimeInput,
    RepeatingGroupInfo,
  },
  data() {
    return {
      showForm: false,
      gisInfoQueryResult: {},
    };
  },
  computed: {
    groupedItems() {
      return this.item.question.groupedItems;
    },
    isFormValid() {
      const values = Object.values(this.fieldValidationResult);
      return values.every((r) => Boolean(r.valid));
    },
  },
  async beforeMount() {
    this.showForm = false;
    for (const item of this.item.question.groupedItems) {
      item.value = undefined;
    }
    await this.getFeature();
    await this.prePopulateGroupValues();
    this.showForm = true;
  },
  methods: {
    async prePopulateGroupValues() {
      const gisDataValues = await this.getGisDataFieldsAndValuesOffline();
      const userDataValues = await this.getUserDataFieldsAndValuesOffline();
      const { item, formResultId } = this;
      const featureAttributes = {
        ...this.gisInfoQueryResult?.features?.[0]?.attributes,
      };

      const [firstRowGroupedItems] = await getRepeatingGroupDefaultValues({
        item,
        userDataValues,
        featureAttributes,
        gisDataValues,
        formResultId,
      });

      this.item.question.groupedItems = firstRowGroupedItems;

      for (const groupedItem of this.item.question.groupedItems) {
        const defaultSettings = groupedItem?.question?.default ?? {};
        const { value } = defaultSettings;
        if (groupedItem?.question?.type === "MULTI_SELECT") {
          if (Array.isArray(value)) {
            const allStrings = value.every((v) => typeof v === "string");
            if (allStrings) {
              groupedItem.value = value.map((v) => {
                return {
                  key: v,
                  val: v,
                };
              });
            } else {
              groupedItem.value = value;
            }
          } else {
            groupedItem.value = [{ val: value }];
          }
        }

        if (
          Object.keys(defaultSettings).includes("applyDefaultValue") &&
          !defaultSettings.applyDefaultValue
        ) {
          groupedItem.value = undefined;
        }
      }
    },
    getItemType(item) {
      return item?.question?.type;
    },
    async getGisDataFieldsAndValuesOffline() {
      const { selectedMapServiceId, objectId } = this;
      const gisDataValues = await db.gisDataValues.toCollection().toArray();
      const gisDataValueIds = gisDataValues.map((g) => g.gis_data_field_id);
      const gisDataFields = await db.gisDataFields
        .filter((g) => {
          return (
            g.map_service_id === selectedMapServiceId &&
            !gisDataValueIds.includes(objectId)
          );
        })
        .toArray();
      return gisDataFields?.map((gdf) => {
        const { gis_data_field_id: gisDataFieldId } = gdf;
        const gisDataValue = gisDataValues?.find((gdv) => {
          return (
            gdv?.gis_data_field_id === gdf?.gis_data_field_id &&
            +gdv?.feature_id === +objectId
          );
        });
        const value = gisDataValue?.value;
        const gisDataValueId = gisDataValue?.gis_data_value_id;
        return {
          feature_id: objectId,
          gis_data_field_id: gisDataFieldId,
          gis_data_value_id: gisDataValueId,
          value,
        };
      });
    },
    async getUserDataFieldsAndValuesOffline() {
      return await db.userDataValues.toCollection().toArray();
    },
    async getFeature() {
      const { selectedMapServiceId, objectId } = this;
      const [selectedMapService] = await db.mapServices
        .filter((m) => m.map_service_id === selectedMapServiceId)
        .toArray();
      this.selectedMapService = selectedMapService;
      if (selectedMapService.service_type === "F") {
        if (!navigator.onLine) {
          return;
        }
        const { data: queryResult } = await axiosWithNoAuth.get(
          `${selectedMapService.service_url}/query`,
          {
            params: {
              objectids: objectId,
              outFields: "*",
              f: "json",
              token: localStorage.getItem("esri_token"),
            },
          }
        );
        this.gisInfoQueryResult = queryResult;
      }
    },
    closeForm() {
      this.$emit("close-form");
    },
    async save() {
      const success = await this.$refs.groupForm.validate();
      if (!success) {
        return;
      }
      const savedValues = cloneDeep(this.groupedItems);
      this.$emit("form-saved", savedValues);
    },
    onFieldValidated(result) {
      const { id, ...restResult } = result;
      this.$set(this.fieldValidationResult, id, restResult);
    },
    isVisible(item) {
      if (item.question) {
        return this.checkVisibility("question", item);
      } else {
        return this.checkVisibility("information", item);
      }
    },
    checkVisibility(type = "question", item) {
      if (
        !item[type]?.visible?.condition ||
        item[type]?.visible?.condition === "ALWAYS"
      ) {
        return true;
      }

      if (item[type]?.visible?.condition === "NEVER") {
        return false;
      }

      const { sections = [] } = { ...this.formDefinition?.form };
      for (const section of sections) {
        for (const dependantItem of section.items) {
          if (+dependantItem.id === +item[type]?.visible?.dependantId) {
            if (dependantItem?.question?.type === "GROUP") {
              for (const groupDependantItem of dependantItem?.question
                ?.groupedItems ?? []) {
                if (
                  +groupDependantItem.id ===
                  +item.question.visible.dependantGroupItemId
                ) {
                  const groupDependantItemValue = groupDependantItem.value;
                  if (Array.isArray(groupDependantItemValue)) {
                    if (groupDependantItemValue.length > 1) {
                      return false;
                    } else {
                      const [dependantItemValue] = groupDependantItemValue;
                      return this.checkDependantValue(
                        dependantItemValue,
                        type,
                        "visible",
                        item
                      );
                    }
                  } else {
                    return this.checkDependantValue(
                      groupDependantItemValue,
                      type,
                      "visible",
                      item
                    );
                  }
                }
              }
            } else {
              const dependantItemValue = dependantItem.value;
              if (Array.isArray(dependantItemValue)) {
                if (dependantItemValue.length > 1) {
                  return false;
                } else {
                  const [dependantValue] = dependantItemValue;
                  return this.checkDependantValue(
                    dependantValue,
                    type,
                    "visible",
                    item
                  );
                }
              } else {
                return this.checkDependantValue(
                  dependantItemValue,
                  type,
                  "visible",
                  item
                );
              }
            }
          }
        }
      }
      return item[type]?.visible?.condition === "ALWAYS";
    },
    checkDependantValue(
      dependantItemValue,
      type = "question",
      field = "required",
      item
    ) {
      if (item[type][field].condition === "NOT_EQUAL") {
        return (
          +dependantItemValue !== +item[type][field].value &&
          dependantItemValue !== item[type][field].value
        );
      } else if (item[type][field].condition === "EQUAL") {
        return (
          +dependantItemValue === +item[type][field].value ||
          dependantItemValue === item[type][field].value
        );
      } else if (item[type][field].condition === "GREATER_THAN") {
        return +dependantItemValue > +item[type][field].value;
      } else if (item[type][field].condition === "GREATER_THAN_OR_EQUAL") {
        return +dependantItemValue >= +item[type][field].value;
      } else if (item[type][field].condition === "LESS_THAN") {
        return +dependantItemValue < +item[type][field].value;
      } else if (item[type][field].condition === "LESS_THAN_OR_EQUAL") {
        return +dependantItemValue <= +item[type][field].value;
      }
    },
    isRequired(item) {
      if (
        !item.question?.required?.condition ||
        item.question?.required?.condition === "NEVER"
      ) {
        return false;
      }

      if (item.question?.required?.condition === "ALWAYS") {
        return true;
      }
      const { sections } = { ...this.formDefinition.form };
      for (const section of sections) {
        for (const dependantItem of section.items) {
          if (+dependantItem.id === +item.question.required.dependantId) {
            if (dependantItem?.question?.type === "GROUP") {
              for (const groupDependantItem of dependantItem?.question
                ?.groupedItems ?? []) {
                if (
                  +groupDependantItem.id ===
                  +item.question.required.dependantGroupItemId
                ) {
                  const groupDependantItemValue = groupDependantItem.value;
                  if (Array.isArray(groupDependantItemValue)) {
                    if (groupDependantItemValue.length > 1) {
                      return false;
                    } else {
                      const [dependantItemValue] = groupDependantItemValue;
                      return this.checkDependantValue(
                        dependantItemValue,
                        undefined,
                        undefined,
                        item
                      );
                    }
                  } else {
                    return this.checkDependantValue(
                      groupDependantItemValue,
                      undefined,
                      undefined,
                      item
                    );
                  }
                }
              }
            } else {
              const dependantItemValue = dependantItem.value;
              if (Array.isArray(dependantItemValue)) {
                if (dependantItemValue.length > 1) {
                  return false;
                } else {
                  const [dependantValue] = dependantItemValue;
                  return this.checkDependantValue(
                    dependantValue,
                    undefined,
                    undefined,
                    item
                  );
                }
              } else {
                return this.checkDependantValue(
                  dependantItemValue,
                  undefined,
                  undefined,
                  item
                );
              }
            }
          }
        }
      }
      return item.question.required.condition === "ALWAYS";
    },
  },
};
</script>

<style>
#repeating-group-dynamic-form-card-text {
  max-height: 60vh;
  overflow-y: auto;
}
</style>
