<template>
  <v-card-actions class="px-0 py-2 ma-0 d-flex justify-end">
    <v-btn
      text
      color="#3F51B5"
      v-if="canEdit && editableAfterFinal"
      @click="$emit('edit-form')"
    >
      <v-icon class="mr-1 mt-n1">
        {{ mdiPencil }}
      </v-icon>
      <span v-if="$vuetify.breakpoint.smAndUp">Edit</span>
    </v-btn>
    <v-btn
      text
      color="#3F51B5"
      v-if="selectedPdfFileUrl"
      @click="showPdfDialog = true"
    >
      <v-icon class="mr-1 mt-n1">
        {{ mdiFileDocument }}
      </v-icon>
      <span v-if="$vuetify.breakpoint.smAndUp">View PDF</span>
    </v-btn>
    <v-btn text color="#3F51B5" @click="showUtilibotDialog = true">
      <img
        src="@/assets/UtiliBotIconPrimaryBlue.svg"
        height="24"
        width="24"
        class="mr-2"
      />

      <span v-if="$vuetify.breakpoint.smAndUp">Utilibot Summary</span>
    </v-btn>

    <v-menu offset-y>
      <template v-slot:activator="{ on, attrs }">
        <v-btn text color="#3F51B5" dark v-bind="attrs" v-on="on">
          <v-icon>
            {{ mdiDotsVertical }}
          </v-icon>
        </v-btn>
      </template>
      <v-list class="px-0 mx-0">
        <v-list-item class="px-4 mx-0" @click="showFormIdDialog = true">
          <v-icon class="mr-2">
            {{ mdiInformation }}
          </v-icon>
          Form Submission ID
        </v-list-item>
        <v-list-item class="px-4 mx-0" @click="showDeleteFormDialog = true">
          <v-icon class="mr-2">
            {{ mdiDelete }}
          </v-icon>
          Delete Form
        </v-list-item>
      </v-list>
    </v-menu>

    <DeleteFormDialog
      :showDeleteFormDialog="showDeleteFormDialog"
      @cancel-delete-form="showDeleteFormDialog = false"
      @delete-form="deleteForm"
    />

    <FormIdDialog
      :showFormIdDialog="showFormIdDialog"
      :formResultId="formResultId"
    />

    <UtilibotDialog
      :showUtilibotDialog="showUtilibotDialog"
      :formResultId="formResultId"
      @utilibot-dialog-close="showUtilibotDialog = false"
    />

    <PdfViewerDialog
      v-if="showPdfDialog"
      :showPdfDialog="showPdfDialog"
      :selectedPdfFileUrl="selectedPdfFileUrl"
      @pdf-viewer-dialog-close="showPdfDialog = false"
    />
  </v-card-actions>
</template>

<script>
import {
  mdiDotsVertical,
  mdiPencil,
  mdiFileDocument,
  mdiInformation,
  mdiDelete,
} from "@mdi/js";
import DeleteFormDialog from "@/components/tickets/ticket-edit-form/dynamic-form/dynamic-form-edit-actions/DeleteFormDialog";
import FormIdDialog from "@/components/tickets/ticket-edit-form/dynamic-form/dynamic-form-edit-actions/FormIdDialog";
import UtilibotDialog from "@/components/tickets/ticket-edit-form/dynamic-form/dynamic-form-edit-actions/UtilibotDialog";
import PdfViewerDialog from "@/components/tickets/ticket-log/PdfViewerDialog";
import { axiosWithRegularAuth } from "@/plugins/axios";

const APIURL = process.env.VUE_APP_API_URL;

export default {
  name: "DynamicFormEditActions",
  data() {
    return {
      mdiDotsVertical,
      mdiPencil,
      mdiFileDocument,
      mdiInformation,
      mdiDelete,
      showDeleteFormDialog: false,
      showFormIdDialog: false,
      showUtilibotDialog: false,
      showPdfDialog: false,
    };
  },
  components: {
    DeleteFormDialog,
    FormIdDialog,
    UtilibotDialog,
    PdfViewerDialog,
  },
  props: {
    canEdit: Boolean,
    formResultId: String,
    DeleteFormDialog: Boolean,
    selectedPdfFileUrl: String,
    editableAfterFinal: Boolean,
  },
  methods: {
    async deleteForm() {
      await axiosWithRegularAuth.delete(
        `${APIURL}/form_results/${this.formResultId}`
      );
      this.$emit("form-result-deleted");
    },
  },
};
</script>

<style scoped>
.icon {
  width: 20px;
  height: 20px;
}
</style>
