var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: {
        value: _vm.showEditRepeatingGroupDialog,
        "max-width": "600px",
        fullscreen: _vm.$vuetify.breakpoint.xsOnly
      }
    },
    [
      _c(
        "v-card",
        [
          _c(
            "v-toolbar",
            { attrs: { dark: "", color: "#3F51B5" } },
            [
              _c("v-toolbar-title", [_vm._v(_vm._s(_vm.item.question.label))]),
              _c("v-spacer"),
              _c(
                "v-btn",
                { attrs: { icon: "", dark: "" }, on: { click: _vm.closeForm } },
                [_c("v-icon", [_vm._v("mdi-close")])],
                1
              )
            ],
            1
          ),
          _c(
            "v-card-text",
            {
              staticClass: "pa-5 ma-0",
              attrs: { id: "edit-repeating-group-dynamic-form-card-text" }
            },
            [
              _c(
                "validation-observer",
                { ref: "groupForm" },
                [
                  _vm._l(_vm.newSelectedValueItems, function(item) {
                    return [
                      _vm.isVisible(item)
                        ? [
                            item.question
                              ? [
                                  ["FILE"].includes(_vm.getItemType(item))
                                    ? [
                                        !item.question.isImage
                                          ? _c("GroupFileInput", {
                                              key: item.id,
                                              attrs: {
                                                item: item,
                                                readOnly: _vm.readOnly
                                              },
                                              on: {
                                                validated: _vm.onFieldValidated,
                                                input: function($event) {
                                                  return _vm.$forceUpdate()
                                                }
                                              },
                                              model: {
                                                value: item.value,
                                                callback: function($$v) {
                                                  _vm.$set(item, "value", $$v)
                                                },
                                                expression: "item.value"
                                              }
                                            })
                                          : _c("GroupPhotoInput", {
                                              key: item.id,
                                              attrs: {
                                                item: item,
                                                allowMultiple:
                                                  item.allowMultiple,
                                                readOnly: _vm.readOnly,
                                                formResultId: _vm.formResultId,
                                                formDefinition:
                                                  _vm.formDefinition,
                                                canEdit: _vm.canEdit,
                                                isEditingFinalForm:
                                                  _vm.isEditingFinalForm,
                                                alreadySubmittedFinalOnline:
                                                  _vm.alreadySubmittedFinalOnline,
                                                selectedGisInfo:
                                                  _vm.selectedGisInfo,
                                                globalId: _vm.globalId,
                                                objectId: _vm.objectId
                                              },
                                              on: {
                                                validated: _vm.onFieldValidated
                                              },
                                              model: {
                                                value: item.value,
                                                callback: function($$v) {
                                                  _vm.$set(item, "value", $$v)
                                                },
                                                expression: "item.value"
                                              }
                                            })
                                      ]
                                    : ["MULTI_SELECT"].includes(
                                        _vm.getItemType(item)
                                      )
                                    ? _c("GroupMultiSelectInput", {
                                        key: item.id,
                                        attrs: {
                                          item: item,
                                          readOnly: _vm.readOnly
                                        },
                                        on: {
                                          input: function($event) {
                                            return _vm.$forceUpdate()
                                          }
                                        },
                                        model: {
                                          value: item.value,
                                          callback: function($$v) {
                                            _vm.$set(item, "value", $$v)
                                          },
                                          expression: "item.value"
                                        }
                                      })
                                    : ["SIGNATURE"].includes(
                                        _vm.getItemType(item)
                                      )
                                    ? _c("GroupSignaturePad", {
                                        key: item.id,
                                        attrs: {
                                          item: item,
                                          readOnly: _vm.readOnly
                                        },
                                        on: {
                                          validated: _vm.onFieldValidated,
                                          input: function($event) {
                                            return _vm.$forceUpdate()
                                          }
                                        },
                                        model: {
                                          value: item.value,
                                          callback: function($$v) {
                                            _vm.$set(item, "value", $$v)
                                          },
                                          expression: "item.value"
                                        }
                                      })
                                    : ["SINGLE_SELECT"].includes(
                                        _vm.getItemType(item)
                                      )
                                    ? _c("GroupSingleSelectInput", {
                                        key: item.id,
                                        attrs: {
                                          item: item,
                                          readOnly: _vm.readOnly
                                        },
                                        on: {
                                          validated: _vm.onFieldValidated,
                                          input: function($event) {
                                            return _vm.$forceUpdate()
                                          }
                                        },
                                        model: {
                                          value: item.value,
                                          callback: function($$v) {
                                            _vm.$set(item, "value", $$v)
                                          },
                                          expression: "item.value"
                                        }
                                      })
                                    : ["DATE"].includes(_vm.getItemType(item))
                                    ? _c("GroupDateInput", {
                                        key: item.id,
                                        attrs: {
                                          item: item,
                                          readOnly: _vm.readOnly
                                        },
                                        on: {
                                          validated: _vm.onFieldValidated,
                                          input: function($event) {
                                            return _vm.$forceUpdate()
                                          }
                                        },
                                        model: {
                                          value: item.value,
                                          callback: function($$v) {
                                            _vm.$set(item, "value", $$v)
                                          },
                                          expression: "item.value"
                                        }
                                      })
                                    : ["TIME"].includes(_vm.getItemType(item))
                                    ? _c("GroupTimeInput", {
                                        key: item.id,
                                        attrs: {
                                          item: item,
                                          readOnly: _vm.readOnly
                                        },
                                        on: {
                                          validated: _vm.onFieldValidated,
                                          input: function($event) {
                                            return _vm.$forceUpdate()
                                          }
                                        },
                                        model: {
                                          value: item.value,
                                          callback: function($$v) {
                                            _vm.$set(item, "value", $$v)
                                          },
                                          expression: "item.value"
                                        }
                                      })
                                    : ["TEXT", "EMAIL", "NUMBER"].includes(
                                        _vm.getItemType(item)
                                      )
                                    ? _c("GroupTextInput", {
                                        key: item.id,
                                        attrs: {
                                          item: item,
                                          readOnly: _vm.readOnly,
                                          formDefinition: _vm.formDefinition,
                                          isRequired: _vm.isRequired(item)
                                        },
                                        on: {
                                          validated: _vm.onFieldValidated,
                                          input: function($event) {
                                            return _vm.$forceUpdate()
                                          }
                                        },
                                        model: {
                                          value: item.value,
                                          callback: function($$v) {
                                            _vm.$set(item, "value", $$v)
                                          },
                                          expression: "item.value"
                                        }
                                      })
                                    : _vm._e()
                                ]
                              : item.information
                              ? _c("RepeatingGroupInfo", {
                                  key: item.id,
                                  attrs: { item: item }
                                })
                              : _vm._e()
                          ]
                        : _vm._e()
                    ]
                  })
                ],
                2
              )
            ],
            1
          ),
          _c(
            "v-card-actions",
            { staticClass: "d-flex justify-end px-5" },
            [
              _c(
                "v-btn",
                {
                  attrs: {
                    text: "",
                    color: "#3F51B5",
                    disabled: !_vm.canAddOrDeleteRows || _vm.readOnly
                  },
                  on: {
                    click: function($event) {
                      return _vm.$emit("delete-item", _vm.selectedValueIndex)
                    }
                  }
                },
                [_vm._v(" Delete ")]
              ),
              _c(
                "v-btn",
                {
                  staticClass: "white--text",
                  attrs: { color: "#3F51B5", disabled: _vm.readOnly },
                  on: { click: _vm.save }
                },
                [_vm._v(" Save ")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }