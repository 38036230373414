var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card-actions",
    { staticClass: "px-0 py-2 ma-0 d-flex justify-end" },
    [
      _vm.canEdit && _vm.editableAfterFinal
        ? _c(
            "v-btn",
            {
              attrs: { text: "", color: "#3F51B5" },
              on: {
                click: function($event) {
                  return _vm.$emit("edit-form")
                }
              }
            },
            [
              _c("v-icon", { staticClass: "mr-1 mt-n1" }, [
                _vm._v(" " + _vm._s(_vm.mdiPencil) + " ")
              ]),
              _vm.$vuetify.breakpoint.smAndUp
                ? _c("span", [_vm._v("Edit")])
                : _vm._e()
            ],
            1
          )
        : _vm._e(),
      _vm.selectedPdfFileUrl
        ? _c(
            "v-btn",
            {
              attrs: { text: "", color: "#3F51B5" },
              on: {
                click: function($event) {
                  _vm.showPdfDialog = true
                }
              }
            },
            [
              _c("v-icon", { staticClass: "mr-1 mt-n1" }, [
                _vm._v(" " + _vm._s(_vm.mdiFileDocument) + " ")
              ]),
              _vm.$vuetify.breakpoint.smAndUp
                ? _c("span", [_vm._v("View PDF")])
                : _vm._e()
            ],
            1
          )
        : _vm._e(),
      _c(
        "v-btn",
        {
          attrs: { text: "", color: "#3F51B5" },
          on: {
            click: function($event) {
              _vm.showUtilibotDialog = true
            }
          }
        },
        [
          _c("img", {
            staticClass: "mr-2",
            attrs: {
              src: require("@/assets/UtiliBotIconPrimaryBlue.svg"),
              height: "24",
              width: "24"
            }
          }),
          _vm.$vuetify.breakpoint.smAndUp
            ? _c("span", [_vm._v("Utilibot Summary")])
            : _vm._e()
        ]
      ),
      _c(
        "v-menu",
        {
          attrs: { "offset-y": "" },
          scopedSlots: _vm._u([
            {
              key: "activator",
              fn: function(ref) {
                var on = ref.on
                var attrs = ref.attrs
                return [
                  _c(
                    "v-btn",
                    _vm._g(
                      _vm._b(
                        { attrs: { text: "", color: "#3F51B5", dark: "" } },
                        "v-btn",
                        attrs,
                        false
                      ),
                      on
                    ),
                    [
                      _c("v-icon", [
                        _vm._v(" " + _vm._s(_vm.mdiDotsVertical) + " ")
                      ])
                    ],
                    1
                  )
                ]
              }
            }
          ])
        },
        [
          _c(
            "v-list",
            { staticClass: "px-0 mx-0" },
            [
              _c(
                "v-list-item",
                {
                  staticClass: "px-4 mx-0",
                  on: {
                    click: function($event) {
                      _vm.showFormIdDialog = true
                    }
                  }
                },
                [
                  _c("v-icon", { staticClass: "mr-2" }, [
                    _vm._v(" " + _vm._s(_vm.mdiInformation) + " ")
                  ]),
                  _vm._v(" Form Submission ID ")
                ],
                1
              ),
              _c(
                "v-list-item",
                {
                  staticClass: "px-4 mx-0",
                  on: {
                    click: function($event) {
                      _vm.showDeleteFormDialog = true
                    }
                  }
                },
                [
                  _c("v-icon", { staticClass: "mr-2" }, [
                    _vm._v(" " + _vm._s(_vm.mdiDelete) + " ")
                  ]),
                  _vm._v(" Delete Form ")
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c("DeleteFormDialog", {
        attrs: { showDeleteFormDialog: _vm.showDeleteFormDialog },
        on: {
          "cancel-delete-form": function($event) {
            _vm.showDeleteFormDialog = false
          },
          "delete-form": _vm.deleteForm
        }
      }),
      _c("FormIdDialog", {
        attrs: {
          showFormIdDialog: _vm.showFormIdDialog,
          formResultId: _vm.formResultId
        }
      }),
      _c("UtilibotDialog", {
        attrs: {
          showUtilibotDialog: _vm.showUtilibotDialog,
          formResultId: _vm.formResultId
        },
        on: {
          "utilibot-dialog-close": function($event) {
            _vm.showUtilibotDialog = false
          }
        }
      }),
      _vm.showPdfDialog
        ? _c("PdfViewerDialog", {
            attrs: {
              showPdfDialog: _vm.showPdfDialog,
              selectedPdfFileUrl: _vm.selectedPdfFileUrl
            },
            on: {
              "pdf-viewer-dialog-close": function($event) {
                _vm.showPdfDialog = false
              }
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }