var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    { attrs: { width: "100%", elevation: "0" } },
    [
      _c(
        "v-card-text",
        [
          _c(
            "v-row",
            [
              _c(
                "v-col",
                {
                  staticClass: "d-flex align-center justify-space-between",
                  attrs: { cols: "12" }
                },
                [
                  _c("div", [
                    _vm._v(" " + _vm._s(_vm.item.question.label) + " ")
                  ]),
                  _c(
                    "div",
                    [
                      _c(
                        "v-btn",
                        {
                          attrs: {
                            text: "",
                            color: "#3F51B5",
                            disabled: _vm.isDisabled,
                            id: "addActionItemBtn"
                          },
                          on: {
                            click: function($event) {
                              $event.stopPropagation()
                              _vm.showActionItemDialog = true
                            }
                          }
                        },
                        [_vm._v(" + Add ")]
                      )
                    ],
                    1
                  )
                ]
              ),
              _c(
                "v-col",
                { staticClass: "pa-0 ma-0", attrs: { cols: "12" } },
                [
                  _vm.noActionItems
                    ? _c(
                        "v-card",
                        [
                          _c(
                            "v-card-text",
                            { staticClass: "d-flex justify-center" },
                            [
                              _vm._v(
                                " No " + _vm._s(_vm.item.question.label) + " "
                              )
                            ]
                          )
                        ],
                        1
                      )
                    : _c(
                        "v-card",
                        [
                          _c(
                            "v-card-text",
                            { staticClass: "pa-0 ma-0" },
                            [
                              _c("v-data-table", {
                                staticClass: "pa-0 ma-0 cursor-pointer",
                                attrs: {
                                  headers: _vm.headers,
                                  "hide-default-footer": "",
                                  "disable-pagination": "",
                                  "group-by": "groupPosition",
                                  items: _vm.mappedActionItems,
                                  "item-key": "action_item_id"
                                },
                                on: { "click:row": _vm.onRowClick },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "group.header",
                                      fn: function(ref) {
                                        var isOpen = ref.isOpen
                                        var toggle = ref.toggle
                                        var items = ref.items
                                        return [
                                          _c(
                                            "td",
                                            {
                                              staticClass:
                                                "ma-0 pa-0 group-toggle group-header",
                                              attrs: { colspan: "8" },
                                              on: { click: toggle }
                                            },
                                            [
                                              _c(
                                                "div",
                                                { staticClass: "d-flex ml-2" },
                                                [
                                                  _c(
                                                    "v-btn",
                                                    {
                                                      attrs: {
                                                        text: "",
                                                        "x-small": ""
                                                      }
                                                    },
                                                    [
                                                      _c("v-icon", [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              isOpen
                                                                ? _vm.mdiChevronUp
                                                                : _vm.mdiChevronDown
                                                            ) +
                                                            " "
                                                        )
                                                      ])
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "ml-2 text-capitalize"
                                                    },
                                                    [
                                                      _c("b", [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.getGroupHeader(
                                                              items
                                                            )
                                                          )
                                                        )
                                                      ])
                                                    ]
                                                  )
                                                ],
                                                1
                                              )
                                            ]
                                          )
                                        ]
                                      }
                                    },
                                    {
                                      key: "item.due_by",
                                      fn: function(ref) {
                                        var item = ref.item
                                        return [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm._f("date")(item.due_by)
                                              ) +
                                              " "
                                          )
                                        ]
                                      }
                                    },
                                    {
                                      key: "item.action_item_id",
                                      fn: function(ref) {
                                        var item = ref.item
                                        return [
                                          _c(
                                            "v-btn",
                                            {
                                              attrs: {
                                                icon: "",
                                                color: "#3F51B5"
                                              },
                                              on: {
                                                click: function($event) {
                                                  $event.stopPropagation()
                                                  return _vm.onActionItemClick(
                                                    item
                                                  )
                                                }
                                              }
                                            },
                                            [
                                              _vm.isDisabled
                                                ? _c("v-icon", [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(_vm.mdiEye) +
                                                        " "
                                                    )
                                                  ])
                                                : _c("v-icon", [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(_vm.mdiPencil) +
                                                        " "
                                                    )
                                                  ])
                                            ],
                                            1
                                          )
                                        ]
                                      }
                                    }
                                  ],
                                  null,
                                  true
                                )
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                ],
                1
              )
            ],
            1
          ),
          _vm.showActionItemDialog
            ? _c("ActionItemDialog", {
                attrs: {
                  showActionItemDialog: _vm.showActionItemDialog,
                  item: _vm.item,
                  readOnly: _vm.isDisabled,
                  isEditingFinalForm: _vm.isEditingFinalForm,
                  alreadySubmittedFinalOnline: _vm.alreadySubmittedFinalOnline,
                  formResultId: _vm.formResultId,
                  formDefinition: _vm.formDefinition,
                  newActionItems: _vm.actionItems.newActionItems,
                  selectedMapServiceId: _vm.selectedMapServiceId,
                  gisInfoObjectId: _vm.objectId
                },
                on: {
                  "form-saved": _vm.onFormSaved,
                  "close-form": function($event) {
                    _vm.showActionItemDialog = false
                  }
                }
              })
            : _vm._e(),
          _vm.showEditActionItemDialog
            ? _c("EditActionItemDialog", {
                attrs: {
                  formDefinition: _vm.formDefinition,
                  showEditActionItemDialog: _vm.showEditActionItemDialog,
                  readOnly: _vm.isDisabled,
                  alreadySubmittedFinalOnline: _vm.alreadySubmittedFinalOnline,
                  isEditingFinalForm: _vm.isEditingFinalForm,
                  item: _vm.item,
                  selectedActionItem: _vm.selectedActionItem,
                  selectedMapServiceId: _vm.selectedMapServiceId,
                  gisInfoObjectId: _vm.objectId,
                  formResultId: _vm.formResultId
                },
                on: {
                  "form-saved": _vm.onEditFormSaved,
                  "close-form": function($event) {
                    _vm.showEditActionItemDialog = false
                  },
                  "delete-action-item": _vm.deleteActionItem
                }
              })
            : _vm._e(),
          _vm.showActionItemTabActionItemDialog
            ? _c("ActionItemTabActionItemDialog", {
                attrs: {
                  action: _vm.getActionItemLabel(_vm.selectedOpenActionItem),
                  showActionItemDialog: _vm.showActionItemTabActionItemDialog,
                  selectedActionItem: _vm.selectedOpenActionItem,
                  selectedActionItemId:
                    _vm.selectedOpenActionItem.action_item_id,
                  hideResponseSection: ""
                },
                on: {
                  "action-item-dialog-close": function($event) {
                    _vm.showActionItemTabActionItemDialog = false
                  },
                  "action-item-saved": _vm.onActionItemSaved,
                  "action-item-response-saved": function($event) {
                    _vm.showActionItemTabActionItemDialog = false
                    _vm.loadActionItems()
                  },
                  "reject-action-item": function($event) {
                    _vm.showActionItemTabActionItemDialog = false
                    _vm.onActionItemSaved()
                  },
                  "accept-action-item": function($event) {
                    _vm.showActionItemTabActionItemDialog = false
                    _vm.loadActionItems()
                  }
                }
              })
            : _vm._e(),
          _vm.showActionItemTabEditActionItemDialog
            ? _c("ActionItemTabEditActionItemDialog", {
                attrs: {
                  showEditItemDialog: _vm.showActionItemTabEditActionItemDialog,
                  selectedActionItem: _vm.selectedOpenActionItem,
                  selectedMapServiceId: _vm.selectedMapServiceId,
                  gisInfoObjectId: _vm.objectId
                },
                on: {
                  "edit-action-item-dialog-close": function($event) {
                    _vm.showActionItemTabEditActionItemDialog = false
                    _vm.loadActionItems()
                  },
                  "action-item-saved": function($event) {
                    _vm.showActionItemTabEditActionItemDialog = false
                    _vm.loadActionItems()
                  }
                }
              })
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }