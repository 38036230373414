<template>
  <v-dialog :value="showUtilibotDialog" max-width="600px" persistent>
    <v-toolbar color="#3F51B5" dark>
      <v-toolbar-title style="width: 100%">
        <div class="d-flex justify-space-between">
          <div class="mt-2">Form Utilibots</div>
          <div v-if="!showDontCloseText">
            <v-btn icon @click="$emit('utilibot-dialog-close')">
              <v-icon>
                {{ mdiClose }}
              </v-icon>
            </v-btn>
          </div>
        </div>
      </v-toolbar-title>
    </v-toolbar>
    <v-card color="#fafafa" class="overflow-y-auto" max-height="75vh">
      <v-card-text class="pa-5">
        <div v-if="formResultTasks.length === 0">
          No UtilIBots configured for this form.
        </div>
        <v-expansion-panels
          v-else
          multiple
          accordion
          v-model="openPanels"
          class="elevation-0 ma-0 pa-0"
        >
          <v-expansion-panel v-for="t of displayFormResultTasks" :key="t.id">
            <v-expansion-panel-header>
              <div class="d-flex justify-space-between">
                <div class="d-flex align-center">
                  <b>{{ t.type_display_name }}</b>
                </div>

                <div class="d-flex align-center">
                  <v-btn
                    v-if="
                      ![
                        FORM_RESULT_TASK_STATUS.COMPLETED_SUCCESS,
                        FORM_RESULT_TASK_STATUS.PROCESSING,
                      ].includes(t.status_name) &&
                      ![
                        'update_esri_main_tables',
                        'update_related_tables',
                      ].includes(t.type_name)
                    "
                    text
                    color="red"
                    @click.stop="reprocessFailedMessages(t.form_result_task_id)"
                  >
                    Retry
                  </v-btn>
                  <v-icon
                    v-if="
                      processing[t.form_result_task_id] ||
                      [FORM_RESULT_TASK_STATUS.PROCESSING].includes(
                        t.status_name
                      )
                    "
                    color="#00A3FF"
                    class="mr-4"
                  >
                    {{ mdiAutorenew }}
                  </v-icon>
                  <template v-else>
                    <img
                      v-if="iconUrlMap[t.status_name]"
                      :src="require(`@/assets/${iconUrlMap[t.status_name]}`)"
                      style="width: 20px; height: 20px"
                      class="mr-4"
                    />
                  </template>
                </div>
              </div>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <section
                class="preserve-white-space"
                v-html="
                  (
                    t.status_message || 'No additional information available'
                  ).trim()
                "
              ></section>
              <template
                v-if="
                  ['update_esri_main_tables', 'update_related_tables'].includes(
                    t.type_name
                  )
                "
              >
                <v-btn
                  text
                  @click="viewRequest = !viewRequest"
                  class="px-0 mx-0"
                  color="#3F51B5"
                >
                  {{ viewRequest ? "Hide" : "View" }} Request
                </v-btn>
                <template v-if="viewRequest">
                  <section v-if="t.request_url" class="py-3">
                    <b>URL</b>
                  </section>
                  <section>
                    {{ t.request_url }}
                  </section>

                  <section v-if="t.request_payload" class="py-3">
                    <b>Payload</b>
                  </section>
                  <section
                    class="preserve-white-space"
                    v-if="t.request_payload"
                  >
                    {{ t.request_payload }}
                  </section>
                  <section v-if="t.request_response" class="py-3">
                    <b>Response</b>
                  </section>
                  <section
                    class="preserve-white-space"
                    v-html="t.request_response || 'No response recorded'"
                  ></section>
                </template>
              </template>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>

        <div
          v-if="showDontCloseText"
          class="py-2 d-flex justify-center text-body-1"
        >
          Don't close this dialog...
        </div>

        <FormIdDialog
          :showFormIdDialog="showFormIdDialog"
          :formResultId="formResultId"
          @form-id-dialog-close="showFormIdDialog = false"
        />
      </v-card-text>
      <v-card-actions class="d-flex justify-end mr-1">
        <v-btn icon @click="showFormIdDialog = true">
          <v-icon color="#3F51B5">
            {{ mdiInformation }}
          </v-icon>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mdiClose, mdiInformation, mdiAutorenew } from "@mdi/js";
import FormIdDialog from "@/components/tickets/ticket-edit-form/dynamic-form/dynamic-form-edit-actions/FormIdDialog";
import { axiosWithRegularAuth } from "@/plugins/axios";
import sleep from "@/mixins/sleep";
import esriTablesUtilibotsMixin from "@/mixins/esriTablesUtilibotsMixin";
import FORM_RESULT_TASK_STATUS from "@/constants/formResultTaskStatus";
import { db } from "@/mixins/utilisync-db";

const APIURL = process.env.VUE_APP_API_URL;
const iconUrlMap = {
  [FORM_RESULT_TASK_STATUS.COMPLETED_SUCCESS]: "check_green.svg",
  [FORM_RESULT_TASK_STATUS.DEAD_LETTER_QUEUE]: "failed_red.svg",
  [FORM_RESULT_TASK_STATUS.COMPLETED_WITH_ERRORS]:
    "completed_with_errors_yellow.svg",
  [FORM_RESULT_TASK_STATUS.COMPLETED_ERROR]: "completed_with_errors_yellow.svg",
  [FORM_RESULT_TASK_STATUS.ERROR]: "failed_red.svg",
};

export default {
  name: "UtilibotDialog",
  props: {
    showUtilibotDialog: Boolean,
    formResultId: String,
    openedAfterFormSubmission: Boolean,
  },
  components: {
    FormIdDialog,
  },
  computed: {
    displayFormResultTasks() {
      if (
        this.showAfterFormSubmissionUtilibotDialog &&
        this.openedAfterFormSubmission
      ) {
        return this.namesOformResultTasksToDisplay
          .map((n) => {
            return this.formResultTasks.find((f) => f.type_name === n);
          })
          .filter(Boolean);
      } else {
        return this.formResultTasks;
      }
    },
  },
  data() {
    return {
      mdiClose,
      formResultTasks: {},
      openPanels: [],
      iconUrlMap,
      showFormIdDialog: false,
      mdiInformation,
      mdiAutorenew,
      processing: {},
      FORM_RESULT_TASK_STATUS,
      viewRequest: false,
      showAfterFormSubmissionUtilibotDialog: false,
      namesOformResultTasksToDisplay: [],
      showDontCloseText: false,
    };
  },
  mixins: [esriTablesUtilibotsMixin],
  methods: {
    async reprocessFailedMessages(formResultTaskId) {
      const formResultTask = this.formResultTasks.find(
        (ft) => ft.form_result_task_id === formResultTaskId
      );
      if (formResultTask?.type_name === "update_esri_main_tables") {
        await this.updateFeatureService();
      } else if (formResultTask?.type_name === "update_related_tables") {
        await this.addToRelatedTableFromFormResult();
      } else {
        await axiosWithRegularAuth.get(
          `${APIURL}/reprocess_failed_messages/${formResultTaskId}`
        );
        this.$set(this.processing, formResultTaskId, true);
        await sleep(2000);
        this.$set(this.processing, formResultTaskId, false);
      }
      await this.getFormResultTasks();
    },
    async getFormResultTasks() {
      if (!this.formResultId) {
        return;
      }
      const {
        data: { results },
      } = await axiosWithRegularAuth.get(
        `${APIURL}/form_result_tasks/${this.formResultId}`
      );
      this.formResultTasks = results;
    },
    async getFormDefinition() {
      const { formResultId } = this;
      const { data: formResult } = await axiosWithRegularAuth.get(
        `${APIURL}/form_results/${formResultId}`
      );
      const { form_definition_id: formDefinitionId } = formResult;
      const [formDefinition] = await db.formDefinitions
        .filter((f) => f.form_definition_id === formDefinitionId)
        .toArray();
      const showUtiliBotsOnFormSubmission = Boolean(
        formDefinition?.form?.formDescription?.showUtiliBotsOnFormSubmission
      );
      this.showAfterFormSubmissionUtilibotDialog =
        showUtiliBotsOnFormSubmission;
    },
    async addFormResultTasksInOrder() {
      if (
        !this.showAfterFormSubmissionUtilibotDialog ||
        !this.openedAfterFormSubmission
      ) {
        return;
      }
      this.showDontCloseText = true;
      this.namesOformResultTasksToDisplay.push("update_esri_main_tables");
      await sleep(2000);
      this.namesOformResultTasksToDisplay.push("update_related_tables");
      await sleep(2000);
      this.namesOformResultTasksToDisplay.push("create_form_pdf");
      this.showDontCloseText = false;
      await sleep(2000);
      const namesOfRemainingFomrResultTasks = this.formResultTasks
        .filter((f) => {
          return ![
            "update_esri_main_tables",
            "update_related_tables",
            "create_form_pdf",
          ].includes(f.type_name);
        })
        .map((f) => f.type_name);
      this.namesOformResultTasksToDisplay.push(
        ...namesOfRemainingFomrResultTasks
      );
    },
  },
  beforeMount() {
    this.getFormResultTasks();
  },

  watch: {
    formResultId: {
      immediate: true,
      async handler() {
        await this.getFormDefinition();
        await this.getFormResultTasks();
        await this.addFormResultTasksInOrder();
      },
    },
  },
};
</script>

<style scoped>
.preserve-white-space {
  white-space: pre-wrap;
}
</style>
