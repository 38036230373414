export const getGisInfoObjectId = (selectedGisInfo) => {
  const field = selectedGisInfo?.layer?.fields?.find((f) => f.type === "oid");
  return (
    selectedGisInfo?.attributes?.[field?.name] ??
    selectedGisInfo?.attributes?.OBJECTID
  );
};

export const getGisInfoGlobalId = (selectedGisInfo) => {
  const field = selectedGisInfo?.layer?.fields?.find(
    (f) => f.type === "global-id"
  );
  const { globalIdFieldName, features = [] } = selectedGisInfo ?? {};
  const [feature] = features;
  return (
    selectedGisInfo?.attributes?.[field?.name] ??
    selectedGisInfo?.attributes?.gisDataPointId ??
    feature?.attributes?.[globalIdFieldName]
  );
};
