import { render, staticRenderFns } from "./UtilibotDialog.vue?vue&type=template&id=36f768ee&scoped=true&"
import script from "./UtilibotDialog.vue?vue&type=script&lang=js&"
export * from "./UtilibotDialog.vue?vue&type=script&lang=js&"
import style0 from "./UtilibotDialog.vue?vue&type=style&index=0&id=36f768ee&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "36f768ee",
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VExpansionPanel } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelContent } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelHeader } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanels } from 'vuetify/lib/components/VExpansionPanel';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';
installComponents(component, {VBtn,VCard,VCardActions,VCardText,VDialog,VExpansionPanel,VExpansionPanelContent,VExpansionPanelHeader,VExpansionPanels,VIcon,VToolbar,VToolbarTitle})


/* hot reload */
if (module.hot) {
  var api = require("/opt/build/repo/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('36f768ee')) {
      api.createRecord('36f768ee', component.options)
    } else {
      api.reload('36f768ee', component.options)
    }
    module.hot.accept("./UtilibotDialog.vue?vue&type=template&id=36f768ee&scoped=true&", function () {
      api.rerender('36f768ee', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/tickets/ticket-edit-form/dynamic-form/dynamic-form-edit-actions/UtilibotDialog.vue"
export default component.exports