<template>
  <v-card width="100%" elevation="0">
    <v-card-text>
      <v-row>
        <v-col cols="12" class="d-flex align-center justify-space-between">
          <div>
            {{ item.question.label }}
          </div>
          <div>
            <v-btn
              text
              color="#3F51B5"
              @click.stop="showActionItemDialog = true"
              :disabled="isDisabled"
              id="addActionItemBtn"
            >
              + Add
            </v-btn>
          </div>
        </v-col>

        <v-col cols="12" class="pa-0 ma-0">
          <v-card v-if="noActionItems">
            <v-card-text class="d-flex justify-center">
              No {{ item.question.label }}
            </v-card-text>
          </v-card>
          <v-card v-else>
            <v-card-text class="pa-0 ma-0">
              <v-data-table
                :headers="headers"
                hide-default-footer
                disable-pagination
                group-by="groupPosition"
                :items="mappedActionItems"
                item-key="action_item_id"
                class="pa-0 ma-0 cursor-pointer"
                @click:row="onRowClick"
              >
                <template v-slot:[`group.header`]="{ isOpen, toggle, items }">
                  <td
                    colspan="8"
                    class="ma-0 pa-0 group-toggle group-header"
                    @click="toggle"
                  >
                    <div class="d-flex ml-2">
                      <v-btn text x-small>
                        <v-icon>
                          {{ isOpen ? mdiChevronUp : mdiChevronDown }}
                        </v-icon>
                      </v-btn>
                      <div class="ml-2 text-capitalize">
                        <b>{{ getGroupHeader(items) }}</b>
                      </div>
                    </div>
                  </td>
                </template>

                <template v-slot:[`item.due_by`]="{ item }">
                  {{ item.due_by | date }}
                </template>

                <template v-slot:[`item.action_item_id`]="{ item }">
                  <v-btn
                    icon
                    color="#3F51B5"
                    @click.stop="onActionItemClick(item)"
                  >
                    <v-icon v-if="isDisabled">
                      {{ mdiEye }}
                    </v-icon>
                    <v-icon v-else>
                      {{ mdiPencil }}
                    </v-icon>
                  </v-btn>
                </template>
              </v-data-table>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>

      <ActionItemDialog
        v-if="showActionItemDialog"
        :showActionItemDialog="showActionItemDialog"
        :item="item"
        :readOnly="isDisabled"
        :isEditingFinalForm="isEditingFinalForm"
        :alreadySubmittedFinalOnline="alreadySubmittedFinalOnline"
        :formResultId="formResultId"
        :formDefinition="formDefinition"
        :newActionItems="actionItems.newActionItems"
        :selectedMapServiceId="selectedMapServiceId"
        :gisInfoObjectId="objectId"
        @form-saved="onFormSaved"
        @close-form="showActionItemDialog = false"
      />

      <EditActionItemDialog
        v-if="showEditActionItemDialog"
        :formDefinition="formDefinition"
        :showEditActionItemDialog="showEditActionItemDialog"
        :readOnly="isDisabled"
        :alreadySubmittedFinalOnline="alreadySubmittedFinalOnline"
        :isEditingFinalForm="isEditingFinalForm"
        :item="item"
        :selectedActionItem="selectedActionItem"
        :selectedMapServiceId="selectedMapServiceId"
        :gisInfoObjectId="objectId"
        :formResultId="formResultId"
        @form-saved="onEditFormSaved"
        @close-form="showEditActionItemDialog = false"
        @delete-action-item="deleteActionItem"
      />

      <ActionItemTabActionItemDialog
        v-if="showActionItemTabActionItemDialog"
        :action="getActionItemLabel(selectedOpenActionItem)"
        :showActionItemDialog="showActionItemTabActionItemDialog"
        :selectedActionItem="selectedOpenActionItem"
        :selectedActionItemId="selectedOpenActionItem.action_item_id"
        hideResponseSection
        @action-item-dialog-close="showActionItemTabActionItemDialog = false"
        @action-item-saved="onActionItemSaved"
        @action-item-response-saved="
          showActionItemTabActionItemDialog = false;
          loadActionItems();
        "
        @reject-action-item="
          showActionItemTabActionItemDialog = false;
          onActionItemSaved();
        "
        @accept-action-item="
          showActionItemTabActionItemDialog = false;
          loadActionItems();
        "
      />

      <ActionItemTabEditActionItemDialog
        v-if="showActionItemTabEditActionItemDialog"
        :showEditItemDialog="showActionItemTabEditActionItemDialog"
        :selectedActionItem="selectedOpenActionItem"
        :selectedMapServiceId="selectedMapServiceId"
        :gisInfoObjectId="objectId"
        @edit-action-item-dialog-close="
          showActionItemTabEditActionItemDialog = false;
          loadActionItems();
        "
        @action-item-saved="
          showActionItemTabEditActionItemDialog = false;
          loadActionItems();
        "
      />
    </v-card-text>
  </v-card>
</template>

<script>
import { mdiPencil, mdiChevronUp, mdiChevronDown, mdiEye } from "@mdi/js";
import ActionItemDialog from "@/components/tickets/shared/action-item/ActionItemDialog";
import EditActionItemDialog from "@/components/tickets/shared/action-item/EditActionItemDialog";
import ActionItemTabEditActionItemDialog from "@/components/mapView/action-items-tab/action-item-dialog/EditActionItemDialog";
import ActionItemTabActionItemDialog from "@/components/mapView/action-items-tab/ActionItemTabActionItemDialog";
import { axiosWithRegularAuth } from "@/plugins/axios";
import { cloneDeep } from "lodash";
import ACTION_ITEM_STATUSES from "@/constants/actionItemStatuses";
import { v4 as uuidv4 } from "uuid";
import { getGisInfoObjectId } from "@/mixins/getId";
import { db } from "@/mixins/utilisync-db";
import actionItemMixin from "@/mixins/actionItemMixin";

const APIURL = process.env.VUE_APP_API_URL;
const GROUP_POSITIONS = {
  [ACTION_ITEM_STATUSES.OPEN]: 0,
  [ACTION_ITEM_STATUSES.READY_FOR_REVIEW]: 1,
  [ACTION_ITEM_STATUSES.REJECTED]: 2,
  [ACTION_ITEM_STATUSES.CANCELED]: 4,
  [ACTION_ITEM_STATUSES.CLOSED]: 4,
  [ACTION_ITEM_STATUSES.NEW]: 5,
};
const headers = [
  {
    text: "Due By",
    align: "start",
    value: "due_by",
  },
  {
    text: "Assigned To",
    align: "start",
    value: "assignee",
    width: "120px",
  },
  {
    text: "Description",
    align: "start",
    value: "action_item_description",
  },
  {
    text: "",
    align: "end",
    value: "action_item_id",
  },
];

export default {
  name: "ActionItem",
  props: {
    item: Object,
    formDefinition: Object,
    value: Object,
    canEdit: Boolean,
    formResultId: String,
    alreadySubmittedFinalOnline: Boolean,
    isEditingFinalForm: Boolean,
    gisInfos: Array,
    selectedGisInfo: Object,
    selectedMapServiceId: String,
    objectId: Number,
  },
  components: {
    ActionItemDialog,
    EditActionItemDialog,
    ActionItemTabActionItemDialog,
    ActionItemTabEditActionItemDialog,
  },
  mixins: [actionItemMixin],
  async beforeMount() {
    if (this.value) {
      this.actionItems = { ...this.value };
    } else {
      this.$emit("input", cloneDeep(this.actionItems));
    }
    await this.getAssignableUsers();
    await this.getActionItemsByFeature();
    await this.getSiteContacts();
  },
  methods: {
    onFormSaved(actionItem) {
      if (!Array.isArray(this.actionItems.newActionItems)) {
        this.actionItems.newActionItems = [];
      }
      this.actionItems.newActionItems.push(actionItem);
      this.showActionItemDialog = false;
      this.$emit("input", cloneDeep(this.actionItems));
      this.$emit("changes-made");
    },
    onEditFormSaved(actionItem) {
      const selectedActionItemIndex = this.actionItems.newActionItems.findIndex(
        (a) => a.id === actionItem.id
      );
      this.$set(this.actionItems.newActionItems, selectedActionItemIndex, {
        ...actionItem,
      });
      this.selectedActionItem = {};
      this.showEditActionItemDialog = false;
      this.$emit("input", cloneDeep(this.actionItems));
      this.$emit("changes-made");
    },
    deleteActionItem(actionItem) {
      const selectedActionItemIndex = this.actionItems.newActionItems.findIndex(
        (a) => a.id === actionItem.id
      );
      this.actionItems.newActionItems.splice(selectedActionItemIndex, 1);
      this.selectedActionItem = {};
      this.showEditActionItemDialog = false;
      this.$emit("input", cloneDeep(this.actionItems));
    },
    async getAssignableUsers() {
      this.users = await db.users.toCollection().toArray();
    },
    async getSiteContacts() {
      const { selectedMapServiceId, objectId } = this;
      if (!navigator.onLine || !selectedMapServiceId || !objectId) {
        return;
      }
      const {
        data: { results },
      } = await axiosWithRegularAuth.get(`${APIURL}/shared_site_contacts`, {
        params: {
          map_service_id: selectedMapServiceId,
          feature_id: objectId,
        },
      });
      this.sharedSiteContacts = results;
    },
    getAssigneeValue(actionItem) {
      const orgUser = this.users
        .map((u) => {
          const { f_name: fName, l_name: lName, user_id: userId } = u;
          return {
            name: `${fName} ${lName}`,
            userId,
          };
        })
        .find((u) => u.userId === actionItem?.assignee_user_id);
      const sharedSiteContact = this.sharedSiteContacts.find(
        (c) => c.user_id === actionItem?.assignee_user_id
      );
      const assigneeIsOrgUser = Boolean(orgUser);
      const assigneeIsSiteContact = Boolean(sharedSiteContact);
      if (assigneeIsOrgUser) {
        return orgUser?.name;
      } else if (assigneeIsSiteContact) {
        return sharedSiteContact?.name;
      }
    },
    async getActionItemsByFeature() {
      const { selectedMapServiceId, objectId, item } = this;
      const autoLoadActionItems = item?.question?.autoLoadActionItems;
      if (
        !selectedMapServiceId ||
        !objectId ||
        !autoLoadActionItems ||
        !navigator.onLine
      ) {
        return;
      }
      const {
        data: { results },
      } = await axiosWithRegularAuth.get(`${APIURL}/action_item/by_feature`, {
        params: {
          map_service_id: selectedMapServiceId,
          object_id: objectId,
        },
      });

      this.actionItems.openActionItems = results;
      this.$emit("input", cloneDeep(this.actionItems));
    },
    async onActionItemSaved() {
      await this.loadActionItems();
      this.selectedActionItem = this.actionItems[this.selectedActionItemIndex];
    },
    async loadActionItems() {
      if (navigator.onLine) {
        await this.getActionItemsByFeature();
      }
    },
    onRowClick(item) {
      if (item.status !== ACTION_ITEM_STATUSES.NEW) {
        this.selectedActionItem = item;
        this.showActionItemTabActionItemDialog = true;
      } else {
        this.selectedActionItem = item;
        this.showEditActionItemDialog = true;
      }
    },
    onActionItemClick(item) {
      if (item.status === ACTION_ITEM_STATUSES.NEW) {
        this.selectedActionItem = item;
        this.showEditActionItemDialog = true;
      } else {
        this.selectedOpenActionItem = item;
        this.showActionItemTabEditActionItemDialog = true;
      }
    },
    getGroupHeader(items) {
      const [item] = items;
      return item?.displayStatus;
    },
  },
  data() {
    return {
      mdiPencil,
      mdiChevronUp,
      mdiChevronDown,
      mdiEye,
      showActionItemDialog: false,
      showEditActionItemDialog: false,
      actionItems: {
        newActionItems: [],
        openActionItems: [],
      },
      selectedActionItem: {},
      selectedActionItemIndex: undefined,
      users: [],
      showActionItemTabActionItemDialog: false,
      selectedOpenActionItem: {},
      selectedOpenActionItemIndex: undefined,
      ACTION_ITEM_STATUSES,
      headers,
      showActionItemTabEditActionItemDialog: false,
      sharedSiteContacts: [],
    };
  },
  computed: {
    gisInfoObjectId() {
      return getGisInfoObjectId(this.selectedGisInfo);
    },
    noActionItems() {
      return (
        (this.actionItems?.newActionItems?.length ?? 0) === 0 &&
        (this.actionItems?.openActionItems?.length ?? 0) === 0
      );
    },
    isDisabled() {
      if (this.alreadySubmittedFinalOnline) {
        return !this.canEdit || !this.isEditingFinalForm;
      }
      return !this.canEdit;
    },
    mappedActionItems() {
      const openItems =
        this.actionItems.openActionItems?.map((it) => {
          const { status } = it;
          return {
            ...it,
            assignee: this.getAssigneeValue(it),
            displayStatus:
              status === ACTION_ITEM_STATUSES.OPEN
                ? "Ready for Response"
                : status,
            groupPosition: GROUP_POSITIONS[status],
          };
        }) ?? [];

      const newItems =
        this.actionItems.newActionItems?.map((it) => {
          return {
            ...it,
            assignee: this.getAssigneeValue(it),
            status: ACTION_ITEM_STATUSES.NEW,
            displayStatus: ACTION_ITEM_STATUSES.NEW,
            action_item_id: uuidv4(),
            groupPosition: GROUP_POSITIONS[ACTION_ITEM_STATUSES.NEW],
          };
        }) ?? [];
      return [...openItems, ...newItems];
    },
  },
};
</script>
