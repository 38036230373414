var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: {
        value: _vm.showActionItemDialog,
        "max-width": "500px",
        persistent: ""
      }
    },
    [
      _c(
        "v-card",
        { staticStyle: { "background-color": "#fafafa" } },
        [
          _c(
            "v-toolbar",
            {
              staticClass: "elevation-0",
              attrs: { dark: "", width: "100%", color: "#3F51B5" }
            },
            [
              _c("div", [_vm._v(_vm._s(_vm.getAction()) + " Action Item")]),
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  attrs: { icon: "" },
                  on: {
                    click: function($event) {
                      return _vm.$emit("action-item-dialog-close")
                    }
                  }
                },
                [_c("v-icon", [_vm._v(_vm._s(_vm.mdiClose))])],
                1
              )
            ],
            1
          ),
          _c(
            "v-card-text",
            {
              staticClass: "overflow-y-auto",
              staticStyle: { "background-color": "#fafafa", height: "60vh" }
            },
            [
              _c(
                "div",
                {
                  staticClass: "d-flex justify-space-between align-center pt-3",
                  staticStyle: { width: "100%" }
                },
                [
                  _c(
                    "div",
                    { staticClass: "caption d-flex align-center gap my-0" },
                    [
                      _vm._v(" Action Item "),
                      _vm.canEdit
                        ? _c(
                            "v-icon",
                            {
                              staticClass: "cursor-pointer",
                              attrs: { small: "", color: "#3F51B5" },
                              on: {
                                click: function($event) {
                                  _vm.showEditItemDialog = true
                                }
                              }
                            },
                            [_vm._v(" " + _vm._s(_vm.mdiPencil) + " ")]
                          )
                        : _vm._e()
                    ],
                    1
                  ),
                  _vm.actionItem.last_updated
                    ? _c("div", { staticClass: "caption" }, [
                        _vm._v(
                          " Last Updated: " +
                            _vm._s(
                              _vm._f("formatFullDate")(
                                _vm.actionItem.last_updated
                              )
                            ) +
                            " "
                        )
                      ])
                    : _vm._e()
                ]
              ),
              _c(
                "v-card",
                { attrs: { id: "actionItemInfo" } },
                [
                  _c(
                    "v-card-text",
                    [
                      _c(
                        "v-row",
                        [
                          _c("v-col", { attrs: { cols: "12" } }, [
                            _c("div", [
                              _c("p", { staticClass: "caption my-0 py-0" }, [
                                _vm._v("Status")
                              ]),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "body-1 black--text font-weight-normal"
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm._f("actionItemStatus")(
                                          _vm.actionItem.status
                                        )
                                      ) +
                                      " "
                                  )
                                ]
                              )
                            ])
                          ]),
                          _c("v-col", { attrs: { cols: "12" } }, [
                            _c("p", { staticClass: "caption my-0 py-0" }, [
                              _vm._v("Created")
                            ]),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "body-1 black--text font-weight-normal"
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm._f("date")(_vm.actionItem.created_on)
                                    ) +
                                    " "
                                )
                              ]
                            )
                          ]),
                          _c(
                            "v-col",
                            { staticClass: "my-0 py-0", attrs: { cols: "12" } },
                            [
                              _c("p", { staticClass: "caption my-0 py-0" }, [
                                _vm._v("Due By")
                              ]),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "body-1 black--text font-weight-normal"
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm._f("date")(_vm.actionItem.due_by)
                                      ) +
                                      " "
                                  )
                                ]
                              )
                            ]
                          ),
                          _c(
                            "v-col",
                            { staticClass: "my-0 py-3", attrs: { cols: "12" } },
                            [
                              _c("p", { staticClass: "caption my-0 py-0" }, [
                                _vm._v("Assigned To")
                              ]),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "body-1 black--text font-weight-normal"
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.assigneeEmail || "Unassigned"
                                      ) +
                                      " "
                                  )
                                ]
                              )
                            ]
                          ),
                          _c(
                            "v-col",
                            { staticClass: "my-0 py-0", attrs: { cols: "12" } },
                            [
                              _c("p", { staticClass: "caption my-0 py-0" }, [
                                _vm._v("Description")
                              ]),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "body-1 black--text font-weight-normal"
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.actionItem.action_item_description
                                      ) +
                                      " "
                                  )
                                ]
                              )
                            ]
                          ),
                          _c(
                            "v-col",
                            {
                              staticClass: "my-0 pb-3 px-3",
                              attrs: { cols: "12" }
                            },
                            [
                              _c("p", { staticClass: "caption" }, [
                                _vm._v("Photos")
                              ]),
                              _vm.assignerPhotos.length > 0
                                ? _c(
                                    "div",
                                    { staticClass: "d-flex flex-wrap gap" },
                                    _vm._l(_vm.assignerPhotos, function(p) {
                                      return _c("img", {
                                        key: p.file_id,
                                        staticClass: "image",
                                        class: {
                                          "cursor-pointer":
                                            _vm.actionItemDetails.status !==
                                            _vm.ACTION_ITEM_STATUSES
                                              .READY_FOR_REVIEW
                                        },
                                        style: {
                                          "pointer-events":
                                            _vm.actionItemDetails.status ===
                                            _vm.ACTION_ITEM_STATUSES
                                              .READY_FOR_REVIEW
                                              ? "none"
                                              : undefined
                                        },
                                        attrs: { src: p.s3_file_path },
                                        on: {
                                          click: function($event) {
                                            return _vm.onClick(
                                              p.file_id,
                                              _vm.actionItem
                                            )
                                          }
                                        }
                                      })
                                    }),
                                    0
                                  )
                                : _c(
                                    "div",
                                    {
                                      staticClass:
                                        "d-flex justify-center body-1 font-weight-normal"
                                    },
                                    [_vm._v(" No Photos Uploaded ")]
                                  )
                            ]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _vm.selectedActionItem.status ===
              _vm.ACTION_ITEM_STATUSES.REJECTED
                ? _c(
                    "p",
                    { staticClass: "caption d-flex gap my-0 py-0 pt-3" },
                    [_vm._v(" Reason Action Item Was Not Accepted ")]
                  )
                : _vm._e(),
              _vm.selectedActionItem.status ===
              _vm.ACTION_ITEM_STATUSES.REJECTED
                ? _c("v-alert", { attrs: { color: "#D10000", dark: "" } }, [
                    _vm._v(" " + _vm._s(_vm.actionItem.rejection_text) + " ")
                  ])
                : _vm._e(),
              !_vm.hideResponseSection
                ? [
                    _c(
                      "p",
                      { staticClass: "caption d-flex gap my-0 py-0 pt-3" },
                      [_vm._v("Response")]
                    ),
                    _vm.selectedActionItem.status ===
                      _vm.ACTION_ITEM_STATUSES.OPEN ||
                    _vm.selectedActionItem.status ===
                      _vm.ACTION_ITEM_STATUSES.REJECTED
                      ? _c(
                          "v-card",
                          { attrs: { id: "actionItemResponse" } },
                          [
                            _c("v-card-text", [
                              _c(
                                "form",
                                [
                                  _c(
                                    "validation-observer",
                                    { ref: "responseForm" },
                                    [
                                      _c(
                                        "v-row",
                                        [
                                          _c(
                                            "v-col",
                                            { attrs: { cols: "12" } },
                                            [
                                              _c("validation-provider", {
                                                attrs: { name: "Response" },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "default",
                                                      fn: function(ref) {
                                                        var errors = ref.errors
                                                        var valid = ref.valid
                                                        return [
                                                          _c("v-text-field", {
                                                            ref:
                                                              "responseField",
                                                            attrs: {
                                                              "error-messages": errors,
                                                              success: valid,
                                                              color: "#3F51B5",
                                                              label: "Response"
                                                            },
                                                            model: {
                                                              value:
                                                                _vm.response
                                                                  .response,
                                                              callback: function(
                                                                $$v
                                                              ) {
                                                                _vm.$set(
                                                                  _vm.response,
                                                                  "response",
                                                                  $$v
                                                                )
                                                              },
                                                              expression:
                                                                "response.response"
                                                            }
                                                          })
                                                        ]
                                                      }
                                                    }
                                                  ],
                                                  null,
                                                  false,
                                                  2572200149
                                                )
                                              })
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-col",
                                            { attrs: { cols: "12" } },
                                            [
                                              _c(
                                                "p",
                                                { staticClass: "caption" },
                                                [_vm._v("Photos")]
                                              ),
                                              _c("file-pond", {
                                                ref: "files",
                                                attrs: {
                                                  name: "files",
                                                  "label-idle":
                                                    "Tap or drop photos here...",
                                                  "allow-multiple": "",
                                                  "accepted-file-types":
                                                    "image/jpeg, image/png",
                                                  files: _vm.uploadFiles,
                                                  allowFileSizeValidation: "",
                                                  maxFileSize: "2MB"
                                                },
                                                on: {
                                                  addfile:
                                                    _vm.onAssigneePhotoInputChange
                                                }
                                              }),
                                              _vm.assigneePhotos.length > 0
                                                ? _c(
                                                    "div",
                                                    _vm._l(
                                                      _vm.assigneePhotos,
                                                      function(p, index) {
                                                        return _c(
                                                          "v-card",
                                                          { key: p.file_id },
                                                          [
                                                            _c(
                                                              "v-card-text",
                                                              {
                                                                staticClass:
                                                                  "justify-space-between align-center d-flex"
                                                              },
                                                              [
                                                                _c("img", {
                                                                  staticClass:
                                                                    "cursor-pointer image",
                                                                  attrs: {
                                                                    src:
                                                                      p.s3_file_path
                                                                  },
                                                                  on: {
                                                                    click: function(
                                                                      $event
                                                                    ) {
                                                                      return _vm.markup(
                                                                        p.file_id
                                                                      )
                                                                    }
                                                                  }
                                                                }),
                                                                _c(
                                                                  "v-menu",
                                                                  {
                                                                    scopedSlots: _vm._u(
                                                                      [
                                                                        {
                                                                          key:
                                                                            "activator",
                                                                          fn: function(
                                                                            ref
                                                                          ) {
                                                                            var on =
                                                                              ref.on
                                                                            var attrs =
                                                                              ref.attrs
                                                                            return [
                                                                              _c(
                                                                                "v-btn",
                                                                                _vm._g(
                                                                                  _vm._b(
                                                                                    {
                                                                                      attrs: {
                                                                                        icon:
                                                                                          ""
                                                                                      }
                                                                                    },
                                                                                    "v-btn",
                                                                                    attrs,
                                                                                    false
                                                                                  ),
                                                                                  on
                                                                                ),
                                                                                [
                                                                                  _c(
                                                                                    "v-icon",
                                                                                    [
                                                                                      _vm._v(
                                                                                        _vm._s(
                                                                                          _vm.mdiDotsVertical
                                                                                        )
                                                                                      )
                                                                                    ]
                                                                                  )
                                                                                ],
                                                                                1
                                                                              )
                                                                            ]
                                                                          }
                                                                        }
                                                                      ],
                                                                      null,
                                                                      true
                                                                    )
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "v-list",
                                                                      [
                                                                        _c(
                                                                          "v-list-item",
                                                                          {
                                                                            on: {
                                                                              click: function(
                                                                                $event
                                                                              ) {
                                                                                $event.stopPropagation()
                                                                                return _vm.markup(
                                                                                  p.file_id
                                                                                )
                                                                              }
                                                                            }
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "v-list-item-title",
                                                                              [
                                                                                _c(
                                                                                  "v-icon",
                                                                                  [
                                                                                    _vm._v(
                                                                                      _vm._s(
                                                                                        _vm.mdiPencil
                                                                                      )
                                                                                    )
                                                                                  ]
                                                                                ),
                                                                                _vm._v(
                                                                                  " Markup "
                                                                                )
                                                                              ],
                                                                              1
                                                                            )
                                                                          ],
                                                                          1
                                                                        ),
                                                                        _c(
                                                                          "v-list-item",
                                                                          {
                                                                            on: {
                                                                              click: function(
                                                                                $event
                                                                              ) {
                                                                                $event.stopPropagation()
                                                                                return _vm.removePhoto(
                                                                                  index,
                                                                                  p.file_id
                                                                                )
                                                                              }
                                                                            }
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "v-list-item-title",
                                                                              [
                                                                                _c(
                                                                                  "v-icon",
                                                                                  [
                                                                                    _vm._v(
                                                                                      _vm._s(
                                                                                        _vm.mdiDelete
                                                                                      )
                                                                                    )
                                                                                  ]
                                                                                ),
                                                                                _vm._v(
                                                                                  " Delete "
                                                                                )
                                                                              ],
                                                                              1
                                                                            )
                                                                          ],
                                                                          1
                                                                        )
                                                                      ],
                                                                      1
                                                                    )
                                                                  ],
                                                                  1
                                                                )
                                                              ],
                                                              1
                                                            )
                                                          ],
                                                          1
                                                        )
                                                      }
                                                    ),
                                                    1
                                                  )
                                                : _vm._e()
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ])
                          ],
                          1
                        )
                      : _c(
                          "v-card",
                          [
                            _c(
                              "v-card-text",
                              [
                                _c(
                                  "v-row",
                                  [
                                    _c("v-col", { attrs: { cols: "12" } }, [
                                      _c(
                                        "p",
                                        { staticClass: "caption py-0 my-0" },
                                        [_vm._v("Responded")]
                                      ),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "body-1 black--text font-weight-normal"
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm._f("formatDateTime")(
                                                  _vm.actionItemDetails
                                                    .last_response
                                                )
                                              ) +
                                              " "
                                          )
                                        ]
                                      )
                                    ]),
                                    _c("v-col", { attrs: { cols: "12" } }, [
                                      _c(
                                        "p",
                                        { staticClass: "caption py-0 my-0" },
                                        [_vm._v("Response From")]
                                      ),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "body-1 black--text font-weight-normal"
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.actionItemDetails
                                                  .last_response_user_name
                                              ) +
                                              ", " +
                                              _vm._s(
                                                _vm.actionItemDetails
                                                  .last_response_user_group_name
                                              ) +
                                              " "
                                          )
                                        ]
                                      )
                                    ]),
                                    _c("v-col", { attrs: { cols: "12" } }, [
                                      _c(
                                        "p",
                                        { staticClass: "caption my-0 py-0" },
                                        [_vm._v("Response")]
                                      ),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "body-1 black--text font-weight-normal"
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.actionItemDetails.response
                                              ) +
                                              " "
                                          )
                                        ]
                                      )
                                    ]),
                                    _c("v-col", { attrs: { cols: "12" } }, [
                                      _c(
                                        "p",
                                        { staticClass: "caption my-0 py-0" },
                                        [_vm._v("Photos")]
                                      ),
                                      _vm.assigneePhotos.length > 0
                                        ? _c(
                                            "div",
                                            [
                                              _vm.actionItemDetails.status ===
                                              _vm.ACTION_ITEM_STATUSES
                                                .READY_FOR_REVIEW
                                                ? _c(
                                                    "section",
                                                    {
                                                      staticClass:
                                                        "d-flex gap flex-wrap flex-start"
                                                    },
                                                    _vm._l(
                                                      _vm.assigneePhotos,
                                                      function(p) {
                                                        return _c("img", {
                                                          key: p.file_id,
                                                          staticClass: "image",
                                                          attrs: {
                                                            src: p.s3_file_path
                                                          },
                                                          on: {
                                                            click: function(
                                                              $event
                                                            ) {
                                                              return _vm.markup(
                                                                p.file_id
                                                              )
                                                            }
                                                          }
                                                        })
                                                      }
                                                    ),
                                                    0
                                                  )
                                                : _vm._l(
                                                    _vm.assigneePhotos,
                                                    function(p, index) {
                                                      return _c(
                                                        "v-card",
                                                        { key: p.file_id },
                                                        [
                                                          _c(
                                                            "v-card-text",
                                                            {
                                                              staticClass:
                                                                "justify-space-between align-center d-flex"
                                                            },
                                                            [
                                                              _c("img", {
                                                                staticClass:
                                                                  "cursor-pointer image",
                                                                attrs: {
                                                                  src:
                                                                    p.s3_file_path
                                                                },
                                                                on: {
                                                                  click: function(
                                                                    $event
                                                                  ) {
                                                                    return _vm.markup(
                                                                      p.file_id
                                                                    )
                                                                  }
                                                                }
                                                              }),
                                                              _c(
                                                                "v-menu",
                                                                {
                                                                  scopedSlots: _vm._u(
                                                                    [
                                                                      {
                                                                        key:
                                                                          "activator",
                                                                        fn: function(
                                                                          ref
                                                                        ) {
                                                                          var on =
                                                                            ref.on
                                                                          var attrs =
                                                                            ref.attrs
                                                                          return [
                                                                            _c(
                                                                              "v-btn",
                                                                              _vm._g(
                                                                                _vm._b(
                                                                                  {
                                                                                    attrs: {
                                                                                      icon:
                                                                                        ""
                                                                                    }
                                                                                  },
                                                                                  "v-btn",
                                                                                  attrs,
                                                                                  false
                                                                                ),
                                                                                on
                                                                              ),
                                                                              [
                                                                                _c(
                                                                                  "v-icon",
                                                                                  [
                                                                                    _vm._v(
                                                                                      _vm._s(
                                                                                        _vm.mdiDotsVertical
                                                                                      )
                                                                                    )
                                                                                  ]
                                                                                )
                                                                              ],
                                                                              1
                                                                            )
                                                                          ]
                                                                        }
                                                                      }
                                                                    ],
                                                                    null,
                                                                    true
                                                                  )
                                                                },
                                                                [
                                                                  _c(
                                                                    "v-list",
                                                                    [
                                                                      _c(
                                                                        "v-list-item",
                                                                        {
                                                                          on: {
                                                                            click: function(
                                                                              $event
                                                                            ) {
                                                                              $event.stopPropagation()
                                                                              return _vm.markup(
                                                                                p.file_id
                                                                              )
                                                                            }
                                                                          }
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "v-list-item-title",
                                                                            [
                                                                              _c(
                                                                                "v-icon",
                                                                                [
                                                                                  _vm._v(
                                                                                    _vm._s(
                                                                                      _vm.mdiPencil
                                                                                    )
                                                                                  )
                                                                                ]
                                                                              ),
                                                                              _vm._v(
                                                                                " Markup "
                                                                              )
                                                                            ],
                                                                            1
                                                                          )
                                                                        ],
                                                                        1
                                                                      ),
                                                                      _c(
                                                                        "v-list-item",
                                                                        {
                                                                          on: {
                                                                            click: function(
                                                                              $event
                                                                            ) {
                                                                              $event.stopPropagation()
                                                                              return _vm.removePhoto(
                                                                                index,
                                                                                p.file_id
                                                                              )
                                                                            }
                                                                          }
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "v-list-item-title",
                                                                            [
                                                                              _c(
                                                                                "v-icon",
                                                                                [
                                                                                  _vm._v(
                                                                                    _vm._s(
                                                                                      _vm.mdiDelete
                                                                                    )
                                                                                  )
                                                                                ]
                                                                              ),
                                                                              _vm._v(
                                                                                " Delete "
                                                                              )
                                                                            ],
                                                                            1
                                                                          )
                                                                        ],
                                                                        1
                                                                      )
                                                                    ],
                                                                    1
                                                                  )
                                                                ],
                                                                1
                                                              )
                                                            ],
                                                            1
                                                          )
                                                        ],
                                                        1
                                                      )
                                                    }
                                                  )
                                            ],
                                            2
                                          )
                                        : _c(
                                            "div",
                                            {
                                              staticClass:
                                                "d-flex justify-center body-1 font-weight-normal"
                                            },
                                            [_vm._v(" No Photos Uploaded ")]
                                          )
                                    ])
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                  ]
                : _vm._e(),
              _vm.showEditItemDialog
                ? _c("EditActionItemDialog", {
                    attrs: {
                      showEditItemDialog: _vm.showEditItemDialog,
                      selectedActionItem: _vm.selectedActionItem
                    },
                    on: {
                      "edit-action-item-dialog-close": function($event) {
                        _vm.showEditItemDialog = false
                      },
                      "action-item-saved": function($event) {
                        _vm.$emit("action-item-saved")
                        _vm.showEditItemDialog = false
                      }
                    }
                  })
                : _vm._e(),
              _c("ActionItemNotAcceptedDialog", {
                attrs: {
                  showActionItemNotAcceptedDialog:
                    _vm.showActionItemNotAcceptedDialog,
                  selectedActionItem: _vm.selectedActionItem,
                  selectedActionItemId: _vm.selectedActionItemId
                },
                on: {
                  "reject-action-item": function($event) {
                    _vm.showActionItemNotAcceptedDialog = false
                    _vm.$emit("reject-action-item")
                  }
                }
              }),
              _vm.showMarkupImageDialog[_vm.selectedFile.file_id]
                ? _c("MarkupImageDialog", {
                    attrs: {
                      showMarkupImageDialog:
                        _vm.showMarkupImageDialog[_vm.selectedFile.file_id],
                      selectedFile: _vm.selectedFile
                    },
                    on: {
                      "markup-image-dialog-close": function($event) {
                        _vm.showMarkupImageDialog = {}
                        _vm.onFileMarkupPreviewSaved()
                      },
                      "file-markup-preview-saved": _vm.onFileMarkupPreviewSaved
                    }
                  })
                : _vm._e(),
              _vm.showMarkupViewerDialog[_vm.selectedFile.file_id]
                ? _c("MarkupViewerDialog", {
                    attrs: {
                      showMarkupViewerDialog:
                        _vm.showMarkupViewerDialog[_vm.selectedFile.file_id],
                      selectedFile: _vm.selectedFile
                    },
                    on: {
                      "markup-viewer-dialog-close": function($event) {
                        _vm.showMarkupViewerDialog = {}
                      }
                    }
                  })
                : _vm._e()
            ],
            2
          ),
          _c("v-card-actions", { staticClass: "d-flex justify-end p-3" }, [
            _vm.selectedActionItem.status ===
            _vm.ACTION_ITEM_STATUSES.READY_FOR_REVIEW
              ? _c(
                  "div",
                  { staticClass: "d-flex justify-end gap" },
                  [
                    _c(
                      "v-btn",
                      {
                        attrs: { type: "button" },
                        on: {
                          click: function($event) {
                            _vm.showActionItemNotAcceptedDialog = true
                          }
                        }
                      },
                      [_vm._v(" Reject ")]
                    ),
                    _c(
                      "v-btn",
                      {
                        attrs: { color: "#3F51B5", dark: "", type: "submit" },
                        on: {
                          click: function($event) {
                            return _vm.acceptAndClose()
                          }
                        }
                      },
                      [_vm._v(" Accept and Close ")]
                    )
                  ],
                  1
                )
              : _vm.selectedActionItem.status ===
                  _vm.ACTION_ITEM_STATUSES.OPEN ||
                _vm.selectedActionItem.status ===
                  _vm.ACTION_ITEM_STATUSES.REJECTED
              ? _c(
                  "div",
                  [
                    _c(
                      "v-btn",
                      {
                        attrs: {
                          id: "submitResponse",
                          color: Boolean(_vm.response.response)
                            ? "#00A9F4"
                            : undefined,
                          dark: Boolean(_vm.response.response),
                          type: "submit",
                          disabled: !_vm.response.response
                        },
                        on: {
                          click: function($event) {
                            return _vm.submitResponse()
                          }
                        }
                      },
                      [
                        _vm._v(
                          " " +
                            _vm._s(
                              !_vm.assigneeInCurrentOrg
                                ? "Accept and Close"
                                : "Submit"
                            ) +
                            " "
                        )
                      ]
                    )
                  ],
                  1
                )
              : _vm._e()
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }