import { axiosWithRegularAuth, axiosWithNoAuth } from "@/plugins/axios";
import { db } from "@/mixins/utilisync-db";
import {
  getGisRelatedTableFieldsToUpdate,
  getUpdateGisMainTable,
} from "@/mixins/esriTablesUtilibots";

const APIURL = process.env.VUE_APP_API_URL;

const isHtml = (value) => {
  return Boolean(value?.match?.(/<[^>]*>/g));
};

export default {
  data() {
    return {
      isUpdatingFeatureService: false,
      isAddingToRelatedTables: false,
    };
  },
  methods: {
    async updateFeatureService() {
      const { data: computedFormDefinition } = await axiosWithRegularAuth.get(
        `${APIURL}/form_results/${this.formResultId}`
      );
      const [mapService] = await db.mapServices
        .filter(
          (m) => m.map_service_id === computedFormDefinition?.map_service_id
        )
        .toArray();
      const { formResultId } = this;
      const { feature_attributes: featureAttributes } = computedFormDefinition;
      const { service_url: mapServiceUrl, token_type: tokenType } =
        mapService ?? {};
      if (!mapService?.service_url) {
        return;
      }
      this.isUpdatingFeatureService = true;
      const { data: queryResult } = await axiosWithNoAuth.get(
        `${mapServiceUrl}/query`,
        {
          params: {
            where: "1=1",
            outFields: "*",
            f: "json",
            token:
              tokenType === "NONE"
                ? undefined
                : localStorage.getItem("esri_token"),
          },
        }
      );
      const objectIdFieldName = queryResult?.objectIdFieldName;
      const { data } = await axiosWithNoAuth.get(`${mapServiceUrl}/query`, {
        params: {
          objectids:
            featureAttributes[objectIdFieldName] ?? featureAttributes?.OBJECTID,
          outFields: "*",
          f: "json",
          token:
            tokenType === "NONE"
              ? undefined
              : localStorage.getItem("esri_token"),
        },
      });
      const [feature] = data.features;
      const {
        updatedFeature,
        formResultTaskMessageErrors,
        formResultTaskMessage,
      } =
        getUpdateGisMainTable(
          computedFormDefinition,
          this.formResultId,
          feature
        ) ?? {};
      const { attributes } = updatedFeature;
      const transformedAttributes = Object.fromEntries(
        Object.entries(attributes).map(([key, val]) => {
          if (isHtml(val)) {
            const tempDiv = document.createElement("div");
            tempDiv.innerHTML = val;
            const texts = [
              ...tempDiv.querySelectorAll("div,section,article,p,span"),
            ]
              .map((el) => el.textContent)
              .join("\n\n");
            return [key, texts];
          } else if (Array.isArray(val)) {
            const transformedVal = [...new Set(val.flat(Infinity))].join(" ");
            return [key, transformedVal];
          }
          return [key, val];
        })
      );
      const formData = new FormData();
      formData.append("f", "pjson");
      formData.append(
        "updates",
        JSON.stringify([
          {
            attributes: transformedAttributes,
          },
        ])
      );
      if (tokenType !== "NONE") {
        formData.append("token", localStorage.getItem("esri_token"));
      }
      const {
        data: { results },
      } = await axiosWithRegularAuth.get(
        `${APIURL}/form_result_tasks/${formResultId}`
      );
      const esriMainTableTask = results?.find(
        (r) => r.type_name === "update_esri_main_tables"
      );
      const hasUpdatedEsriMainTableTask = Boolean(esriMainTableTask);
      let messageId = esriMainTableTask?.message_id;

      try {
        const { data } = await axiosWithNoAuth.post(
          `${mapServiceUrl}/applyEdits`,
          formData
        );

        if (!hasUpdatedEsriMainTableTask) {
          await axiosWithRegularAuth.post(`${APIURL}/form_result_tasks`, {
            form_result_id: formResultId,
            type_name: "update_esri_main_tables",
          });
          const {
            data: { results },
          } = await axiosWithRegularAuth.get(
            `${APIURL}/form_result_tasks/${formResultId}`
          );
          const esriMainTableTask = results?.find(
            (r) => r.type_name === "update_esri_main_tables"
          );
          messageId = esriMainTableTask?.message_id;
        }

        const payload = [
          {
            attributes: transformedAttributes,
          },
        ];
        let message = "";
        if (formResultTaskMessageErrors.length >= 1) {
          message = `${formResultTaskMessageErrors.join(
            " - "
          )} ${formResultTaskMessage.join(" - ")}`;
        } else {
          message = `Successfully updated feature service`;
        }

        const [updateResult] = data?.updateResults ?? [];
        if (updateResult?.success) {
          await axiosWithRegularAuth.put(`${APIURL}/form_result_tasks/update`, {
            message_id: messageId,
            status_name:
              formResultTaskMessageErrors.length >= 1
                ? "completed_error"
                : "completed_success",
            message,
            request_url: `${mapServiceUrl}/applyEdits`,
            request_payload: payload,
            request_response: data,
          });
        } else {
          const details = data?.error?.details?.join(" ");
          await axiosWithRegularAuth.put(`${APIURL}/form_result_tasks/update`, {
            message_id: messageId,
            status_name: "dead_letter_queue",
            message:
              updateResult?.error?.description ??
              `${data?.error?.message} ${details}`,
            request_url: `${mapServiceUrl}/applyEdits`,
            request_payload: payload,
            request_response: data,
          });
        }
      } catch (error) {
        if (!hasUpdatedEsriMainTableTask) {
          await axiosWithRegularAuth.post(`${APIURL}/form_result_tasks`, {
            form_result_id: messageId,
            type_name: "update_esri_main_tables",
          });
        }
        await axiosWithRegularAuth.put(`${APIURL}/form_result_tasks/update`, {
          message_id: messageId,
          status_name: "dead_letter_queue",
          message: error?.response?.data?.error,
        });
      } finally {
        this.isUpdatingFeatureService = false;
      }
    },
    async addToRelatedTableFromFormResult() {
      const { data: computedFormDefinition } = await axiosWithRegularAuth.get(
        `${APIURL}/form_results/${this.formResultId}`
      );
      const [mapService] = await db.mapServices
        .filter(
          (m) => m.map_service_id === computedFormDefinition?.map_service_id
        )
        .toArray();
      if (!mapService?.service_url) {
        return;
      }
      this.isAddingToRelatedTables = true;

      if (
        !(
          computedFormDefinition?.form?.relatedTable?.relationshipId &&
          Array.isArray(computedFormDefinition?.form?.relatedTable?.fields) &&
          computedFormDefinition?.form?.relatedTable?.fields?.length > 0 &&
          mapService?.service_url
            ?.toLowerCase()
            ?.includes("FeatureServer".toLowerCase())
        )
      ) {
        return;
      }
      const entries = getGisRelatedTableFieldsToUpdate(computedFormDefinition);
      const attributes = Object.fromEntries(entries);
      const formData = new FormData();
      formData.append("f", "json");
      formData.append("token", localStorage.getItem("esri_token"));
      formData.append(
        "adds",
        JSON.stringify([
          {
            attributes,
          },
        ])
      );
      const { relationshipId } =
        computedFormDefinition?.form?.relatedTable ?? {};
      const mapServiceUrl = mapService.service_url;
      const relatedTableMapServiceUrl = mapServiceUrl.replace(
        /featureServer\/\d{1,}/i,
        `FeatureServer/${relationshipId}`
      );
      const { formResultId } = this;
      const {
        data: { results },
      } = await axiosWithRegularAuth.get(
        `${APIURL}/form_result_tasks/${formResultId}`
      );
      const relatedTablesTask = results?.find(
        (r) => r.type_name === "update_related_tables"
      );
      let messageId = relatedTablesTask?.message_id;
      const hasUpdatedRelatedTablesTask = Boolean(relatedTablesTask);
      if (!hasUpdatedRelatedTablesTask) {
        await axiosWithRegularAuth.post(`${APIURL}/form_result_tasks`, {
          form_result_id: formResultId,
          type_name: "update_related_tables",
        });
        const {
          data: { results },
        } = await axiosWithRegularAuth.get(
          `${APIURL}/form_result_tasks/${formResultId}`
        );
        const relatedTablesTask = results?.find(
          (r) => r.type_name === "update_related_tables"
        );
        messageId = relatedTablesTask.message_id;
      }

      try {
        const { data } = await axiosWithNoAuth.post(
          `${relatedTableMapServiceUrl}/applyEdits`,
          formData
        );

        const payload = [
          {
            attributes,
          },
        ];

        const [addResult] = data?.addResults ?? [];
        if (addResult?.success) {
          await axiosWithRegularAuth.put(`${APIURL}/form_result_tasks/update`, {
            message_id: messageId,
            status_name: "completed_success",
            message: `Successfully added a record to the related table`,
            request_url: `${relatedTableMapServiceUrl}/applyEdits`,
            request_payload: payload,
            request_response: data,
          });
        } else {
          const details = data?.error?.details?.join(" ");
          await axiosWithRegularAuth.put(`${APIURL}/form_result_tasks/update`, {
            message_id: messageId,
            status_name: "dead_letter_queue",
            message:
              addResult?.error?.description ??
              `${data?.error?.message} ${details}`.trim(),
            request_url: `${relatedTableMapServiceUrl}/applyEdits`,
            request_payload: payload,
            request_response: data,
          });
        }
      } catch (error) {
        await axiosWithRegularAuth.put(`${APIURL}/form_result_tasks/update`, {
          message_id: messageId,
          status_name: "dead_letter_queue",
          message: `Failed to add record: ${JSON.stringify(attributes)}`,
        });
      } finally {
        this.isAddingToRelatedTables = false;
      }
    },
  },
};
