<template>
  <v-dialog :value="showEditActionItemDialog" max-width="600px" persistent>
    <validation-observer ref="actionItemForm" v-slot="{ valid: isFormValid }">
      <v-card>
        <v-toolbar dark color="#3F51B5">
          <v-toolbar-title>Action Item</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon dark @click="closeForm">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>

        <v-card-text
          class="pt-3"
          id="dynamic-form-card-text"
          :style="{
            height: $vuetify.breakpoint.xsOnly ? `${contentHeight}px` : '65vh',
          }"
        >
          <v-row>
            <v-col cols="12">
              <validation-provider
                v-slot="{ errors, valid }"
                name="Description"
                rules="required"
              >
                <v-textarea
                  label="Description *"
                  hide-details="auto"
                  :error-messages="errors"
                  :success="valid"
                  color="#3F51B5"
                  name="value"
                  v-model="actionItem.action_item_description"
                  :disabled="readOnly"
                  :hint="item.question.descriptionHint"
                  auto-grow
                  rows="1"
                  ref="textAreaRef"
                >
                </v-textarea>
              </validation-provider>
            </v-col>

            <v-col
              cols="12"
              class="d-flex gap justify-space-between"
              v-if="selectedMapService && selectedMapService.site_enabled"
            >
              <div class="half">
                <validation-provider
                  v-slot="{ errors, valid }"
                  name="Assign To"
                  rules="required"
                >
                  <v-select
                    v-model="assignTo"
                    :items="ASSIGN_TO_CHOICES"
                    hide-details="auto"
                    name="assignTo"
                    item-value="value"
                    item-text="label"
                    :error-messages="errors"
                    :success="valid"
                    label="Assign To *"
                    :disabled="readOnly"
                  />
                </validation-provider>
              </div>
            </v-col>

            <v-col
              cols="12"
              class="d-flex gap justify-space-between"
              v-if="assignTo === ASSIGN_TO.SITE_CONTACT"
            >
              <div class="half">
                <validation-provider
                  v-slot="{ errors, valid }"
                  name="Site Contact"
                  rules="required"
                >
                  <v-combobox
                    v-model="sharedSiteContactUser"
                    :items="sharedSiteContacts"
                    hide-details="auto"
                    name="siteContact"
                    item-value="user_id"
                    :error-messages="errors"
                    :success="valid"
                    label="Site Contact *"
                    :disabled="readOnly"
                  >
                    <template #item="{ item }">
                      <section>
                        <div>{{ item.f_name }} {{ item.l_name }}</div>
                        <div class="caption">{{ item.email }}</div>
                      </section>
                    </template>

                    <template #selection="{ item }">
                      <section>
                        <div>{{ item.f_name }} {{ item.l_name }}</div>
                        <div class="caption">{{ item.email }}</div>
                      </section>
                    </template>
                  </v-combobox>
                </validation-provider>
              </div>
            </v-col>
            <v-col
              cols="12"
              class="d-flex gap justify-space-between"
              v-else-if="assignTo === ASSIGN_TO.ORG_USER"
            >
              <div class="half">
                <validation-provider
                  v-slot="{ errors, valid }"
                  name="Organization User"
                  rules="required"
                >
                  <v-combobox
                    v-model="assigneeUserId"
                    :items="userChoices"
                    hide-details="auto"
                    name="organizationUser"
                    item-value="value"
                    item-text="name"
                    :error-messages="errors"
                    :success="valid"
                    label="Organization User *"
                    :disabled="readOnly"
                    :return-object="false"
                  >
                    <template #item="{ item }">
                      <section>
                        <div>{{ item.name }}</div>
                      </section>
                    </template>

                    <template #selection="{ item: assigneeUserId }">
                      <section>
                        <div>{{ getAssigneeName(assigneeUserId) }}</div>
                      </section>
                    </template>
                  </v-combobox>
                </validation-provider>
              </div>
            </v-col>

            <v-col cols="12">
              <div class="half">
                <validation-provider
                  slim
                  v-slot="{ errors, valid }"
                  name="Due By"
                  rules="required"
                >
                  <v-menu>
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        v-on="on"
                        label="Due By *"
                        color="#3F51B5"
                        v-model="actionItem.due_by"
                        :error-messages="errors"
                        :success="valid"
                        :disabled="readOnly"
                      ></v-text-field>
                    </template>
                    <v-date-picker v-model="actionItem.due_by"></v-date-picker>
                  </v-menu>
                </validation-provider>
              </div>
            </v-col>

            <v-col cols="12">
              <p class="caption">Photos</p>
              <file-pond
                name="files"
                ref="files"
                label-idle="Tap or drop photos here..."
                allow-multiple
                accepted-file-types="image/jpeg, image/png"
                :files="uploadFiles"
                @addfile="onChange"
                allowFileSizeValidation
                maxFileSize="2MB"
                :disabled="readOnly"
              />

              <v-card
                v-for="(image, index) of actionItem.assigner_photos"
                :key="image.id"
              >
                <v-card-text>
                  <v-list-item>
                    <v-list-item-content>
                      <v-card class="d-flex flex-row justify-start elevation-0">
                        <v-card
                          class="
                            elevation-0
                            d-flex
                            flex-column
                            justify-center
                            mr-2
                          "
                        >
                          <img
                            :src="getImageUrl(image)"
                            class="thumbnail mr-2 cursor-pointer"
                            @click.stop="
                              !isDisabled
                                ? onClick(image.id)
                                : onViewClick(image)
                            "
                          />
                        </v-card>
                        <v-card class="elevation-0" width="100%">
                          <v-textarea
                            :key="image.id"
                            label="Description"
                            color="#3F51B5"
                            :name="image.id"
                            v-model="image.description"
                            :disabled="readOnly"
                            auto-grow
                            rows="1"
                            ref="imageTextAreaRef"
                          >
                          </v-textarea>
                        </v-card>
                      </v-card>
                    </v-list-item-content>

                    <v-list-item-action>
                      <v-list-item-action-text>
                        <v-menu>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn icon v-bind="attrs" v-on="on">
                              <v-icon>{{ mdiDotsVertical }}</v-icon>
                            </v-btn>
                          </template>

                          <v-list>
                            <v-list-item
                              @click="onClick(image.id)"
                              v-if="
                                !alreadySubmittedFinalOnline ||
                                isEditingFinalForm
                              "
                            >
                              <v-list-item-title
                                :style="{
                                  'pointer-events': isBase64Image(image)
                                    ? 'none'
                                    : undefined,
                                  opacity: isBase64Image(image) ? 0.5 : 1,
                                }"
                              >
                                <v-icon>{{ mdiFileEdit }}</v-icon>
                                Markup
                              </v-list-item-title>
                            </v-list-item>
                            <v-list-item @click="onViewClick(image)" v-else>
                              <v-list-item-title>
                                <v-icon>
                                  {{ mdiFile }}
                                </v-icon>
                                View Image
                              </v-list-item-title>
                            </v-list-item>

                            <v-list-item
                              @click="removePhoto(index, image.id)"
                              v-if="
                                !alreadySubmittedFinalOnline ||
                                isEditingFinalForm
                              "
                            >
                              <v-list-item-title>
                                <v-icon>{{ mdiDelete }}</v-icon>
                                Delete
                              </v-list-item-title>
                            </v-list-item>
                          </v-list>
                        </v-menu>
                      </v-list-item-action-text>
                    </v-list-item-action>
                  </v-list-item>
                </v-card-text>
                <MarkupViewerDialog
                  v-if="showMarkupViewerDialog[image.id]"
                  @markup-viewer-dialog-close="showMarkupViewerDialog = {}"
                  :showMarkupViewerDialog="showMarkupViewerDialog[image.id]"
                  :selectedFile="selectedFile"
                />
              </v-card>
            </v-col>
          </v-row>

          <MarkupImageDialog
            v-if="showMarkupImageDialog[selectedFile.file_id]"
            @markup-image-dialog-close="
              showMarkupImageDialog = {};
              onFileMarkupPreviewSaved();
            "
            @file-markup-preview-saved="onFileMarkupPreviewSaved"
            :showMarkupImageDialog="showMarkupImageDialog[selectedFile.file_id]"
            :selectedFile="selectedFile"
          />
        </v-card-text>

        <v-card-actions class="d-flex justify-end px-5">
          <v-btn
            color="#3F51B5"
            text
            @click="$emit('delete-action-item', selectedActionItem)"
            :disabled="!isFormValid || uploadingFiles || isDisabled"
          >
            Delete
          </v-btn>
          <v-btn
            color="#3F51B5"
            @click="save"
            :disabled="!isFormValid || uploadingFiles || isDisabled"
            dark
          >
            Save Action Item
          </v-btn>
        </v-card-actions>
      </v-card>
    </validation-observer>
  </v-dialog>
</template>

<script>
import { cloneDeep } from "lodash";
import vueFilePond, { setOptions } from "vue-filepond";
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import FilePondPluginFileValidateSize from "filepond-plugin-file-validate-size";
import {
  mdiDelete,
  mdiDotsVertical,
  mdiPencil,
  mdiFile,
  mdiFileEdit,
} from "@mdi/js";
import "filepond/dist/filepond.min.css";
import { axiosWithRegularAuth } from "@/plugins/axios";
import { db } from "@/mixins/utilisync-db";
import { ASSIGN_TO, ASSIGN_TO_CHOICES } from "@/constants/actionItemChoices";
import contentHeightMixin from "@/mixins/contentHeightMixin";
import MarkupImageDialog from "@/components/mapView/docs-tab/MarkupImageDialog";
import MarkupViewerDialog from "@/components/mapView/docs-tab/MarkupViewerDialog";
import moment from "moment";
import heic2any from "heic2any";
import loadImage from "blueimp-load-image";
import sleep from "@/mixins/sleep";
import { v4 as uuidv4 } from "uuid";

const APIURL = process.env.VUE_APP_API_URL;

const base64Regex =
  /^(?:[A-Za-z0-9+/]{4})*(?:[A-Za-z0-9+/]{2}==|[A-Za-z0-9+/]{3}=)?$/;

const FilePond = vueFilePond(
  FilePondPluginFileValidateType,
  FilePondPluginImagePreview,
  FilePondPluginFileValidateSize
);

setOptions({
  imageResizeMode: "contain",
  allowImageTransform: true,
});

export default {
  name: "EditActionItemDialog",
  props: {
    showEditActionItemDialog: Boolean,
    item: Object,
    readOnly: Boolean,
    isEditingFinalForm: Boolean,
    alreadySubmittedFinalOnline: Boolean,
    selectedActionItem: Object,
    userGroup: Array,
    formResultId: String,
    formDefinition: Object,
    selectedMapServiceId: String,
    gisInfoObjectId: [String, Number],
  },
  mixins: [contentHeightMixin],
  data() {
    return {
      actionItem: {
        action_item_description: "",
        assignee_user_id: "",
        due_by: "",
        assigner_photos: [],
      },
      uploadFiles: undefined,
      mdiDelete,
      mdiDotsVertical,
      mdiPencil,
      mdiFile,
      mdiFileEdit,
      assigneeUserId: undefined,
      manuallyEnteredAssigneeEmail: "",
      users: [],
      ASSIGN_TO_CHOICES,
      ASSIGN_TO,
      assignTo: ASSIGN_TO.SITE_CONTACT,
      selectedMapService: {},
      sharedSiteContacts: [],
      sharedSiteContactUser: "",
      showMarkupImageDialog: {},
      showMarkupViewerDialog: {},

      selectedFile: {},
      uploadingFiles: false,
    };
  },
  watch: {
    gisInfoObjectId: {
      immediate: true,
      handler() {
        this.getData();
      },
    },
    selectedMapServiceId: {
      immediate: true,
      handler() {
        this.getData();
      },
    },
  },
  beforeMount() {
    this.getData();
  },
  components: {
    FilePond,
    MarkupImageDialog,
    MarkupViewerDialog,
  },
  computed: {
    isDisabled() {
      if (this.alreadySubmittedFinalOnline) {
        return this.readOnly || !this.isEditingFinalForm;
      }
      return this.readOnly;
    },
    userChoices() {
      if (!Array.isArray(this.users)) {
        return [];
      }
      return this.users
        .filter((u) => !u.is_contact && u.is_active)
        .map((u) => {
          const { user_id: value, f_name: fName, l_name: lName } = u;
          return {
            name: `${fName} ${lName}`,
            value,
          };
        })
        .sort((a, b) => {
          return a.name.localeCompare(b.name);
        });
    },
  },
  methods: {
    getAssigneeName(assigneeUserId) {
      return this.userChoices.find((u) => u.value === assigneeUserId)?.name;
    },
    getImageUrl(image) {
      return image?.metadata?.s3_file_path ?? image?.url;
    },
    async onFileMarkupPreviewSaved() {
      const base64ImagePromises = [...this.actionItem.assigner_photos].map(
        async (p) => {
          const {
            data: { results: metadata },
          } = await axiosWithRegularAuth.get(
            `${APIURL}/files/${p.id}/metadata`
          );
          return {
            ...p,
            metadata,
          };
        }
      );
      const base64Images = await Promise.all(base64ImagePromises);
      this.actionItem.assigner_photos = [
        ...base64Images.map((im) => {
          const { id, description, url, thumbnail, metadata } = im;
          return { id, description, url, thumbnail, metadata };
        }),
      ];
    },
    isBase64Image(image) {
      const { url } = image;
      const [, base64] = url.split(",");
      return base64Regex.test(base64);
    },
    async onClick(fileId) {
      const {
        data: { results },
      } = await axiosWithRegularAuth.get(`${APIURL}/files/${fileId}/metadata`);
      this.selectedFile = {
        file_id: fileId,
        s3_file_path_original_image: results?.s3_file_path_original_image,
      };

      this.showMarkupImageDialog = {
        ...this.showMarkupImageDialog,
        [fileId]: true,
      };
    },
    async onViewClick(image) {
      const { id, fileName, description, url } = image;
      if (this.isBase64Image(image)) {
        this.selectedFile = {
          file_id: id,
          s3_file_path_original_image: url,
          name: fileName ?? description,
        };
      } else {
        const {
          data: { results },
        } = await axiosWithRegularAuth.get(`${APIURL}/files/${id}/metadata`);
        this.selectedFile = {
          file_id: id,
          s3_file_path_original_image: results?.s3_file_path_original_image,
          name: fileName ?? description,
        };
      }
      this.showMarkupViewerDialog = {
        ...this.showMarkupViewerDialog,
        [id]: true,
      };
    },
    async getData() {
      await this.getSiteContacts();
      await this.getMapService();
      await this.getOrgUsers();
      this.actionItem = cloneDeep(this.selectedActionItem);
      const { assignee_user_id: assigneeUserId } = this.actionItem;
      const sharedSiteContact = this.sharedSiteContacts.find(
        (c) => c.user_id === assigneeUserId
      );
      const siteContactUserIds = this.sharedSiteContacts.map((u) => u.user_id);
      const orgUser = this.users
        .filter((u) => !siteContactUserIds.includes(u.user_id))
        .find((u) => u.user_id === assigneeUserId);

      const assigneeIsOrgUser = Boolean(orgUser);
      const assigneeIsSiteContact = Boolean(sharedSiteContact);

      if (assigneeIsOrgUser) {
        this.assignTo = ASSIGN_TO.ORG_USER;
        this.assigneeUserId = assigneeUserId;
      } else if (assigneeIsSiteContact) {
        this.assignTo = ASSIGN_TO.SITE_CONTACT;
        this.sharedSiteContactUser = sharedSiteContact;
      }
    },
    async getMapService() {
      const [mapService] = await db.mapServices
        .filter((m) => {
          return m.map_service_id === this.selectedMapServiceId;
        })
        .toArray();
      this.selectedMapService = mapService;
    },
    async getSiteContacts() {
      const { selectedMapServiceId, gisInfoObjectId } = this;
      if (!selectedMapServiceId || !gisInfoObjectId) {
        return;
      }

      const {
        data: { results },
      } = await axiosWithRegularAuth.get(`${APIURL}/shared_site_contacts`, {
        params: {
          map_service_id: selectedMapServiceId,
          feature_id: gisInfoObjectId,
        },
      });
      this.sharedSiteContacts = results.sort((a, b) => {
        const nameA = `${a.f_name} ${a.l_name}`;
        const nameB = `${b.f_name} ${b.l_name}`;
        return nameA.localeCompare(nameB);
      });
    },
    closeForm() {
      this.$emit("close-form");
    },
    async getOrgUsers() {
      const allUsers = await db.users.toCollection().toArray();
      const siteContactUserIds = this.sharedSiteContacts.map((u) => u.user_id);
      this.users = allUsers.filter(
        (u) => !siteContactUserIds.includes(u.user_id)
      );
    },
    async save() {
      const success = await this.$refs.actionItemForm.validate();
      if (!success) {
        return;
      }
      if (this.assignTo === ASSIGN_TO.SITE_CONTACT) {
        const sharedSiteUser = this.sharedSiteContacts.find(
          (u) => u.user_id === this.sharedSiteContactUser.user_id
        );
        const {
          email,
          user_id: userId,
          f_name: fName,
          l_name: lName,
          company,
        } = sharedSiteUser;
        this.actionItem = {
          ...this.actionItem,
          due_by: moment(this.actionItem.due_by).utc().format("YYYY-MM-DD"),
          assignee_user_id: userId,
          email_from_from: null,
          assignee_email: email,
          assigned_to_user_name: `${fName} ${lName}`,
          assigned_to_user_group_name: company,
        };
      } else {
        const user = this.users.find((u) => u.user_id === this.assigneeUserId);
        if (user) {
          const { user_id: userId, f_name: fName, l_name: lName } = user;
          const { organization } = JSON.parse(localStorage.getItem("auth"));
          this.actionItem = {
            ...this.actionItem,
            due_by: moment(this.actionItem.due_by).utc().format("YYYY-MM-DD"),
            assignee_user_id: userId,
            email_from_from: null,
            assignee_email: null,
            assigned_to_user_name: `${fName} ${lName}`,
            assigned_to_user_group_name: organization.name,
          };
        }
      }
      const actionItem = cloneDeep(this.actionItem);
      this.$emit("form-saved", actionItem);
    },
    async createPayload(resultCanvas, fileName, fileType) {
      const dataUrl = resultCanvas.toDataURL("image/png");
      const blob = await new Promise((resolve) => {
        resultCanvas.toBlob(resolve, "image/png");
      });
      const resizedImageFile = new File([blob], fileName, {
        type: fileType,
      });
      const formData = new FormData();
      formData.append("file", resizedImageFile);
      formData.append("file_name", fileName);
      formData.append("is_image", true);
      return { formData, dataUrl };
    },
    async processImage(file) {
      try {
        const { name: fileName, type: fileType } = file;
        let result;

        await sleep(500);
        if (fileName.endsWith("heif") || fileName.endsWith("heic")) {
          const blobURL = URL.createObjectURL(file);
          const blobRes = await fetch(blobURL);
          const blob = await blobRes.blob();
          const conversionResult = await heic2any({
            blob,
          });
          const options = {
            orientation: true,
            canvas: true,
          };
          const { image } = await loadImage(conversionResult, options);
          result = image;
        } else {
          const options = {
            orientation: true,
            canvas: true,
          };
          try {
            const { image } = await loadImage(file, options);
            result = image;
          } catch (error) {
            console.log(error);
            return undefined;
          }
        }
        await sleep(500);
        const { formData, dataUrl } = await this.createPayload(
          result,
          fileName,
          fileType
        );
        if (result) {
          result.width = 1;
          result.height = 1;
          const ctx = result.getContext("2d");
          ctx?.clearRect?.(0, 0, 1, 1);
        }

        if (navigator.onLine) {
          const {
            data: { file },
          } = await axiosWithRegularAuth.post(
            `${APIURL}/form_results/${this.formResultId}/files`,
            formData
          );
          return {
            dataUrl,
            description: "",
            fileName,
            fileType,
            ...file,
            uploaded: true,
            isImage: true,
          };
        } else {
          return {
            dataUrl,
            description: "",
            fileName,
            fileType,
            id: uuidv4(),
            name: fileName,
            url: dataUrl,
            uploaded: false,
            isImage: true,
          };
        }
      } catch (error) {
        console.log(error);
        return undefined;
      }
    },
    async onChange() {
      if (this.uploadingFiles) {
        return;
      }
      this.uploadingFiles = true;
      const files = this.$refs.files.getFiles();
      for (const f of files) {
        const base64Image = await this.processImage(f.file);
        const { id, description, url, thumbnail } = base64Image;
        this.actionItem.assigner_photos = [
          ...(this.actionItem.assigner_photos ?? []),
          { id, description, url, thumbnail, metadata: {} },
        ];
        this.$refs.files.removeFile(f);
      }
      await this.onFileMarkupPreviewSaved();
      this.uploadingFiles = false;
    },
    async removePhoto(index, imageId) {
      this.actionItem.assigner_photos.splice(index, 1);
      await axiosWithRegularAuth.delete(`${APIURL}/files/${imageId}`);
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.$refs.textAreaRef;
      this.$refs.imageTextAreaRef;
    });
  },
};
</script>

<style scoped>
.thumbnail {
  width: 70px;
}

.half {
  width: 50%;
}
</style>
