const FORM_RESULT_TASK_STATUS = {
  COMPLETED_SUCCESS: "completed_success",
  DEAD_LETTER_QUEUE: "dead_letter_queue",
  COMPLETED_WITH_ERRORS: "completed_with_errors",
  COMPLETED_ERROR: "completed_error",
  ERROR: "error",
  PROCESSING: "processing",
};

export default FORM_RESULT_TASK_STATUS;
