<template>
  <validation-observer ref="form">
    <form @submit.prevent="submit" id="formDialog">
      <v-toolbar
        dark
        color="#3F51B5"
        class="elevation-0"
        v-if="computedFormDefinition.form"
        width="100%"
        id="top-bar"
        ref="toolbar"
      >
        <v-toolbar-title>
          <div>
            {{ formDescription.title }}
          </div>
          <div class="caption">
            {{ formDescription.description }}
          </div>
        </v-toolbar-title>

        <v-spacer />

        <v-menu offset-x>
          <template v-slot:activator="{ on, attrs }">
            <v-btn text v-bind="attrs" v-on="on" x-small class="px-0 mx-0">
              <v-icon>{{ mdiDotsVertical }}</v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item
              class="px-4 mx-0"
              @click="
                autoSave();
                $emit('refresh-log');
              "
              id="save-button"
              v-if="!alreadySubmittedFinalOnline || isEditingFinalForm"
            >
              <v-icon class="mr-2">
                {{ mdiFile }}
              </v-icon>
              Save Draft
            </v-list-item>

            <v-list-item class="px-4 mx-0" @click="showFormInfoDialog = true">
              <v-icon class="mr-2">
                {{ mdiInformation }}
              </v-icon>
              Form Submission ID
            </v-list-item>

            <v-list-item
              class="px-4 mx-0 d-flex align-center"
              v-if="cityworksActivityId"
              @click="openCityworksActivity(CITYWORKS.RS)"
            >
              <img src="@/assets/cityworks-logo.svg" class="icon mr-2" />
              ID:
              {{ cityworksActivityId }}
            </v-list-item>

            <v-list-item
              class="px-4 mx-0"
              @click="showDeleteFormDialog = true"
              id="delete-button"
            >
              <v-icon class="mr-2">
                {{ mdiDelete }}
              </v-icon>
              Delete Form
            </v-list-item>
          </v-list>
        </v-menu>
        <v-btn icon @click="onCloseButtonClick">
          <v-icon>{{ mdiClose }}</v-icon>
        </v-btn>
      </v-toolbar>

      <v-card-text
        class="pa-0 mb-0"
        id="dynamic-form-card-text"
        :style="{
          height: `${contentHeight}px`,
        }"
        v-if="!formLoading"
      >
        <v-expansion-panels
          class="px-2 py-0 my-0"
          v-model="openSections"
          accordion
          multiple
          v-if="computedFormDefinition.form"
        >
          <v-expansion-panel
            v-for="section in computedFormDefinition.form.sections"
            :key="section.id"
            v-show="isSectionVisible(section)"
          >
            <v-expansion-panel-header class="py-0 my-0 d-flex align-center">
              <b>{{ section.name }}</b>
            </v-expansion-panel-header>
            <v-expansion-panel-content eager>
              <v-row v-if="showForm">
                <v-col
                  cols="12"
                  v-for="item in section.items"
                  :key="`${item.id}-${item.number}`"
                  :style="{ display: isVisible(item) ? 'block' : 'none' }"
                >
                  <template v-if="isQuestion(item)">
                    <validation-provider v-if="isVisible(item)">
                      <TextInput
                        v-if="
                          ['TEXT', 'EMAIL', 'NUMBER'].includes(
                            item.question.type
                          )
                        "
                        v-model="item.value"
                        :item="item"
                        :formDefinition="computedFormDefinition"
                        :canEdit="canEdit"
                        :isEditingFinalForm="isEditingFinalForm"
                        :alreadySubmittedFinalOnline="
                          alreadySubmittedFinalOnline
                        "
                        color="#3F51B5"
                        @input="onInput"
                        @validated="onFieldValidated"
                      />

                      <DateInput
                        v-if="['DATE'].includes(item.question.type)"
                        v-model="item.value"
                        :item="item"
                        @input="onInput"
                        @validated="onFieldValidated"
                        :formDefinition="computedFormDefinition"
                        :canEdit="canEdit"
                        :isEditingFinalForm="isEditingFinalForm"
                        :alreadySubmittedFinalOnline="
                          alreadySubmittedFinalOnline
                        "
                      />

                      <TimeInput
                        v-if="['TIME'].includes(item.question.type)"
                        v-model="item.value"
                        :item="item"
                        @input="onInput"
                        @validated="onFieldValidated"
                        :formDefinition="computedFormDefinition"
                        :canEdit="canEdit"
                        :isEditingFinalForm="isEditingFinalForm"
                        :alreadySubmittedFinalOnline="
                          alreadySubmittedFinalOnline
                        "
                      />

                      <SingleSelectInput
                        v-if="['SINGLE_SELECT'].includes(item.question.type)"
                        :item="item"
                        v-model="item.value"
                        :formDefinition="computedFormDefinition"
                        :canEdit="canEdit"
                        :isEditingFinalForm="isEditingFinalForm"
                        :alreadySubmittedFinalOnline="
                          alreadySubmittedFinalOnline
                        "
                        @input="onInput"
                        @validated="onFieldValidated"
                      />

                      <MultiSelectInput
                        v-if="['MULTI_SELECT'].includes(item.question.type)"
                        :item="item"
                        v-model="item.value"
                        :formDefinition="computedFormDefinition"
                        :canEdit="canEdit"
                        :isEditingFinalForm="isEditingFinalForm"
                        :alreadySubmittedFinalOnline="
                          alreadySubmittedFinalOnline
                        "
                        @input="onInput"
                        @validated="onFieldValidated"
                      />

                      <SignaturePad
                        v-if="['SIGNATURE'].includes(item.question.type)"
                        v-model="item.value"
                        :item="item"
                        :formDefinition="computedFormDefinition"
                        :canEdit="canEdit"
                        :isEditingFinalForm="isEditingFinalForm"
                        :alreadySubmittedFinalOnline="
                          alreadySubmittedFinalOnline
                        "
                        @input="onInput"
                        @validated="onFieldValidated"
                      />

                      <CalculationInput
                        v-if="['CALCULATION'].includes(item.question.type)"
                        :formDefinition="computedFormDefinition"
                        :item="item"
                        v-model="item.value"
                      />

                      <div
                        v-if="
                          ['FILE'].includes(item.question.type) && formResultId
                        "
                      >
                        <PhotoInput
                          v-if="item.question.isImage"
                          :label="`${item.number} ${item.question.label}`"
                          :id="item.id"
                          :allowMultiple="item.allowMultiple"
                          :showDescription="item.question.showDescription"
                          v-model="item.value"
                          :formResultId="formResultId"
                          :maxWidthHeight="+item.question.maxWidthHeight"
                          :formDefinition="computedFormDefinition"
                          :item="item"
                          :canEdit="canEdit"
                          :isEditingFinalForm="isEditingFinalForm"
                          :alreadySubmittedFinalOnline="
                            alreadySubmittedFinalOnline
                          "
                          :selectedGisInfo="selectedGisInfo"
                          :selectedMapServiceId="selectedMapServiceId"
                          :globalId="globalId"
                          :objectId="objectId"
                          @start-photo-upload="
                            photoUploading = true;
                            autoSave();
                          "
                          @end-photo-upload="
                            photoUploading = false;
                            autoSave();
                          "
                          @auto-save="autoSave()"
                          @validated="onFieldValidated"
                          :ref="`photo-input-${item.id}`"
                        />

                        <FileInput
                          v-else
                          v-model="item.value"
                          :formResultId="formResultId"
                          :id="item.id"
                          :label="`${item.number} ${item.question.label}`"
                          :allowMultiple="item.allowMultiple"
                          :formDefinition="computedFormDefinition"
                          :item="item"
                          :canEdit="canEdit"
                          :isEditingFinalForm="isEditingFinalForm"
                          :alreadySubmittedFinalOnline="
                            alreadySubmittedFinalOnline
                          "
                          @validated="onFieldValidated"
                        />
                      </div>

                      <RepeatingGroup
                        v-if="['GROUP'].includes(item.question.type)"
                        :item="item"
                        :formDefinition="computedFormDefinition"
                        :canEdit="canEdit"
                        :isEditingFinalForm="isEditingFinalForm"
                        :alreadySubmittedFinalOnline="
                          alreadySubmittedFinalOnline
                        "
                        :formResultId="formResultId"
                        :selectedGisInfo="selectedGisInfo"
                        :selectedMapServiceId="selectedMapServiceId"
                        :globalId="globalId"
                        :objectId="objectId"
                        @changes-made="onInput"
                        v-model="item.value"
                      />

                      <ActionItem
                        v-if="['ACTION_ITEM'].includes(item.question.type)"
                        :item="item"
                        :formDefinition="computedFormDefinition"
                        :canEdit="canEdit"
                        :isEditingFinalForm="isEditingFinalForm"
                        :alreadySubmittedFinalOnline="
                          alreadySubmittedFinalOnline
                        "
                        :formResultId="formResultId"
                        :selectedGisInfo="selectedGisInfo"
                        :selectedMapServiceId="selectedMapServiceId"
                        :objectId="objectId"
                        @changes-made="onInput"
                        v-model="item.value"
                      />
                    </validation-provider>
                  </template>
                  <template v-else>
                    <InformationItem :item="item" v-if="isVisible(item)" />
                  </template>
                </v-col>
              </v-row>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
        <v-divider></v-divider>
      </v-card-text>
      <v-card-text
        class="pa-0 mb-0 d-flex justify-center align-center"
        id="dynamic-form-card-text"
        :style="{
          height: `${contentHeight}px`,
        }"
        v-else
      >
        <v-progress-circular indeterminate color="#3F51B5">
        </v-progress-circular>
      </v-card-text>
      <template>
        <DynamicFormEditActions
          ref="cardAction"
          v-if="alreadySubmittedFinalOnline && !isEditingFinalForm"
          :canEdit="canEdit"
          :formResultId="formResultId"
          :selectedPdfFileUrl="selectedPdfFileUrl"
          :editableAfterFinal="editableAfterFinal"
          @edit-form="isEditingFinalForm = true"
          @form-result-deleted="$emit('ticket-edit-form-close')"
        />
        <DynamicFormNormalActions
          ref="cardAction"
          v-else
          :lastSavedTime="lastSavedTimes[formResultId]"
          :isSaving="isSaving"
          :isUpdatingFeatureService="isUpdatingFeatureService"
          :isAddingToRelatedTables="isAddingToRelatedTables"
          :isUpdatingDataAfterSubmit="isUpdatingDataAfterSubmit"
          @invalid-fields-info-dialog-show="showInvalidFieldsDialog = true"
          :canEdit="canEdit"
          :isOnline="isOnline"
          :invalidFields="invalidFields"
          :alreadySubmittedFinalOnline="alreadySubmittedFinalOnline"
          :submittingFinalForm="submittingFinalForm"
          id="bottom-bar"
        />
      </template>
    </form>

    <AlreadyClearedDialog
      :showAlreadyClearedDialog="showAlreadyClearedDialog"
      :ticketNumbersAlreadySubmitted="ticketNumbersAlreadySubmitted"
      :formResultIdsAlreadySubmitted="formResultIdsAlreadySubmitted"
      @delete-forms="showAlreadyClearedDialog = false"
      @keep-forms="showAlreadyClearedDialog = false"
    />

    <FormInfoDialog
      :formResultId="formResultId"
      :showFormInfoDialog="showFormInfoDialog"
      @form-info-dialog-close="showFormInfoDialog = false"
    />

    <InvalidFieldsDialog
      :showInvalidFieldsDialog="showInvalidFieldsDialog"
      :invalidFieldSections="invalidFieldSections"
      @invalid-fields-dialog-close="showInvalidFieldsDialog = false"
    />

    <ErrorConnectingToGisFeatureDialog
      v-if="showErrorConnectingToGisFeatureDialog"
      :showErrorConnectingToGisFeatureDialog="
        showErrorConnectingToGisFeatureDialog
      "
      :gisInfoQueryResult="gisInfoQueryResult"
      :selectedMapService="selectedMapService"
      @error-connecting-to-gis-feature-dialog-close="
        showErrorConnectingToGisFeatureDialog = false;
        deleteFormResult();
      "
      @create-form-result="showErrorConnectingToGisFeatureDialog = false"
    />

    <DeleteFormDialog
      :showDeleteFormDialog="showDeleteFormDialog"
      @cancel-delete-form="showDeleteFormDialog = false"
      @delete-form="deleteFormResult"
    />

    <FormOfflineDialog
      :showFormOfflineDialog="showFormOfflineDialog"
      @cancel="
        showFormOfflineDialog = false;
        onCloseButtonClick();
      "
    />

    <FeatureServiceUnreachableDialog
      :selectedMapServiceId="selectedMapServiceId"
      :showFeatureServiceUnreachableDialog="showFeatureServiceUnreachableDialog"
      :fullRequestUrl="fullRequestUrl"
      @cancel="
        showFeatureServiceUnreachableDialog = false;
        onCloseButtonClick();
      "
    />
  </validation-observer>
</template>

<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";
import SignaturePad from "@/components/tickets/shared/SignaturePad";
import TextInput from "@/components/tickets/shared/TextInput";
import PhotoInput from "@/components/tickets/shared/PhotoInput";
import FileInput from "@/components/tickets/shared/FileInput";
import SingleSelectInput from "@/components/tickets/shared/SingleSelectInput";
import MultiSelectInput from "@/components/tickets/shared/MultiSelectInput";
import CalculationInput from "@/components/tickets/shared/CalculationInput";
import DateInput from "@/components/tickets/shared/DateInput";
import TimeInput from "@/components/tickets/shared/TimeInput";
import RepeatingGroup from "@/components/tickets/shared/RepeatingGroup";
import ActionItem from "@/components/tickets/shared/ActionItem";
import DynamicFormEditActions from "@/components/tickets/ticket-edit-form/dynamic-form/DynamicFormEditActions";
import DynamicFormNormalActions from "@/components/tickets/ticket-edit-form/dynamic-form/DynamicFormNormalActions";
import ErrorConnectingToGisFeatureDialog from "@/components/tickets/ticket-edit-form/dynamic-form/ErrorConnectingToGisFeatureDialog";
import InformationItem from "@/components/tickets/shared/InformationItem";
import FormInfoDialog from "@/components/tickets/shared/FormInfoDialog";
import InvalidFieldsDialog from "@/components/tickets/shared/InvalidFieldsDialog";
import { axiosWithRegularAuth, axiosWithNoAuth } from "@/plugins/axios";
import { cloneDeep, throttle } from "lodash";
import {
  mdiDotsVertical,
  mdiAlertCircle,
  mdiInformation,
  mdiLightningBoltCircle,
  mdiClose,
  mdiFile,
  mdiDelete,
} from "@mdi/js";
import { db } from "@/mixins/utilisync-db";
import { mapMutations } from "vuex";
import dependantValueMixin from "@/mixins/dependantValueMixin";
import checkIfOfflineTicketsSubmittedFinalMixin from "@/mixins/checkIfOfflineTicketsSubmittedFinalMixin";
import AlreadyClearedDialog from "@/components/shared/AlreadyClearedDialog";
import { v4 as uuidv4 } from "uuid";
import moment from "moment";
import DEFAULT_VALUES_TYPES from "@/constants/defaultValueTypes";
import networkStatusMixin from "@/mixins/networkStatusMixin";
import bulkDownloadDataMixin from "@/mixins/bulkDownloadDataMixin";
import downloadDataMixin from "@/mixins/downloadDataMixin";
import {
  checkItemVisibilityWithItem,
  checkItemVisibilityWithInfoItem,
} from "@/mixins/checkValues";
import {
  getOrgValue,
  getUserValue,
  getGisValue,
  getGisDataValue,
  getCurrentValue,
  getUrlValue,
  getSingleGisDataValue,
} from "@/mixins/getDefaultValues";
import esriTablesUtilibotsMixin from "@/mixins/esriTablesUtilibotsMixin";
import DeleteFormDialog from "@/components/tickets/ticket-edit-form/dynamic-form/dynamic-form-edit-actions/DeleteFormDialog";
import FormOfflineDialog from "@/components/tickets/ticket-edit-form/dynamic-form/FormOfflineDialog";
import FeatureServiceUnreachableDialog from "@/components/tickets/ticket-edit-form/dynamic-form/FeatureServiceUnreachableDialog";

const APIURL = process.env.VUE_APP_API_URL;
const APPLY_IF = {
  ANY: "ANY",
  ALL: "ALL",
};

const CONDITION_CHOICES = {
  ALWAYS: "ALWAYS",
  CONDITIONAL: "CONDITIONAL",
};

const FIELD_MATCH_CHOICE_VALUES = {
  EQUAL: "EQUAL",
  GREATER_THAN: "GREATER_THAN",
  GREATER_THAN_OR_EQUAL: "GREATER_THAN_OR_EQUAL",
  LESS_THAN: "LESS_THAN",
  LESS_THAN_OR_EQUAL: "LESS_THAN_OR_EQUAL",
  NOT_EQUAL: "NOT_EQUAL",
};

const checkApplyGisValue = ({
  condition,
  value,
  field,
  featureAttributes,
  allowMultiple,
}) => {
  if (condition === FIELD_MATCH_CHOICE_VALUES.EQUAL) {
    return getGisValue(field, featureAttributes, allowMultiple) === value;
  } else if (condition === FIELD_MATCH_CHOICE_VALUES.GREATER_THAN) {
    return +getGisValue(field, featureAttributes, allowMultiple) > +value;
  } else if (condition === FIELD_MATCH_CHOICE_VALUES.GREATER_THAN_OR_EQUAL) {
    return +getGisValue(field, featureAttributes, allowMultiple) >= +value;
  } else if (condition === FIELD_MATCH_CHOICE_VALUES.LESS_THAN) {
    return +getGisValue(field, featureAttributes, allowMultiple) < +value;
  } else if (condition === FIELD_MATCH_CHOICE_VALUES.LESS_THAN_OR_EQUAL) {
    return +getGisValue(field, featureAttributes, allowMultiple) <= +value;
  } else if (condition === FIELD_MATCH_CHOICE_VALUES.NOT_EQUAL) {
    return getGisValue(field, featureAttributes, allowMultiple) !== value;
  }
};

const checkApplyGisDataValue = ({ condition, value, field, gisDataValues }) => {
  if (condition === FIELD_MATCH_CHOICE_VALUES.EQUAL) {
    return getGisDataValue(field, gisDataValues) === value;
  } else if (condition === FIELD_MATCH_CHOICE_VALUES.GREATER_THAN) {
    return +getGisDataValue(field, gisDataValues) > +value;
  } else if (condition === FIELD_MATCH_CHOICE_VALUES.GREATER_THAN_OR_EQUAL) {
    return +getGisDataValue(field, gisDataValues) >= +value;
  } else if (condition === FIELD_MATCH_CHOICE_VALUES.LESS_THAN) {
    return +getGisDataValue(field, gisDataValues) < +value;
  } else if (condition === FIELD_MATCH_CHOICE_VALUES.LESS_THAN_OR_EQUAL) {
    return +getGisDataValue(field, gisDataValues) <= +value;
  } else if (condition === FIELD_MATCH_CHOICE_VALUES.NOT_EQUAL) {
    return getGisDataValue(field, gisDataValues) !== value;
  }
};

const checkApplyValue = ({
  type,
  field,
  condition,
  value,
  featureAttributes,
  gisDataValues,
  allowMultiple,
}) => {
  if (type === DEFAULT_VALUES_TYPES.GIS) {
    return checkApplyGisValue({
      condition,
      value,
      field,
      featureAttributes,
      allowMultiple,
    });
  } else if (type === DEFAULT_VALUES_TYPES.GIS_DATA) {
    return checkApplyGisDataValue({
      condition,
      value,
      field,
      gisDataValues,
    });
  }
};

const getShouldApplyDefaultValue = ({
  item,
  featureAttributes,
  gisDataValues,
}) => {
  if (
    !item?.question?.default?.applyDefault ||
    !item?.question?.default?.applyDefaultConditions ||
    item?.question?.default?.applyDefault === CONDITION_CHOICES.ALWAYS
  ) {
    return true;
  }
  const { applyIf, conditions } =
    item?.question?.default?.applyDefaultConditions ?? {};
  if (applyIf === APPLY_IF.ANY) {
    return conditions.some((c) => {
      const { type, field, condition, value } = c;
      const { allowMultiple } = item;
      const args = {
        type,
        field,
        condition,
        value,
        featureAttributes,
        gisDataValues,
        allowMultiple,
      };
      return checkApplyValue(args);
    });
  } else if (applyIf === APPLY_IF.ALL) {
    return conditions.every((c) => {
      const { type, field, condition, value } = c;
      const { allowMultiple } = item;
      const args = {
        type,
        field,
        condition,
        value,
        featureAttributes,
        gisDataValues,
        allowMultiple,
      };
      return checkApplyValue(args);
    });
  }
};

const checkCreateValue = ({
  rowItem,
  userDataValues,
  featureAttributes,
  gisDataValues,
  allowMultiple,
}) => {
  if (
    !rowItem?.question?.create ||
    rowItem?.question?.create?.condition === CONDITION_CHOICES.ALWAYS
  ) {
    return true;
  }

  const { dependentFieldId, dependent, value, condition } =
    rowItem?.question?.create ?? {};
  if (condition === FIELD_MATCH_CHOICE_VALUES.EQUAL) {
    if (dependent === DEFAULT_VALUES_TYPES.USER) {
      return (
        value === getUserValue(dependentFieldId, userDataValues) ||
        +value === +getUserValue(dependentFieldId, userDataValues)
      );
    } else if (dependent === DEFAULT_VALUES_TYPES.GIS) {
      return (
        value ===
          getGisValue(dependentFieldId, featureAttributes, allowMultiple) ||
        +value ===
          +getGisValue(dependentFieldId, featureAttributes, allowMultiple)
      );
    } else if (dependent === DEFAULT_VALUES_TYPES.GIS_DATA) {
      return (
        value === getGisDataValue(dependentFieldId, gisDataValues) ||
        +value === +getGisDataValue(dependentFieldId, gisDataValues)
      );
    }
  }
};

const checkCreateInfoValue = ({
  rowItem,
  userDataValues,
  featureAttributes,
  gisDataValues,
  allowMultiple,
}) => {
  if (
    !rowItem?.information?.create ||
    rowItem?.information?.create?.condition === CONDITION_CHOICES.ALWAYS
  ) {
    return true;
  }

  const { dependentFieldId, dependent, value, condition } =
    rowItem?.information?.create ?? {};
  if (condition === FIELD_MATCH_CHOICE_VALUES.EQUAL) {
    if (dependent === DEFAULT_VALUES_TYPES.USER) {
      return (
        value === getUserValue(dependentFieldId, userDataValues) ||
        +value === +getUserValue(dependentFieldId, userDataValues)
      );
    } else if (dependent === DEFAULT_VALUES_TYPES.GIS) {
      return (
        value ===
          getGisValue(dependentFieldId, featureAttributes, allowMultiple) ||
        +value ===
          +getGisValue(dependentFieldId, featureAttributes, allowMultiple)
      );
    } else if (dependent === DEFAULT_VALUES_TYPES.GIS_DATA) {
      return (
        value === getGisDataValue(dependentFieldId, gisDataValues) ||
        +value === +getGisDataValue(dependentFieldId, gisDataValues)
      );
    }
  }
};

const CITYWORKS = {
  OF: "of",
  RS: "rs",
};

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    SignaturePad,
    TextInput,
    PhotoInput,
    FileInput,
    SingleSelectInput,
    MultiSelectInput,
    CalculationInput,
    DateInput,
    TimeInput,
    RepeatingGroup,
    ActionItem,
    AlreadyClearedDialog,
    DynamicFormEditActions,
    DynamicFormNormalActions,
    InformationItem,
    FormInfoDialog,
    InvalidFieldsDialog,
    ErrorConnectingToGisFeatureDialog,
    DeleteFormDialog,
    FormOfflineDialog,
    FeatureServiceUnreachableDialog,
  },
  mixins: [
    dependantValueMixin,
    checkIfOfflineTicketsSubmittedFinalMixin,
    networkStatusMixin,
    bulkDownloadDataMixin,
    downloadDataMixin,
    esriTablesUtilibotsMixin,
  ],
  props: {
    formDefinition: {
      type: Object,
      default() {
        return {
          form: {
            formDescription: { title: "" },
            sections: [{ items: [] }],
          },
        };
      },
    },
    selectedGisInfo: Object,
    existingFormResultIdMap: Object,
    canEdit: {
      type: Boolean,
      default: true,
    },
    alreadySubmittedFinalOnline: {
      type: Boolean,
      default: false,
    },
    submittedFinalTicketOffline: Boolean,
    selectedMapServiceId: String,
    objectId: Number,
    globalId: String,
    taskId: String,
    selectedPdfFileUrl: String,
  },
  data() {
    return {
      photoUploading: false,
      isOnline: navigator.onLine,
      lastSavedTimes: {},
      mdiDotsVertical,
      mdiAlertCircle,
      mdiInformation,
      mdiLightningBoltCircle,
      mdiClose,
      mdiFile,
      mdiDelete,
      isSaving: false,
      openSections: [],
      computedFormDefinition: {},
      formResultIdMap: undefined,
      showAlreadyClearedDialog: false,
      ticketNumbersAlreadySubmitted: [],
      formResultIdsAlreadySubmitted: [],
      ticketNumbersAlreadySubmittedSubmitFinal: [],
      formResultIdsAlreadySubmittedSubmitFinal: [],
      savedComputedFormDefinition: JSON.stringify({}),
      showFormInfoDialog: false,
      showInvalidFieldsDialog: false,
      fieldValidationResult: {},
      isEditingFinalForm: false,
      gisInfoQueryResult: undefined,
      showErrorConnectingToGisFeatureDialog: false,
      selectedMapService: {},
      showForm: false,
      prepopulatedValues: false,
      errorConnectingToGisFeatureDialogNeverAppeared: false,
      intervalId: undefined,
      changesMade: false,
      gisDataValues: [],
      submittingFinalForm: false,
      isUpdatingFeatureService: false,
      isAddingToRelatedTables: false,
      isUpdatingDataAfterSubmit: false,
      showDeleteFormDialog: false,
      featureAttributes: {},
      showFormOfflineDialog: false,
      showFeatureServiceUnreachableDialog: false,
      fullRequestUrl: "",
      formLoading: true,
      CITYWORKS,
      contentHeight: 0,
    };
  },
  computed: {
    cityworksActivityId() {
      return this.computedFormDefinition?.form?.cityworks
        ?.cityworks_activity_id;
    },
    editableAfterFinal() {
      return this.computedFormDefinition?.form?.formDescription
        .canEditAfterFinal;
    },
    formDescription() {
      return this.computedFormDefinition?.form?.formDescription ?? {};
    },
    formResultId() {
      return this.formResultIdMap?.formResultId;
    },
    invalidFields() {
      return this.invalidFieldSections?.map((s) => s.items).flat();
    },
    invalidFieldSections() {
      const invalidFieldIds = Object.entries(this.fieldValidationResult)
        .filter(([, val]) => !val.valid)
        .map(([key]) => +key);
      const invalidSections = cloneDeep(this.formDefinition?.form?.sections);
      if (Array.isArray(invalidSections)) {
        for (const section of invalidSections) {
          section.items = section.items.filter((i) =>
            invalidFieldIds.includes(+i.id)
          );
        }
      }
      const invalidSectionsWithoutEmpty = invalidSections?.filter(
        ({ items }) => items.length > 0
      );
      return invalidSectionsWithoutEmpty ?? [];
    },
  },
  methods: {
    async getFormResult() {
      if (this.formResultId && navigator.onLine) {
        let formResult;
        try {
          const { data: onlineFormResult } = await axiosWithRegularAuth.get(
            `${APIURL}/form_results/${this.formResultId}`
          );
          formResult = onlineFormResult;
        } catch (error) {
          const [offlineFormResult] = await db.formResults
            .filter((f) => f.form_result_id === this.formResultId)
            .toArray();
          formResult = offlineFormResult;
          this.setNotFoundError(true);
        }
        this.computedFormDefinition.form.cityworks = {
          ...this.computedFormDefinition.form.cityworks,
          cityworks_activity_id:
            formResult?.form?.cityworks?.cityworks_activity_id,
        };
      }
    },
    async openCityworksActivity(type) {
      const {
        data: { results },
      } = await axiosWithRegularAuth.get(`${APIURL}/cityworks/user_group_info`);
      if (
        this.computedFormDefinition?.form?.city_works_webhook_config?.type ===
        "Inspection"
      ) {
        let url;
        if (type === CITYWORKS.OF) {
          url = `${results.api_url.replace(
            /\/Services/i,
            ""
          )}WorkManagement/InspectionEdit.aspx?InspectionId=${
            this.cityworksActivityId
          }`;
        } else if (type === CITYWORKS.RS) {
          url = `${results.api_url.replace(
            /\/Services/i,
            ""
          )}apps/respond/inspection/edit/${this.cityworksActivityId}`;
        }
        window.open(url, "_blank");
      } else if (
        this.computedFormDefinition?.form?.city_works_webhook_config?.type ===
        "workorder"
      ) {
        let url;
        if (type === CITYWORKS.OF) {
          url = `${results.api_url.replace(
            /\/Services/i,
            ""
          )}WorkManagement/WOGeneralEdit.aspx?WorkOrderId=${
            this.cityworksActivityId
          }`;
        } else if (type === CITYWORKS.RS) {
          url = `${results.api_url.replace(
            /\/Services/i,
            ""
          )}apps/respond/work-order/edit/${this.cityworksActivityId}`;
        }
        window.open(url, "_blank");
      }
    },
    isSectionVisible(section) {
      if (
        !section.visible ||
        !section.visible?.condition ||
        section.visible?.condition === "ALWAYS"
      ) {
        return true;
      }
      if (section.visible.condition === "NEVER") {
        return false;
      }
      const { sections } = { ...this.computedFormDefinition.form };
      const flattenedItems = sections.map(({ items }) => items).flat();
      const dependantItem = flattenedItems.find(
        (item) => item.id === section.visible.dependantId
      );
      const dependantItemValue = dependantItem?.value;
      if (Array.isArray(dependantItemValue)) {
        if (dependantItemValue.length > 1) {
          return false;
        } else {
          const [dependantValue] = dependantItemValue;
          return this.checkSectionDependantValue(
            dependantValue,
            section,
            "visible"
          );
        }
      } else {
        return this.checkSectionDependantValue(
          dependantItemValue,
          section,
          "visible"
        );
      }
    },
    async onCloseButtonClick() {
      await this.$nextTick();
      sessionStorage.removeItem("formResultId");
      if (!this.alreadySubmittedFinalOnline) {
        this.$emit("ticket-edit-form-close-button-click", this.changesMade);
      } else {
        this.$emit("ticket-edit-form-close-button-click", false);
      }
    },
    onFieldValidated(result) {
      const { id, ...restResult } = result;
      this.$set(this.fieldValidationResult, id, restResult);
      this.onInput();
    },
    async onInput() {
      await this.$nextTick();
      this.changesMade = true;
      this.$emit("input", this.computedFormDefinition);
    },
    async deleteFormResult() {
      if (!this.formResultId) {
        this.$emit("ticket-edit-form-close");
        return;
      }
      if (navigator.onLine) {
        await axiosWithRegularAuth.delete(
          `${APIURL}/form_results/${this.formResultId}`
        );
      }
      const lastSavedTimes = { ...this.lastSavedTimes };
      delete lastSavedTimes[this.formResultId];
      localStorage.setItem("last-saved-times", JSON.stringify(lastSavedTimes));
      await this.deleteFormResultFromIndexedDb();
      sessionStorage.removeItem("formResultId");
      this.$emit("ticket-edit-form-close");
      const { formResultId } = this;
      this.setFormDeleted({
        isFormDeleted: true,
        formResultId,
      });
    },
    isRequired(item) {
      const { sections } = { ...this.computedFormDefinition.form };
      for (const section of sections) {
        for (const dependantItem of section.items) {
          if (+dependantItem.id === +item.question.required.dependantId) {
            const dependantItemValue = dependantItem.value;
            if (Array.isArray(dependantItemValue)) {
              if (dependantItemValue.length > 1) {
                return false;
              } else {
                const [dependantValue] = dependantItemValue;
                return this.checkDependantValue(dependantValue);
              }
            } else {
              return this.checkDependantValue(dependantItemValue);
            }
          }
        }
      }
      return item.question.required.condition === "ALWAYS";
    },
    isVisible(item) {
      if (item.question) {
        return this.checkVisibility(item, "question");
      } else {
        return this.checkVisibility(item, "information");
      }
    },
    checkVisibility(item, type = "question") {
      if (
        item[type].visible?.condition === "ALWAYS" ||
        item[type].visible?.applyVisible === "ALWAYS"
      ) {
        return true;
      }

      if (
        item[type].visible?.condition === "NEVER" ||
        item[type].visible?.applyVisible === "NEVER"
      ) {
        return false;
      }

      if (item?.[type]?.visible?.condition) {
        const { sections } = { ...this.computedFormDefinition.form };
        const items = sections.map((s) => s.items).flat();
        const dependantItem = items.find((it) => {
          return +it.id === +item[type].visible.dependantId;
        });
        const dependantItemValue = dependantItem?.value;

        if (Array.isArray(dependantItemValue)) {
          if (dependantItemValue.length > 1) {
            return false;
          } else {
            const [dependantValue] = dependantItemValue;
            return this.checkDependantValueWithItem(
              item,
              dependantValue,
              type,
              "visible"
            );
          }
        } else {
          return this.checkDependantValueWithItem(
            item,
            dependantItemValue,
            type,
            "visible"
          );
        }
      } else {
        const { featureAttributes, gisDataValues } = this;
        const { sections } = { ...this.computedFormDefinition.form };
        const dependantItems = sections.map((s) => s.items).flat();
        if (type === "question") {
          return checkItemVisibilityWithItem({
            item,
            featureAttributes,
            dependantItems,
            gisDataValues,
          });
        } else {
          return checkItemVisibilityWithInfoItem({
            item,
            featureAttributes,
            dependantItems,
            gisDataValues,
          });
        }
      }
    },
    isQuestion(item) {
      return Object.prototype.hasOwnProperty.call(item, "question");
    },
    async submit() {
      const success = await this.$refs.form.validate();
      if (!success) {
        this.showInvalidFieldsDialog = true;
        return;
      }
      try {
        this.submittingFinalForm = true;
        await this.saveToIndexedDb("SUBMITTED_FINAL");
        if (navigator.onLine) {
          this.isSaving = true;
          clearInterval(this.intervalId);
          await this.save("SUBMITTED_FINAL");
          this.isSaving = false;
          await this.updateFeatureService();
          await this.addToRelatedTableFromFormResult();
          this.isUpdatingDataAfterSubmit = true;
          await this.checkIfOfflineTicketsSubmittedFinal();
          await this.deleteFormResultFromIndexedDb();
          await this.getNumUnsubmittedTickets();
          this.isUpdatingDataAfterSubmit = false;
        }
        sessionStorage.removeItem("formResultId");
      } catch (error) {
        await this.saveToIndexedDb("SUBMITTED_FINAL", error);
        if (navigator.onLine) {
          await this.deleteFormResultFromIndexedDb();
        }
        if (Array.isArray(error?.response?.data?.error)) {
          const [errorMsg] = error?.response?.data?.error;
          this.$emit("ticket-edit-form-error", errorMsg);
        } else {
          if (error?.response?.data?.error) {
            this.$emit("ticket-edit-form-error", error?.response?.data?.error);
          }
        }
      } finally {
        this.isSaving = false;
        const { formResultId } = this;
        this.setFormSubmitted({ isFormSubmitted: true, formResultId });
        this.getNumUnsubmittedTickets();
        await this.deleteTaskById(this.taskId);
        this.submittingFinalForm = false;
        this.$emit("ticket-edit-form-submitted", this.taskId);
      }
    },
    async deleteTaskById(taskId) {
      if (!taskId) {
        return;
      }
      let existingTask;
      try {
        const {
          data: { results },
        } = await axiosWithRegularAuth.get(`${APIURL}/tasks/${taskId}`);
        existingTask = results;
      } catch (error) {
        console.log(error);
      }
      try {
        if (existingTask) {
          await axiosWithRegularAuth.put(`${APIURL}/tasks/${taskId}`, {
            ...existingTask,
            status: "Closed",
          });
        }
        await db.tasks.filter((t) => t.task_id === taskId).delete();
      } catch (error) {
        console.log(error);
      }
    },
    async prepareRepeatingGroupPayload(repeatingGroupValues) {
      const valuesArr = cloneDeep(repeatingGroupValues);
      if (!Array.isArray(valuesArr)) {
        return [];
      }
      for (const [arrIndex, values] of valuesArr?.entries()) {
        for (const [index, item] of values?.entries()) {
          if (
            typeof item.question === "object" &&
            item.question !== null &&
            ["FILE"].includes(item.question.type)
          ) {
            valuesArr[arrIndex][index] = await this.addFiles(item);
          }
        }
      }
      return valuesArr;
    },
    async createPayload(computedFormDefinition, status) {
      for (const section of computedFormDefinition?.form?.sections ?? []) {
        for (const [index, item] of section.items.entries()) {
          if (typeof item.question !== "object" || item.question === null) {
            continue;
          }

          if (["FILE"].includes(item.question.type)) {
            section.items[index] = await this.addFiles(item);
          }

          if (["GROUP"].includes(item.question.type)) {
            section.items[index].value =
              await this.prepareRepeatingGroupPayload(item.value);
          }

          if (["ACTION_ITEM"].includes(item.question.type)) {
            if (!section.items[index].value?.newActionItems) {
              section.items[index].value = {
                ...section.items[index].value,
                newActionItems: [],
              };
            }
          }

          if (
            ["TEXT", "EMAIL", "NUMBER"].includes(item.question.type) &&
            item.allowMultiple
          ) {
            if (Array.isArray(item.value)) {
              const { id: parentId } = item;
              const [firstVal, ...restVals] = item.value;
              section.items[index] = {
                ...section.items[index],
                value: firstVal,
                children: restVals.map((r, i) => {
                  return {
                    ...section.items[index],
                    value: r,
                    id: `${parentId}.${i + 1}`,
                  };
                }),
              };
            }
          }
        }
      }

      const { featureAttributes } = this;
      const { form_definition_id: formDefinitionId, form } =
        computedFormDefinition;
      const sections = cloneDeep(form.sections);
      const {
        form: formDefinition,
        html_merge_definition_version: htmlMergeDefinitionVersion,
        html_merge_definition: htmlMergeDefinition,
      } = this.formDefinition;

      const payload = {
        form_definition_id: formDefinitionId,
        feature_attributes: featureAttributes,
        feature_id: this.objectId,
        task_id: this.taskId,
        form_values: { sections },
        status,
        form_definition: formDefinition,
        html_merge_definition_version: htmlMergeDefinitionVersion,
        html_merge_definition: htmlMergeDefinition,
      };
      return payload;
    },
    async deleteFormResultFromIndexedDb() {
      await db.formResults
        .where("form_result_id")
        .equals(this.formResultId)
        .delete();
    },
    async saveToIndexedDb(status = "IN_PROCESS") {
      if (!this.canEdit) {
        return;
      }

      const { globalId } = this;
      if (!this.formResultIdMap) {
        if (this.existingFormResultIdMap) {
          this.formResultIdMap = { ...this.existingFormResultIdMap };
        } else {
          this.formResultIdMap = {
            globalId,
            formResultId: uuidv4(),
          };
        }
      }
      const computedFormDefinition = cloneDeep(this.computedFormDefinition);
      const payload = await this.createPayload(
        cloneDeep(computedFormDefinition),
        status
      );

      const formResult = {
        form_result_id: this.formResultId,
        payload,
        computed_form_definition: computedFormDefinition,
        updatedOn: new Date(),
      };

      await db.formResults.put(formResult);
      const lastSavedTimes = {
        ...this.lastSavedTimes,
        [this.formResultId]: new Date(),
      };
      localStorage.setItem("last-saved-times", JSON.stringify(lastSavedTimes));
    },
    async save(status) {
      if (!this.canEdit) {
        return;
      }

      try {
        this.lastSavedTimes =
          JSON.parse(localStorage.getItem("last-saved-times")) || {};
      } catch (error) {
        this.lastSavedTimes = {};
      }
      this.isSaving = true;
      const { computedFormDefinition } = this;

      if (!computedFormDefinition.form) {
        return;
      }
      const { globalId } = this;
      if (!this.formResultIdMap) {
        if (this.existingFormResultIdMap) {
          this.formResultIdMap = { ...this.existingFormResultIdMap };
        } else {
          this.formResultIdMap = {
            globalId,
            formResultId: uuidv4(),
          };
        }
      }

      const payload = await this.createPayload(
        cloneDeep(computedFormDefinition),
        status
      );
      const formResult = {
        form_result_id: this.formResultId,
        payload,
        computed_form_definition: computedFormDefinition,
        updatedOn: new Date(),
      };
      await db.formResults.put(formResult);
      try {
        await axiosWithRegularAuth.put(
          `${APIURL}/form_result_values/${this.formResultId}`,
          payload
        );
      } catch (error) {
        const defaultProps = {
          formDefinition: {
            form: {
              formDescription: { title: "" },
              sections: [{ items: [] }],
            },
          },
          locateRequestId: "",
          existingFormResultIdMap: {},
          canEdit: true,
          alreadySubmittedFinalOnline: false,
          selectedPdfFileUrl: "",
          globalId: "",
          objectId: 0,
          selectedMapServiceId: "",
        };
        if (error?.response?.data?.status === 400) {
          this.setDynamicFormProps(defaultProps);
        } else if ([403].includes(error?.response?.data?.status)) {
          this.setForbiddenError(error?.response?.data?.error);
          this.setDynamicFormProps(defaultProps);
        } else if ([404].includes(error?.response?.data?.status)) {
          this.setCityworksError(error);
          this.setDynamicFormProps(defaultProps);
        }
      }

      const lastSavedTimes = {
        ...this.lastSavedTimes,
        [this.formResultId]: new Date(),
      };
      localStorage.setItem("last-saved-times", JSON.stringify(lastSavedTimes));

      try {
        this.lastSavedTimes =
          JSON.parse(localStorage.getItem("last-saved-times")) || {};
      } catch (error) {
        this.lastSavedTimes = {};
      }
      this.isSaving = false;
      this.changesMade = false;
    },
    autoSave: throttle(async function () {
      if (!this.canEdit || this.submittingFinalForm) {
        return;
      }

      if (!this.alreadySubmittedFinalOnline) {
        await this.saveToIndexedDb();
      }

      if (navigator.onLine) {
        if (!this.alreadySubmittedFinalOnline) {
          this.save("IN_PROCESS");
        }
      }
      this.changesMade = false;
    }, 5000),
    async getNumUnsubmittedTickets() {
      const formResults = await db.formResults
        .filter((f) => f.payload?.status === "SUBMITTED_FINAL")
        .toArray();
      this.setNumUnsubmittedTickets(formResults.length);
    },
    async addFiles(item) {
      if (
        !item.value ||
        (typeof item.value === "object" &&
          item.value !== null &&
          !Array.isArray(item.value) &&
          Array.isArray(item.children))
      ) {
        return { ...item };
      }

      const { id: parentId } = item;
      const filePromises = item.value.map((f) => {
        const {
          file_size_kb: fileSizeKb,
          id,
          name,
          url,
          dataUrl,
          fileName,
          fileType,
          description,
        } = f;
        return {
          file_size_kb: fileSizeKb,
          id,
          name,
          url,
          dataUrl,
          fileName,
          fileType,
          description,
        };
      });

      const files = await Promise.all(filePromises);
      const mappedFiles = files.map(({ id, url, description }) => ({
        id,
        url,
        description,
      }));
      const [firstFile, ...restFiles] = mappedFiles;
      return {
        ...item,
        value: firstFile,
        children: restFiles.map((r, i) => {
          const { children, ...restItem } = item;
          return {
            ...restItem,
            value: r,
            id: `${parentId}.${i + 1}`,
          };
        }),
      };
    },
    transformRepeatingGroup(repeatingGroup) {
      if (!Array.isArray(repeatingGroup?.value)) {
        return [];
      }
      const repeatingGroupValuesCopy = cloneDeep(repeatingGroup.value);
      for (const valueArr of repeatingGroupValuesCopy) {
        for (const item of valueArr) {
          if (item?.question?.type === "FILE") {
            if (
              typeof item.value === "object" &&
              item.value !== null &&
              !Array.isArray(item.value)
            ) {
              const { value, children = [] } = item;
              item.value = [value, ...children.map((c) => c.value)];
            }
          }
        }
      }
      return repeatingGroupValuesCopy;
    },
    async transformFormDefinition(formDefinition) {
      const computedFormDefinition = cloneDeep(formDefinition);
      for (const section of computedFormDefinition?.form?.sections ?? []) {
        for (const item of section.items) {
          if (this.isQuestion(item)) {
            if (item?.question?.type === "FILE") {
              if (
                typeof item.value === "object" &&
                item.value !== null &&
                !Array.isArray(item.value)
              ) {
                const { value, children = [] } = item;
                item.value = [value, ...children.map((c) => c.value)];
              }
            } else if (item?.question?.type === "GROUP") {
              item.value = this.transformRepeatingGroup(item);
            } else if (item?.question?.type === "DATE") {
              if (
                typeof item?.value !== "undefined" &&
                moment(item?.value).isValid()
              ) {
                const date = moment(item?.value);
                item.value = date.format("YYYY-MM-DD");
              }
            } else if (
              ["TEXT", "EMAIL", "NUMBER"].includes(item?.question?.type) &&
              item.allowMultiple
            ) {
              const { value, children } = item;
              if (Array.isArray(children)) {
                item.value = [value, ...children.map((c) => c.value)];
              }
            }
          }
        }
      }
      this.computedFormDefinition = computedFormDefinition;
    },
    async getGisDataFieldsAndValuesOffline() {
      const { selectedMapServiceId, objectId } = this;
      const gisDataValues = await db.gisDataValues.toCollection().toArray();
      const gisDataValueIds = gisDataValues.map((g) => g.gis_data_field_id);
      const gisDataFields = await db.gisDataFields
        .filter((g) => {
          return (
            g.map_service_id === selectedMapServiceId &&
            !gisDataValueIds.includes(objectId)
          );
        })
        .toArray();
      return gisDataFields?.map((gdf) => {
        const { gis_data_field_id: gisDataFieldId } = gdf;
        const gisDataValue = gisDataValues?.find((gdv) => {
          return (
            gdv?.gis_data_field_id === gdf?.gis_data_field_id &&
            +gdv?.feature_id === +objectId
          );
        });
        const value = gisDataValue?.value;
        const gisDataValueId = gisDataValue?.gis_data_value_id;
        return {
          feature_id: objectId,
          gis_data_field_id: gisDataFieldId,
          gis_data_value_id: gisDataValueId,
          value,
        };
      });
    },
    async getUserDataFieldsAndValuesOffline() {
      return await db.userDataValues.toCollection().toArray();
    },
    async prePopulateValues(formDefinition) {
      try {
        if (!this.alreadySubmittedFinalOnline) {
          if (this.isOnline) {
            await this.save("IN_PROCESS");
          }
        }
        const computedFormDefinition = cloneDeep(formDefinition);
        const { globalId } = this;

        if (this.isOnline) {
          const { formResultId } = this.formResultIdMap ?? {};
          if (!formResultId) {
            this.prepopulatedValues = true;
            return;
          }
        } else {
          if (!this.formResultIdMap) {
            if (this.existingFormResultIdMap) {
              this.formResultIdMap = { ...this.existingFormResultIdMap };
            } else {
              this.formResultIdMap = {
                globalId,
                formResultId: uuidv4(),
              };
            }
          }
        }

        const { formResultId } = this.formResultIdMap;
        if (this.isOnline) {
          const {
            data: { results },
          } = await axiosWithRegularAuth.get(`${APIURL}/user_data_values`);
          await db.userDataValues.bulkPut(results);
        }
        const gisDataValues = await this.getGisDataFieldsAndValuesOffline();
        const userDataValues = await this.getUserDataFieldsAndValuesOffline();
        this.gisDataValues = gisDataValues;
        const { featureAttributes, gisInfoQueryResult } = this;

        for (const section of computedFormDefinition.form.sections) {
          for (const item of section.items) {
            if (this.isQuestion(item)) {
              const { type, value } = item?.question?.default ?? {};
              if (item.question.type !== "GROUP") {
                if (!item.value) {
                  if (
                    !getShouldApplyDefaultValue({
                      item,
                      featureAttributes,
                      gisDataValues,
                    })
                  ) {
                    continue;
                  }

                  if (type === DEFAULT_VALUES_TYPES.ORGANIZATION) {
                    item.value = getOrgValue();
                  } else if (type === DEFAULT_VALUES_TYPES.USER) {
                    item.value = getUserValue(value, userDataValues);
                  } else if (type === DEFAULT_VALUES_TYPES.CUSTOM) {
                    if (Array.isArray(value)) {
                      const allStrings = value.every(
                        (v) => typeof v === "string"
                      );
                      if (allStrings) {
                        item.value = value.map((v) => {
                          return {
                            key: v,
                            val: v,
                          };
                        });
                      } else {
                        item.value = value;
                      }
                    } else {
                      item.value = value;
                    }
                  } else if (type === DEFAULT_VALUES_TYPES.GIS) {
                    const { allowMultiple } = item;
                    item.value = getGisValue(
                      value,
                      featureAttributes,
                      allowMultiple,
                      gisInfoQueryResult
                    );
                  } else if (type === DEFAULT_VALUES_TYPES.GIS_DATA) {
                    const { allowMultiple } = item;
                    if (allowMultiple) {
                      item.value = getGisDataValue(value, gisDataValues);
                    } else {
                      item.value = getSingleGisDataValue(value, gisDataValues);
                    }
                  } else if (type === DEFAULT_VALUES_TYPES.CURRENT) {
                    item.value = getCurrentValue(item, value);
                  } else if (type === DEFAULT_VALUES_TYPES.URL) {
                    item.value = await getUrlValue(value, formResultId, item);
                  }
                }
              } else {
                if (
                  !getShouldApplyDefaultValue({
                    item,
                    featureAttributes,
                    gisDataValues,
                  })
                ) {
                  continue;
                }

                if (!Array.isArray(item.value) || item.value.length === 0) {
                  const { groupedItems, default: defaultSetting } =
                    item.question ?? {};
                  const { type, value } = defaultSetting ?? {};
                  if (type === DEFAULT_VALUES_TYPES.CUSTOM) {
                    item.value = cloneDeep(value).filter((r) => {
                      const [rowItem] = r;
                      const { allowMultiple } = item;
                      if (rowItem?.question) {
                        return checkCreateValue({
                          rowItem,
                          userDataValues,
                          featureAttributes,
                          gisDataValues,
                          allowMultiple,
                        });
                      } else if (rowItem?.information) {
                        return checkCreateInfoValue({
                          rowItem,
                          userDataValues,
                          featureAttributes,
                          gisDataValues,
                          allowMultiple,
                        });
                      }
                    });
                  } else {
                    try {
                      const gisDataValue = getGisDataValue(
                        value,
                        gisDataValues
                      );
                      if (gisDataValue) {
                        item.value = JSON.parse(gisDataValue);
                      }
                    } catch (error) {
                      console.log(error);
                      continue;
                    }

                    if (!Array.isArray(item.value)) {
                      continue;
                    }

                    for (const row of item.value) {
                      for (const rowItem of row) {
                        const groupedItem = groupedItems.find(
                          (gIt) => gIt.id === rowItem.id
                        );
                        const defaultSettings =
                          groupedItem?.question?.default ?? {};
                        if (
                          Object.keys(defaultSettings).includes(
                            "applyDefaultValue"
                          ) &&
                          !defaultSettings.applyDefaultValue
                        ) {
                          rowItem.value = undefined;
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }

        for (const section of computedFormDefinition?.form?.sections ?? []) {
          for (const item of section.items) {
            if (this.isQuestion(item)) {
              if (item?.question?.type === "FILE") {
                if (
                  typeof item.value === "object" &&
                  item.value !== null &&
                  !Array.isArray(item.value)
                ) {
                  const { value, children = [] } = item;
                  item.value = [value, ...children.map((c) => c.value)];
                }
              } else if (item?.question?.type === "GROUP") {
                item.value = this.transformRepeatingGroup(item);
              } else if (item?.question?.type === "DATE") {
                if (
                  typeof item?.value !== "undefined" &&
                  moment(item?.value).isValid()
                ) {
                  const date = moment(item?.value);
                  item.value = date.format("YYYY-MM-DD");
                }
              }
            }
          }
        }
        this.computedFormDefinition = { ...computedFormDefinition };
      } catch (error) {
        console.log(error);
      } finally {
        this.prepopulatedValues = true;
      }
    },
    async getFeature() {
      const { selectedMapServiceId, objectId } = this;
      const [selectedMapService] = await db.mapServices
        .filter((m) => m.map_service_id === selectedMapServiceId)
        .toArray();
      this.selectedMapService = selectedMapService;
      if (selectedMapService?.service_type === "F") {
        if (navigator.onLine) {
          let config = {};
          try {
            let params;
            if (selectedMapService?.token_type === "NONE") {
              params = {
                objectids: objectId,
                outFields: "*",
                f: "json",
              };
            } else {
              params = {
                objectids: objectId,
                outFields: "*",
                f: "json",
                token: localStorage.getItem("esri_token"),
              };
            }
            const { data: queryResult, config: requestConfig } =
              await axiosWithNoAuth.get(
                `${selectedMapService.service_url}/query`,
                {
                  params,
                }
              );
            this.gisInfoQueryResult = queryResult;
            config = requestConfig;
            const { gisInfoQueryResult } = this;
            const [feature] = gisInfoQueryResult?.features ?? [];
            this.featureAttributes = {
              ...feature?.attributes,
            };

            if (queryResult?.error) {
              this.showFeatureServiceUnreachableDialog = true;
            }
          } catch (error) {
            this.showFeatureServiceUnreachableDialog = true;
          } finally {
            const { params, url } = config;
            if (
              /(http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-/]))?/.test(
                url
              )
            ) {
              const searchParams = new URLSearchParams(params);
              const fullRequestUrl = new URL(url);
              for (const [key, val] of searchParams) {
                fullRequestUrl.searchParams.append(key, val);
              }

              this.fullRequestUrl = fullRequestUrl.toString();
            }
          }
        }

        if (
          this.gisInfoQueryResult?.error &&
          !this.errorConnectingToGisFeatureDialogNeverAppeared
        ) {
          this.showErrorConnectingToGisFeatureDialog = true;
          this.errorConnectingToGisFeatureDialogNeverAppeared = true;
        }
      } else if (selectedMapService?.service_type === "U") {
        const { objectId, selectedMapServiceId } = this;
        const mapServices = await db.mapServices.toCollection().toArray();
        const gisDataValues = await db.gisDataValues.toCollection().toArray();
        const allGisDataFields = await db.gisDataFields
          .toCollection()
          .toArray();
        const gisDataValueIds =
          gisDataValues?.map((g) => g.gis_data_field_id) ?? [];
        const gisDataFields = allGisDataFields.filter((g) => {
          return (
            g.map_service_id === selectedMapServiceId &&
            !gisDataValueIds.includes(objectId)
          );
        });

        const gisDataFieldsAndValues = gisDataFields?.map((gdf) => {
          const { name } = gdf;
          const value = gisDataValues?.find((gdv) => {
            return (
              gdv?.gis_data_field_id === gdf?.gis_data_field_id &&
              gdv?.feature_id === objectId
            );
          })?.value;

          return {
            name,
            value,
          };
        });
        const mapService = mapServices.find(
          (m) => m.map_service_id === selectedMapServiceId
        );
        const { ref_field: refField } = mapService ?? {};
        const refFieldValue = gisDataFieldsAndValues?.find(
          (f) => f.name === refField
        )?.value;
        const refFieldObj = refField ? { [refField]: refFieldValue } : {};
        const [gisDataPoint] = await db.gisDataPoints
          .filter(
            (gdp) =>
              gdp.map_service_id === selectedMapServiceId &&
              gdp.object_id === objectId
          )
          .toArray();
        this.featureAttributes = {
          OBJECTID: objectId,
          gisDataPointId: gisDataPoint?.gis_data_point_id,
          ...refFieldObj,
        };
      }
    },
    async onResize() {
      await this.$nextTick();
      if (window.innerHeight < 500 || window.innerWidth < 500) {
        this.contentHeight =
          window.innerHeight -
          (this.$refs.toolbar?.$el?.clientHeight ?? 0) -
          70;
      } else {
        this.contentHeight =
          window.innerHeight -
          (this.$refs.toolbar?.$el?.clientHeight ?? 0) -
          (this.$refs.cardAction?.$el?.clientHeight ?? 50) -
          130;
      }
    },
    async validateCityworksToken() {
      if (!navigator.onLine) {
        return;
      }
      const auth = JSON.parse(localStorage.getItem("auth"));
      const { cityworks_token: cityworksToken } = auth ?? {};
      if (!cityworksToken) {
        return false;
      }
      try {
        const {
          data: { error },
        } = await axiosWithRegularAuth.get(
          `${APIURL}/cityworks/validate_token`,
          {
            params: {
              cityworks_token: cityworksToken,
            },
          }
        );
        return !error;
      } catch (error) {
        return false;
      }
    },
    ...mapMutations([
      "setNumUnsubmittedTickets",
      "setFormSubmitted",
      "setFormDeleted",
      "setDynamicFormProps",
      "setNotFoundError",
      "setCityworksError",
      "setForbiddenError",
    ]),
  },
  async mounted() {
    if (this.formDefinition?.city_works_webhook_config?.enabled) {
      const isCityworksTokenValid = await this.validateCityworksToken();
      if (!isCityworksTokenValid) {
        // eslint-disable-next-line no-unused-vars
        const { cityworks_token, ...newAuth } = this.auth;
        localStorage.setItem("auth", JSON.stringify(newAuth));
      }
    }
    this.formLoading = true;
    try {
      this.lastSavedTimes =
        JSON.parse(localStorage.getItem("last-saved-times")) || {};
    } catch (error) {
      this.lastSavedTimes = {};
    }
    this.showForm = false;
    await this.getFeature();
    if (!navigator.onLine) {
      const { selectedMapServiceId } = this;
      const [selectedMapService] = await db.mapServices
        .filter((m) => m.map_service_id === selectedMapServiceId)
        .toArray();
      if (selectedMapService?.service_type !== "U") {
        this.showFormOfflineDialog = true;
        return;
      }
    }
    await this.$nextTick();
    await this.transformFormDefinition(this.formDefinition);
    if (this.formDefinition.status !== "SUBMITTED_FINAL") {
      await this.prePopulateValues(this.computedFormDefinition);
    }

    await this.getFormResult();
    this.showForm = true;
    this.openSections = [
      ...(this.computedFormDefinition?.form?.sections?.map((_, i) => i) ?? []),
    ];
    await this.$nextTick();
    this.showForm = false;
    this.openSections = [
      ...(this.computedFormDefinition?.form?.sections
        ?.map(({ isOpenByDefault }, index) => ({ isOpenByDefault, index }))
        ?.filter(({ isOpenByDefault }) => isOpenByDefault)
        ?.map(({ index }) => index) ?? []),
    ];
    await this.$nextTick();
    this.showForm = true;
    this.savedComputedFormDefinition = JSON.stringify(
      this.computedFormDefinition
    );
    this.formLoading = false;
    this.onResize();
  },
  async beforeMount() {
    window.addEventListener("resize", this.onResize);
    if (this.formDefinition?.city_works_webhook_config?.enabled) {
      const isCityworksTokenValid = await this.validateCityworksToken();
      if (!isCityworksTokenValid) {
        // eslint-disable-next-line no-unused-vars
        const { cityworks_token, ...newAuth } = this.auth;
        localStorage.setItem("auth", JSON.stringify(newAuth));
        return;
      }
    }
    this.intervalId = setInterval(() => {
      if (this.changesMade && !this.submittingFinalForm) {
        this.autoSave();
      }
    }, 15000);
  },
  beforeDestroy() {
    clearInterval(this.intervalId);
    window.removeEventListener("resize", this.onResize);
  },
  watch: {
    existingFormResultIdMap: {
      deep: true,
      immediate: true,
      handler(val) {
        if (val) {
          this.prepopulatedValues = true;
          this.formResultIdMap = val;
        }
      },
    },
    formResultId: {
      immediate: true,
      handler(currVal, oldVal) {
        if (currVal && currVal !== oldVal) {
          sessionStorage.setItem("formResultId", currVal);
        }
      },
    },
  },
};
</script>

<style scoped>
.v-expansion-panel::before {
  box-shadow: none;
}

.v-expansion-panel--active:not(:first-child)::after {
  opacity: 1;
}
</style>

<style>
#dynamic-form-card-text {
  height: 60vh;
  overflow-y: auto;
}

#top-bar {
  position: sticky;
  top: 0;
  z-index: 999;
}

#bottom-bar {
  position: sticky;
  top: 0;
  z-index: 999;
}

.icon {
  width: 24px;
}

.big-icon {
  width: 36px;
}
</style>
